import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";

export const StyledAccordion = styled(Accordion)({
  "&": {
    padding: "4px 16px 16px 16px",
    boxShadow: "none",
    borderRadius: "10px",
    width: "100%",
    position: "static",
    "&:before": {
      display: "none",
    },
  },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  "&": {
    padding: "0",
    margin: "5px 0",
  },

  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  "&": {
    padding: "0",
  },
});
