import React from "react";
import { Carousel } from "react-responsive-carousel";

const CustomCarousel = (props: any) => {
  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      interval={5550}
      transitionTime={1000}
      infiniteLoop={true}
      useKeyboardArrows={true}
      emulateTouch={true}
      showThumbs={false}
    >
      {props.children}
    </Carousel>
  );
};

export default CustomCarousel;
