import React from "react";
import { Link } from "react-router-dom";
import default_display from "../../assets/images/default.png";
import { IUserData } from "../../interfaces/Interfaces.model";
import { isAuth, sendLike } from "../../utils/helper";
import { checkUser } from "../../utils/utility";

export default function Card({ data }: { data: IUserData }) {
  const user = isAuth();
  const linkStyles = {
    width: "75%",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  };
  const divStyles = {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  };
  const imgStyles = { borderRadius: "50%", height: "10rem", width: "8rem" };
  if (data) {
    const personalDetails = data.personal_details;
    return (
      <div className="card mb-3 border-0 text-lg-left" style={divStyles}>
        <div className="row no-gutters">
          <div className="col-lg-4">
            <img
              src={
                personalDetails.profile_photo === "default.png"
                  ? default_display
                  : personalDetails.profile_photo
              }
              alt="Profile"
              className="rounded-circle py-3 ml-lg-5"
              style={imgStyles}
            />
          </div>
          <div className="col-lg-4">
            <div className="card-body">
              <Link
                className="card-title"
                target="_blank"
                style={{ marginBottom: "5px" }}
                to={`/${checkUser(user)}/${data._id}`}
              >
                <strong style={{ textTransform: "capitalize" }}>
                  {`${data.first_name} ${data.middle_name ?? ""} ${
                    data.last_name ?? ""
                  }`.toLocaleLowerCase()}
                </strong>
              </Link>
              <p style={{ marginBottom: "5px" }}>
                {personalDetails.mobile_number}
              </p>
              <p className="card-text" style={{ marginBottom: "5px" }}>
                height:
                {` ${personalDetails.height_feet ?? ""}' ${
                  personalDetails.height_inches ?? ""
                }"`}
                &nbsp; weight: {personalDetails.weight}
              </p>
              <p className="card-text">{personalDetails.marital_status}</p>
            </div>
          </div>
          <div className="col-lg-4 mt-lg-3 mb-3 mb-lg-0">
            <Link
              to={`/${checkUser(user)}/${data._id}`}
              target="_blank"
              className="btn btn-outline-dark mx-1 card-body__insides my-1"
              style={linkStyles}
            >
              View Profile
            </Link>
            <button
              className="btn btn-outline-dark mx-1 card-body__insides my-1"
              style={linkStyles}
              onClick={() => sendLike(data.notificationId, data._id ?? "")}
            >
              Like Profile
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
