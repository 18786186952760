import React from "react";
import { NavLink } from "react-router-dom";
import { IUserData } from "../../interfaces/Interfaces.model";
import { logout } from "../../services/actions/auth";
import { checkUser } from "../../utils/utility";

const HomeHeader = ({ user, navigate }: { user: IUserData; navigate: any }) => {
  return (
    <header className="header_Landing">
      <div className="header_Landing__navbar-box">
        <div className="header_Landing__logo-box">
          <img
            src={"/brahmanMandal.svg"}
            alt="Logo"
            className="header_Landing__logo"
          />
          <div className="header_Landing__title">Vadhuvar Mahiti Kendra</div>
        </div>
        { user.role === "user" ? (
          <div className="header_Landing__navbar-box3">
            <NavLink to="#" className="header_Landing__nav__Links">
              <span
                onClick={() => logout()}
                className="header_Landing__nav__title"
              >
                Logout
              </span>
            </NavLink>
          </div>
        ) :user.role === "admin"? (
          <div className="header_Landing__navbar-box3">
            <div className=""></div>
            <div className=""></div>
            <div className=""></div>
            <div className=""></div>
            <NavLink to="/admin" className="header_Landing__nav__Links">
              <span className="header_Landing__nav__title">Admin Panel</span>
            </NavLink>
            <NavLink to="#" className="header_Landing__nav__Links">
              <span
                onClick={() => logout()}
                className="header_Landing__nav__title"
              >
                Logout
              </span>
            </NavLink>
          </div>
        ):<></>}
      </div>
      <div className="header_Landing__hero-content">
        <div className="header_Landing__hero-content__text-box">
          <h1>
            <span style={{ fontWeight: 700 }}>
              Finding Love
              <br />
              Has never been, This
              <br />"
              <span style={{ color: "#847DCF", fontSize: "2.5rem" }}>
                Simpler
              </span>
              "
              <br />
            </span>
            <span style={{ color: "#847DCF", fontSize: "1.5rem" }}>
              We help you meet the right person,
              <br />
              Someone with whom life becomes "
              <span style={{ color: "#fff", fontSize: "2rem" }}>Simpler</span>"
            </span>
          </h1>

          {user ? (
            <button
              className="btn btn--blue"
              style={{ color: "#000000" }}
              onClick={() => navigate(`/${checkUser(user)}s`)}
            >
              {" "}
              Find Match
            </button>
          ) : (
            <>
              <button
                className="btn btn--blue"
                style={{ color: "#000000" }}
                onClick={() => navigate("/signup")}
              >
                {" "}
                Create Account
              </button>
              <button
                className="btn btn--blue"
                style={{ color: "#000000" }}
                onClick={() => navigate("/signin")}
              >
                {" "}
                Sign-in
              </button>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-8rem",
          }}
          className="header_Landing__hero-content__image"
        >
          <img
            src="/images/hero.png"
            alt="Hero"
            className="header_Landing__hero-content__image"
          />
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
