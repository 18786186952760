import { useState, useEffect, useCallback } from "react";
import { getAction } from "../services/generalServices";
import { isAuth } from "../utils/helper";

export interface IPerson {
  first_name: string;
  middle_name: string;
  last_name: string;
  role: string;
  notificationId: string;
  _id: string;
}

export interface IUserDictionary {
  [id: string]: IPerson;
}
export default function useUsersList(allowSelf = false) {
  const [females, setfemales] = useState<IPerson | null>(null);
  const [allFemales, setallFemales] = useState<IUserDictionary>({});
  const [males, setMales] = useState<IPerson | null>(null);
  const [allMales, setAllMales] = useState<IUserDictionary>({});
  const [allUsers, setAllUsers] = useState<IUserDictionary>({});
  const [users, setUsers] = useState<IUserDictionary>({});

  const getUsers = useCallback(async () => {
    let males: any = {},
      females: any = {};
    let males2: any = {},
      females2: any = {};
    let usersList: any = [];
    const f = await getAction(`/users?isRegistered=true&sort=name`);
    if (f && f.status === 200)
      if (f.data.data && f.data.data.length) usersList = f.data.data;
    if (usersList && usersList.length) {
      usersList.forEach(
        ({
          first_name,
          middle_name,
          last_name,
          _id,
          role,
          gender,
          notificationId,
        }: any) => {
          const id = String(_id);
          if (isAuth() && _id === isAuth()._id && !allowSelf) {
            return;
          } else if (gender === "female") {
            females[id] = ` ${first_name} ${middle_name} ${last_name}`;
            females2[id] = {
              first_name,
              middle_name,
              last_name,
              role,
              notificationId,
              _id: id,
            };
          } else if (gender === "male") {
            males[id] = ` ${first_name} ${middle_name} ${last_name}`;
            males2[id] = {
              first_name,
              middle_name,
              last_name,
              role,
              notificationId,
              _id: id,
            };
          }
        },
      );
    }

    setfemales(females);
    setMales(males);
    setallFemales(females2);
    setAllMales(males2);
    setAllUsers({ ...females2, ...males2 });
    setUsers({ ...females, ...males });
  }, [allowSelf]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return { males, females, allFemales, allMales, users, allUsers };
}
