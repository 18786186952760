import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import {
  IOccupationDetails,
  ISalaryRange,
  IJobType,
} from "../../interfaces/model_helper";
import { isAuth, setLocalStorage } from "../../utils/helper";
import { patchUserDetails } from "../../services/userService";
/*****
 Show Add Personal Details hook
 *****/
export interface IOccupationDetailsStateErrors {
  company_name: boolean;
  position: boolean;
  location: boolean;
  description: boolean;
  salary_range: boolean;
  type: boolean;
  future_plans: boolean;
}

const initialOccupationDetailsErrorsObj = {
  company_name: false,
  position: false,
  location: false,
  description: false,
  salary_range: false,
  type: false,
  future_plans: false,
};

export enum UpdateOccupationDetailsState {
  UpdateCompanyName = "company_name",
  UpdateLocation = "location",
  UpdatePosition = "position",
  UpdateDescription = "description",
  Updatetype = "type",
  UpdateFuturePlans = "future_plans",
  UpdateSalaryRange = "salary_range",
}

export interface IStringAction {
  type:
    | UpdateOccupationDetailsState.UpdateCompanyName
    | UpdateOccupationDetailsState.UpdateLocation
    | UpdateOccupationDetailsState.UpdatePosition
    | UpdateOccupationDetailsState.UpdateFuturePlans
    | UpdateOccupationDetailsState.UpdateDescription;
  value: string;
}

export interface ISalaryRangeAction {
  type: UpdateOccupationDetailsState.UpdateSalaryRange;
  value: ISalaryRange;
}
export interface IJobTypeAction {
  type: UpdateOccupationDetailsState.Updatetype;
  value: IJobType;
}

export interface IHandleOccupationDetailsCreation {
  error: boolean;
  msg?: string;
}
export type OccupationDetailsErrorsAction =
  | IStringAction
  | ISalaryRangeAction
  | IJobTypeAction;

export type OccupationDetailsAction =
  | IStringAction
  | ISalaryRangeAction
  | IJobTypeAction;

const addOccupationDetailsReducer = (
  state: IOccupationDetails,
  action: OccupationDetailsAction,
): IOccupationDetails => {
  let newOccupationDetails: IOccupationDetails = { ...state };
  switch (action.type) {
    case UpdateOccupationDetailsState.UpdateCompanyName:
      newOccupationDetails.company_name = action.value;
      break;
    case UpdateOccupationDetailsState.UpdateLocation:
      newOccupationDetails.location = action.value;
      break;
    case UpdateOccupationDetailsState.Updatetype:
      newOccupationDetails.type = action.value;
      break;
    case UpdateOccupationDetailsState.UpdatePosition:
      newOccupationDetails.position = action.value;
      break;
    case UpdateOccupationDetailsState.UpdateDescription:
      newOccupationDetails.description = action.value;
      break;
    case UpdateOccupationDetailsState.UpdateFuturePlans:
      newOccupationDetails.future_plans = action.value;
      break;
    case UpdateOccupationDetailsState.UpdateSalaryRange:
      newOccupationDetails.salary_range = action.value;
      break;
  }
  return newOccupationDetails;
};

export const useOccupationDetails = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [occupationDetailsErrors, setOccupationDetailsErrors] =
    useState<IOccupationDetailsStateErrors>(initialOccupationDetailsErrorsObj);
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());

  const initialOccupationDetails: IOccupationDetails = {
    company_name:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.company_name) ??
      "",
    position:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.position) ??
      "",
    location:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.location) ??
      "",
    type:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.type) ??
      IJobType.INITIAL_STATE,
    description:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.description) ??
      "",
    future_plans:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.future_plans) ??
      "",
    salary_range:
      (userLocalStorage.occupation_details &&
        userLocalStorage.occupation_details.salary_range) ??
      ISalaryRange.select_salary_range,
  };
  const [occupationDetails, dispatchOccupationDetails] = useReducer(
    addOccupationDetailsReducer,
    initialOccupationDetails,
  );

  const handleOccupationDetailsUpdate = (action: OccupationDetailsAction) => {
    dispatchOccupationDetails(action);
  };

  const handleOccupationDetailsErrors = (
    action: OccupationDetailsErrorsAction,
  ) => {
    let newAddress = { ...occupationDetailsErrors };
    if (isEmpty(occupationDetails[action.type])) {
      newAddress[action.type] = true;
    } else {
      newAddress[action.type] = false;
    }
    setOccupationDetailsErrors(newAddress);
  };

  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);

  useEffect(() => {
    let errors = false;
    if (occupationDetails.type !== IJobType.NOT_WORKING) {
      if (isEmpty(occupationDetails.location)) errors = true;
      if (isEmpty(occupationDetails.description)) errors = true;
      if (isEmpty(occupationDetails.salary_range)) errors = true;
      if (isEmpty(occupationDetails.company_name)) errors = true;
      if (isEmpty(occupationDetails.type)) errors = true;
      if (isEmpty(occupationDetails.position)) errors = true;
    }
    setIsValid(errors);
  }, [occupationDetails]);

  const handleCreate = async (): Promise<IHandleOccupationDetailsCreation> => {
    try {
      const newOccupationDetails = {
        occupation_details: { ...occupationDetails },
      };

      if (
        !isEqual(
          { ...occupationDetails },
          { ...userLocalStorage.occupation_details },
        )
      ) {
        if (occupationDetails.type !== IJobType.NOT_WORKING) {
          let user = isAuth();
          user.occupation_details = occupationDetails;

          const responseStatus = await patchUserDetails(newOccupationDetails);
          if (responseStatus) {
            setLocalStorage("user", user);
          }
        } else {
          const patchOccupationDetails = {
            occupation_details: {
              type: IJobType.NOT_WORKING,
            },
          };
          let user = isAuth();
          user.occupation_details = {
            type: IJobType.NOT_WORKING,
          };
          const responseStatus = await patchUserDetails(patchOccupationDetails);
          if (responseStatus) {
            setLocalStorage("user", user);
          }
        }
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const occupationDetailsRetun: IOccupationDetailsReturn = {
    occupationDetails,
    isValid,
    occupationDetailsErrors,
    handleOccupationDetailsUpdate,
    handleOccupationDetailsErrors,
    handleCreate,
  };
  return occupationDetailsRetun;
};

export interface IOccupationDetailsReturn {
  occupationDetails: IOccupationDetails;
  isValid: boolean;
  occupationDetailsErrors: IOccupationDetailsStateErrors;
  handleOccupationDetailsErrors: (
    action: OccupationDetailsErrorsAction,
  ) => void;
  handleOccupationDetailsUpdate: (action: OccupationDetailsAction) => void;
  handleCreate: () => Promise<IHandleOccupationDetailsCreation>;
}
