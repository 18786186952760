import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const fontSize = { fontSize: "1.14rem" };
  let renderLeft = (
    <Fragment>
      <h4 className="d-none d-lg-block font-weight-bold mb-lg-3">Contact Us</h4>
      <p className="d-block d-lg-none font-weight-bold">Contact Us</p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <i className="fas fa-envelope mr-3"></i> vadhuvarthane@gmail.com
      </h4>
      <p className="d-block d-lg-none">
        <i className="fas fa-envelope mr-3"></i> vadhuvarthane@gmail.com
      </p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <i className="fas fa-phone-alt mr-3"></i> 9757365403
      </h4>
      <p className="d-block d-lg-none">
        <i className="fas fa-phone-alt mr-3"></i> 9757365403
      </p>
      <h4 style={fontSize} className="d-none d-lg-block">
        <p>Timings : 6.00 pm to 7.30 pm (Tue, Thurs and Sat)</p>
      </h4>
      <p className="d-block d-lg-none">
        <p>Timings : 6.00 pm to 7.30 pm (Tue, Thurs and Sat)</p>
      </p>
    </Fragment>
  );
  let renderCenter = (
    <Fragment>
      <h4 className="d-none d-lg-block font-weight-bold mb-lg-3">
        Technical Support
      </h4>
      <p className="d-block d-lg-none font-weight-bold">Technical Support</p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <i className="fas fa-envelope mr-3"></i> vvmk.contact@gmail.com
      </h4>
      <p className="d-block d-lg-none">
        <i className="fas fa-envelope mr-3"></i> vvmk.contact@gmail.com
      </p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <i className="fas fa-phone-alt mr-3"></i> 9833754878{" "}
        <strong> (Whatsapp Only)</strong>
      </h4>
      <p className="d-block d-lg-none">
        <i className="fas fa-phone-alt mr-3"></i> 9833754878{" "}
        <strong>(Whatsapp Only)</strong>
      </p>
    </Fragment>
  );
  let renderAddress = (
    <Fragment>
      <h4 className="d-none d-lg-block font-weight-bold mb-lg-3">
        Vadhu Var Address
      </h4>
      <p className="d-block d-lg-none font-weight-bold">Vadhu Var Address</p>

      <h4 style={fontSize} className="d-none d-lg-block">
        C/o Brahman Seva Sangh 19' D.G. Gangal Marg Brahman Society, Naupada
        Thane (W) 400602
      </h4>
      <p className="d-block d-lg-none">
        C/o Brahman Seva Sangh 19' D.G. Gangal Marg Brahman Society, Naupada
        Thane (W) 400602
      </p>

      {/* <h4 style={fontSize} className="d-none d-lg-block">
        <i className="fas fa-phone-alt mr-3"></i> 9833754878 (Whatsapp Only)
      </h4>
      <p className="d-block d-lg-none">
        <i className="fas fa-phone-alt mr-3"></i> 9833754878 (Whatsapp Only)
      </p> */}
    </Fragment>
  );
  let renderRight = (
    <Fragment>
      <h4 className="d-none d-lg-block font-weight-bold mb-lg-3">Policy</h4>
      <p className="d-block d-lg-none font-weight-bold">Policy</p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </h4>
      <p className="d-block d-lg-none">
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </p>

      <h4 style={fontSize} className="d-none d-lg-block">
        <Link to="/privacy-policy">Privacy Policy</Link>
      </h4>
      <p className="d-block d-lg-none">
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
    </Fragment>
  );
  return (
    <div id="contactUs">
      <div
        className="jumbotron bg-white"
        style={{
          marginBottom: 0,
          paddingBottom: "1rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 text-lg-left text-center pt-lg-1">
              {renderLeft}
            </div>
            <div className="col-lg-3 text-lg-left text-center pt-lg-1">
              {renderCenter}
            </div>

            <div className="col-lg-3 text-lg-left text-center pt-lg-1">
              {renderAddress}
            </div>
            <div className="col-lg-3 text-lg-left text-center pt-lg-1">
              {renderRight}
            </div>
            <div className="col-lg-12 mt-4">
              &copy; This website is developed by Aashay Phirke and belongs to
              Vadhuvar Mahiti Kendra
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
