import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import LoadingSign from "../../components/utils/LoadingSign";
import { getAction } from "../../services/generalServices";
import { IUserData } from "../../interfaces/Interfaces.model";
import { useParams, useNavigate } from "react-router-dom";
import UserProfile from "../../components/user/UserProfile";
import WithAuth from "../../hooks/hoc/WithAuth";

const FemaleProfile = () => {
  const [profile, setprofile] = useState<IUserData | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    const getProfile = async () => {
      const res = await getAction(`/users/${id}`);
      if (res && res.status === 200) {
        if (res.data.data) setprofile(res.data.data[0]);
      }
    };
    getProfile();
  }, [id]);
  let render = <LoadingSign />;
  if (profile) {
    render = <UserProfile navigate={navigate} data={profile} />;
  }
  // styling issues
  // return <Layout rightSidebar={true} projects={true}>{render}</Layout>;
  return <Layout>{render}</Layout>;
};

export default WithAuth("")(FemaleProfile);
