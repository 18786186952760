import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { handleFileChange } from "../../utils/utility";
import CustomCarousel from "../Shared/CustomCarousal";
import { isAuth, onAuth } from "../../utils/helper";
import { deleteAction } from "../../services/generalServices";
import { toast } from "react-toastify";

const EditPhotoAlbum = ({
  prevStep,
  nextStep,
}: {
  prevStep: () => void;
  nextStep: () => void;
}) => {
  const cancel = () => {
    prevStep();
  };
  const save = () => {
    nextStep();
  };
  return (
    <Grid item lg={12}>
      <Grid item lg={12} className="dp--flex dp--jcc">
        <div className=" image-uploader-wrapper">
          <input
            type="file"
            className="upload-image-input input-file"
            accept="image/*"
            id="upload-image-input2"
            onChange={handleFileChange}
          />
          <Tooltip title="Upload cover image">
            <label
              htmlFor="upload-image-input2"
              className="btn btn-primary  mx-2"
              style={{ fontSize: "0.7rem", color: "#FFF" }}
            >
              Upload Album Photo
            </label>
          </Tooltip>
        </div>
      </Grid>
      <p style={{ textAlign: "center" }}>
        Image should be jpg or png and less than 5MB each
      </p>
      <Grid
        item
        lg={12}
        style={{ width: "36%", margin: "auto" }}
        className="dp--flex dp--jcc"
      >
        <CustomCarousel>
          {isAuth().personal_details.album.map(
            (item: string, index: number) => (
              <>
                <img src={item} alt="album" />
                <Tooltip title="delete profile image">
                  <div
                    className="btn btn-danger dp--flex dp--jcc dp--aic "
                    onClick={() =>
                      deleteAction(`/photo/album-photo/${index}`, () => {
                        onAuth(() => {
                          toast.success("Album photo removed successfully");
                          window.location.reload();
                        });
                      })
                    }
                    style={{
                      color: "#FFF",
                      width: "1.5rem",
                    }}
                  >
                    <i
                      className="fa fa-trash-can"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      
                    </i>
                  </div>
                </Tooltip>
              </>
            ),
          )}
        </CustomCarousel>
      </Grid>

      <Grid item lg={12}>
        <div className="dp--flex dp--jcc ">
          <button className="btn btn--blue" onClick={cancel}>
            Go Back
          </button>
          <button className="btn btn--blue" onClick={save}>
            Save and Next
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export default EditPhotoAlbum;
