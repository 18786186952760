import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminHome from "../../components/Admin/AdminHome";
import { IAdminUserData, IUserData } from "../../interfaces/Interfaces.model";
import { getAction } from "../../services/generalServices";
import Layout from "../layout/Layout";

const Admin = () => {
  const [userList, setUserList] = useState<IAdminUserData[]>([]);
  useEffect(() => {
    const getUserData = async () => {
      const data = await getAction("/admin/users");
      if (data.status === 200) {
        const finalArray = data.data.data.map((item: IUserData) => {
          return {
            ...item.personal_details,
            id: item._id,
            gender: item.gender,
            first_name: item.first_name,
            middle_name: item.middle_name,
            last_name: item.last_name,
            emailId: item.emailId,
            isRegistered: item.isRegistered,
            dateOfExpiry: item.dateOfExpiry,
            isMarried: item.isMarried,
            transaction_id: item.transaction_id,
            transaction_date: item.transaction_date,
          };
        });
        setUserList(finalArray);
      } else {
        toast.error("something went wrong");
      }
    };
    getUserData();
    return () => {
      setUserList([]);
    };
  }, []);

  return (
    <Layout>
      <AdminHome userList={userList} />
    </Layout>
  );
};

export default Admin;
