import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import Loading from "../components/utils/LoadingSign";
import { IUserData } from "../interfaces/Interfaces.model";
import { axiosInstance, isAuth, setLocalStorage } from "../utils/helper";

const Payment = () => {
  const user: IUserData = isAuth();
  useEffect(() => {
    const createOrder = async () => {
      if (user && isEmpty(user.payment_url)) {
        try {
          const resp = await axiosInstance.get("/payment/createorder");
          setLocalStorage("user", resp.data.user);
          if (resp.data && resp.data.user && resp.data.user.payment_url) {
            window.location.href = resp.data.user.payment_url;
          }
        } catch (error) {}
      } else {
        window.location.href = user.payment_url;
      }
    };
    createOrder();
  }, [user]);

  return <Loading />;
};

export default Payment;
