import { toast } from "react-toastify";
import { getErrorData } from "../utils/helper";
import { patchAction } from "./generalServices";

export const patchUserDetails = async (data: any) => {
  const resp = await patchAction("/me/register", data);
  if (resp.status === 200) {
    return true;
  } else {
    const errData = getErrorData(resp);
    toast.error(errData);
    return false;
  }
};
