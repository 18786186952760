import Header from "./Header";
import RightSidebar from "./RightSidebar";
import { useEffect, useState } from "react";
import { getAction } from "../../services/generalServices";
import { isAuth } from "../../utils/helper";
// import BottomNav from "./BottomNav";
import {
  IExpCaste,
  IExpComplexion,
  IExpectedJobType,
  IExpectedResidentialStatus,
  IExpManglik,
  IExpSubCaste,
} from "../../interfaces/model_helper";
import { isEmpty } from "lodash";
import Footer from "./Footer";

const Layout = (props: any) => {
  const [recommendedMales, setMales] = useState([]);
  const [recommendedFemales, setFemales] = useState([]);

  useEffect(() => {
    const getRecommendation = async () => {
      const user = isAuth();
      if (user) {
        let query = `/users?isRegistered=true&sort=first_name&gender=${
          user.gender === "male" ? "female" : "male"
        }`;
        if (
          !isEmpty(user.expectation.exp_caste) &&
          user.expectation.exp_caste !== IExpCaste.ANY
        ) {
          query += `&personal_details.caste=${user.expectation.exp_caste}`;
        }
        if (
          !isEmpty(user.expectation.exp_sub_caste) &&
          user.expectation.exp_sub_caste !== IExpSubCaste.ANY_BRAHMIN &&
          user.expectation.exp_caste === IExpCaste.BRAHMIN
        ) {
          query += `&personal_details.sub_caste=${user.expectation.exp_sub_caste}`;
        }
        if (
          !isEmpty(user.expectation.exp_complexion) &&
          user.expectation.exp_complexion !== IExpComplexion.ANY &&
          user.expectation.exp_complexion !== IExpComplexion.INITIAL_STATE
        ) {
          query += `&personal_details.complexion=${user.expectation.exp_complexion}`;
        }
        if (
          !isEmpty(user.expectation.exp_residential_status) &&
          user.expectation.exp_residential_status ===
            IExpectedResidentialStatus.WITHIN_INDIA
        ) {
          query += `&personal_details.residential_status=India`;
        } else if (
          !isEmpty(user.expectation.exp_residential_status) &&
          user.expectation.exp_residential_status ===
            IExpectedResidentialStatus.OUTSIDE_INDIA
        ) {
          query += `&personal_details.neresidential_status=India`;
        }
        if (
          !isEmpty(user.expectation.exp_isManglik) &&
          user.expectation.exp_isManglik !== IExpManglik.ANY &&
          user.expectation.exp_isManglik !== IExpManglik.INITIAL_STATE
        ) {
          query += `&personal_details.isManglik=${user.expectation.exp_isManglik}`;
        }
        if (
          !isEmpty(user.expectation.exp_occupation_type) &&
          user.expectation.exp_occupation_type !== IExpectedJobType.ANY &&
          user.expectation.exp_occupation_type !==
            IExpectedJobType.INITIAL_STATE
        ) {
          query += `&occupation_details.type=${user.expectation.exp_occupation_type}`;
        }
        if (user.gender === "female") {
          const t = await getAction(`${query}`);
          if (t && t.status === 200) if (t.data) setMales(t.data.data);
        } else {
          const s = await getAction(`${query}`);
          if (s && s.status === 200) if (s.data) setFemales(s.data.data);
        }
      }
    };
    if (props.rightSidebar) getRecommendation();
  }, [props.rightSidebar]);

  if (props.rightSidebar) {
    return (
      <>
        <Header />
        <div className="container-modified">
          <div className="row text-center text-lg-left mt-4">
            {props.children}
            {isAuth() && (
              <RightSidebar
                recommendedMales={recommendedMales}
                recommendedFemales={recommendedFemales}
              />
            )}
          </div>
        </div>
        {/* <BottomNav /> */}
        <Footer />
      </>
    );
  } else {
    return (
      <div>
        <Header />
        <div className="container-modified">
          <div className="row mt-lg-4">{props.children}</div>
        </div>
        {/* <BottomNav /> */}
        <Footer />
      </div>
    );
  }
};

export default Layout;
