export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^[^<>()[\]\\,;:\\%#^\s@\\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const isPasswordValid = (password: string) => {
  let errors = [];
  if (password.length < 8) {
    errors.push("Your password must be at least 8 characters");
  }
  if (password.search(/[A-Z]/) < 0) {
    errors.push(
      "Your password must contain at least one upper case character.",
    );
  }
  if (password.search(/(?=.*[!@#$%^&*])/) < 0) {
    errors.push("Your password must contain at least one special character.");
  }
  if (password.search(/[a-z]/) < 0) {
    errors.push(
      "Your password must contain at least one lower case character.",
    );
  }
  if (password.search(/[0-9]/) < 0) {
    errors.push("Your password must contain at least one digit.");
  }
  if (errors.length > 0) {
    return {
      state: true,
      errors,
    };
  } else {
    return {
      state: false,
      errors: errors,
    };
  }
};

export const InputRegEx = {
  TEXT: new RegExp("^([a-z ]+)?$", "i"),
  NUMBER: new RegExp("^([0-9]+)?$"),
  DECIMAL_NUMBER: new RegExp("^([0-9]+)?([.]+)?([0-9]{0,2})?$"),
  EMAIL: new RegExp(
    "^([0-9a-z._]+)?([@]+)?([0-9a-z]+)?([.]+)?([a-z]+)?([.a-z]+)?$",
    "i",
  ),
  CURRENCY: /(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g,
};
