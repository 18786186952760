import React from "react";
import { Navigate } from "react-router";
import { IUserData } from "../../interfaces/Interfaces.model";
import { isAuth } from "../../utils/helper";

const WithNoAuth = () => (Component: any) =>
  class WithNoAuth extends React.Component {
    renderUnProtectedPage() {
      const user: IUserData = isAuth();

      if (!user) {
        return <Component {...this.props} />;
      } else if (user && !user.isRegistered) {
        return <Navigate to="/register" />;
        //   } else if (user && isEmpty(user.transaction_id)) {
        //     return <Navigate to="/payment" />;
      } else if (user) {
        return <Navigate to="/profile" />;
      }
    }

    render() {
      return this.renderUnProtectedPage();
    }
  };

export default WithNoAuth;
