import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { IEducationDetails, IDegreeType } from "../../interfaces/model_helper";
import { patchUserDetails } from "../../services/userService";
import { isAuth, setLocalStorage } from "../../utils/helper";

/*****
 Show Add Personal Details hook
 *****/
export interface IEducationDetailsStateErrors {
  college_name: boolean;
  year_of_passing: boolean;
  special_achievements: boolean;
  degree_specification: boolean;
  degree: boolean;
}

const initialEducationDetailsErrorsObj = {
  college_name: false,
  year_of_passing: false,
  special_achievements: false,
  degree_specification: false,
  degree: false,
};

export enum UpdateEducationDetailsState {
  UpdateCollegeName = "college_name",
  UpdateYearOfPassing = "year_of_passing",
  UpdateSpecialAchievements = "special_achievements",
  UpdateDegreeSpecification = "degree_specification",
  UpdateDegreeType = "degree",
}

export enum UpdateActions {
  Reset = "reset",
}

export interface IStringAction {
  type:
    | UpdateEducationDetailsState.UpdateCollegeName
    | UpdateEducationDetailsState.UpdateYearOfPassing
    | UpdateEducationDetailsState.UpdateDegreeSpecification
    | UpdateEducationDetailsState.UpdateSpecialAchievements;

  value: string;
}

export interface IDegreeAction {
  type: UpdateEducationDetailsState.UpdateDegreeType;
  value: IDegreeType;
}

interface IResetAction {
  type: UpdateActions.Reset;
}

export interface IHandleEducationDetailsCreation {
  error: boolean;
  msg?: string;
}

export type EducationDetailsAction =
  | IStringAction
  | IResetAction
  | IDegreeAction;

export type EducationDetailsErrorsAction = IStringAction | IDegreeAction;
const initialEducationDetails: IEducationDetails = {
  college_name: "",
  year_of_passing: "",
  special_achievements: "",
  degree_specification: "",
  degree: IDegreeType.INITIAL_STATE,
};
const addEducationDetailsReducer = (
  state: IEducationDetails,
  action: EducationDetailsAction,
): IEducationDetails => {
  let newEducationDetails: IEducationDetails = { ...state };
  switch (action.type) {
    case UpdateEducationDetailsState.UpdateCollegeName:
      newEducationDetails.college_name = action.value;
      break;
    case UpdateEducationDetailsState.UpdateYearOfPassing:
      newEducationDetails.year_of_passing = action.value;
      break;
    case UpdateEducationDetailsState.UpdateSpecialAchievements:
      newEducationDetails.special_achievements = action.value;
      break;
    case UpdateEducationDetailsState.UpdateDegreeType:
      newEducationDetails.degree = action.value;
      break;
    case UpdateEducationDetailsState.UpdateDegreeSpecification:
      newEducationDetails.degree_specification = action.value;
      break;
    case UpdateActions.Reset:
      newEducationDetails = initialEducationDetails;
      break;
  }
  return newEducationDetails;
};

export const useEducationDetails = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [allEducations, setAllEducations] = useState<IEducationDetails[]>(
    isAuth().educational_qualification ?? [],
  );
  const [EducationDetailsErrors, setEducationDetailsErrors] =
    useState<IEducationDetailsStateErrors>(initialEducationDetailsErrorsObj);
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());

  const [EducationDetails, dispatchEducationDetails] = useReducer(
    addEducationDetailsReducer,
    initialEducationDetails,
  );

  const handleEducationDetailsUpdate = (action: EducationDetailsAction) => {
    dispatchEducationDetails(action);
  };

  const handleEducationDetailsErrors = (
    action: EducationDetailsErrorsAction,
  ) => {
    let newAddress = { ...EducationDetailsErrors };
    if (isEmpty(EducationDetails[action.type])) {
      newAddress[action.type] = true;
    } else {
      newAddress[action.type] = false;
    }
    setEducationDetailsErrors(newAddress);
  };

  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);

  useEffect(() => {
    let valid = true;
    if (isEmpty(EducationDetails.degree)) valid = false;
    if (isEmpty(EducationDetails.college_name)) valid = false;
    setIsValid(valid);
  }, [EducationDetails]);

  const addNewEducation = () => {
    if (isValid && !isHidden) {
      const newEducation = [EducationDetails];
      setAllEducations([...allEducations, ...newEducation]);
      dispatchEducationDetails({ type: UpdateActions.Reset });
    }
    setIsHidden(false);
    setIsValid(false);
  };
  const hideNewInput = () => {
    setIsHidden(true);
    setIsValid(true);
  };
  const handleCreate = async (): Promise<IHandleEducationDetailsCreation> => {
    try {
      let newEducationArray = [];

      if (EducationDetails.degree === IDegreeType.INITIAL_STATE) {
        newEducationArray = [...allEducations];
      } else {
        newEducationArray = [...allEducations, EducationDetails];
      }
      const newOccupationDetails = {
        educational_qualification: newEducationArray,
      };

      if (
        !isEqual([EducationDetails], userLocalStorage.educational_qualification)
      ) {
        let user = isAuth();
        user.educational_qualification = newEducationArray;

        const responseStatus = await patchUserDetails(newOccupationDetails);
        if (responseStatus) {
          setLocalStorage("user", user);
        }
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const EducationDetailsRetun: IEducationDetailsReturn = {
    EducationDetails,
    isValid,
    addNewEducation,
    allEducations,
    isHidden,
    hideNewInput,
    EducationDetailsErrors,
    handleEducationDetailsUpdate,
    handleEducationDetailsErrors,
    handleCreate,
  };
  return EducationDetailsRetun;
};

export interface IEducationDetailsReturn {
  EducationDetails: IEducationDetails;
  isValid: boolean;
  isHidden: boolean;
  hideNewInput: () => void;
  addNewEducation: () => void;
  allEducations: IEducationDetails[];
  EducationDetailsErrors: IEducationDetailsStateErrors;
  handleEducationDetailsErrors: (action: EducationDetailsErrorsAction) => void;
  handleEducationDetailsUpdate: (action: EducationDetailsAction) => void;
  handleCreate: () => Promise<IHandleEducationDetailsCreation>;
}
