import React, { useState, useEffect } from "react";
import { ResetPasswordForm } from "../../components/authentication/ResetPassword";
import { reset } from "../../services/actions";
import decode from "jwt-decode";
import { useNavigate, useParams } from "react-router";

const Reset = () => {
  const [values, setValues] = useState({
    name: "",
    token: "",
    newPassword: "",
    isLoading: false,
  });
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const { token } = params;
    const decodedToken: any = decode(token ?? "");
    if (token) {
      setValues({
        ...values,
        name: decodedToken.name,
        token: token,
      });
    }
    // eslint-disable-next-line
  }, []);
  const { name, token, newPassword, isLoading } = values;

  const handlePassChange = (event: any) => {
    setValues({ ...values, newPassword: event.target.value });
  };

  const clickSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setValues({ ...values, isLoading: true });
    reset(newPassword, token, () => {
      navigate("/signin");
    });
  };

  return (
    <ResetPasswordForm
      clickSubmit={clickSubmit}
      newPassword={newPassword}
      name={name}
      handlePassChange={handlePassChange}
      isLoading={isLoading}
    />
  );
};

export default Reset;
