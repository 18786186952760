import React from "react";
import { IUserStats } from "../../interfaces/Interfaces.model";

export const TableData = ({ userStats }: { userStats: any }) => {
  console.log(userStats["femaleStats"].count);
  return (
    <div className="modal" id="myModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">User Stats</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <h6>Unpaid Account Will be deleted after 15 days</h6>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Min Age</th>
                  <th>Max Age</th>
                  <th>Single</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Male </th>
                  <td>{userStats["maleStats"].minAge}</td>
                  <td>{userStats["maleStats"].maxAge}</td>
                  <td>{userStats["maleStats"].singleCount}</td>
                  <td>{userStats["maleStats"].count}</td>
                </tr>
                <tr>
                  <th>Female</th>
                  <td>{userStats["femaleStats"].minAge}</td>
                  <td>{userStats["femaleStats"].maxAge}</td>
                  <td>{userStats["femaleStats"].singleCount}</td>
                  <td>{userStats["femaleStats"].count}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
