import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";

const ProfilePreferenceAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  let render = <div></div>;
  if (user.expectation) {
    render = (
      <>
        <StyledAccordion
          expanded={first}
          onChange={(e: any) => {
            setfirst(!first);
          }}
        >
          <StyledAccordionSummary aria-label="Profile">
            <Grid container alignItems="center" justifyContent="space-between">
              <h4 className="text-primary font-weight-bold">
                Preference Information
              </h4>
            </Grid>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              rowSpacing={1}
            >
              <Grid item lg={12} className="dp--flex">
                {!isEmpty(user.expectation.exp_caste) && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Caste:
                    </Typography>
                    <Typography style={{ marginRight: "5rem" }}>
                      {user.expectation.exp_caste}
                    </Typography>
                  </Grid>
                )}
                {user.expectation.exp_caste === "Brahmin" && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Sub Caste:
                    </Typography>
                    <Typography>{user.expectation.exp_sub_caste}</Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Complexion:
                  </Typography>
                  <Typography style={{ marginRight: "5rem" }}>
                    {user.expectation.exp_complexion}
                  </Typography>
                </Grid>
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Residential Status:
                  </Typography>
                  <Typography>
                    {user.expectation.exp_residential_status}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Occupation Type:
                  </Typography>
                  <Typography style={{ marginRight: "5rem" }}>
                    {user.expectation.exp_occupation_type}
                  </Typography>
                </Grid>
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Manglik:
                  </Typography>
                  <Typography>{user.expectation.exp_isManglik}</Typography>
                </Grid>
              </Grid>
              <Grid item lg={5.9} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Expectations:
                </Typography>
                <Typography>{user.expectation.exp_details} </Typography>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      </>
    );
  }
  return render;
};

export default ProfilePreferenceAccordian;
