import React from "react";
import { Link } from "react-router-dom";
import Header from "../../container/layout/Header";

// This is a fallback page which will be shown if the user redirects to an invalid URL.
const ErrorPage = (props: { error?: string }) => {
  let message = "Page Not Available!";
  const error = window.location.href.split("=")[1];
  if (error === "no_email") {
    message =
      "This Email ID is not yet signed up with us. Please sign up to continue.";
  } else if (error === "married") {
    message = "You are already married";
  } else if (error === "unAuthorized") {
    message =
      "You are not authorized. You dont have a permission to visit this page";
  }
  return (
    <div className="wrapper">
      <Header />
      <div className="error-container">
        <div id="errorCard" className="card text-center border-0">
          <div className="card-wrapper">
            <img
              id="errorImage"
              src={"/brahmanMandal.svg"}
              alt="Logo Vadhuvar MahitiKendra"
            />

            <div className="error-card-body">
              <h1 className="error-card-title">ShubhaMangal</h1>
              <p className="error-card-text"> {message} </p>
              <Link to="/" className="btn btn-outline-primary error-button">
                Back To Home Page?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
