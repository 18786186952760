import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import {
  IBloodGroup,
  IBooleanState,
  ICaste,
  IComplexion,
  IManglikState,
  IMaritialStatus,
  IPersonalDetails,
  IResidentialStatus,
  ISubCaste,
} from "../../interfaces/model_helper";
import { patchUserDetails } from "../../services/userService";
import { isAuth, setLocalStorage } from "../../utils/helper";
import { toast } from "react-toastify";
/*****
 Show Add Personal Details hook
 *****/
export interface IPersonalDetailsStateErrors {
  dob: boolean;
  mobile_number: boolean;
  alternate_number: boolean;
  marital_status: boolean;
  isMarried: boolean;
  blood_group: boolean;
  caste: boolean;
  sub_caste: boolean;
  other_caste: boolean;
  place_of_birth: boolean;
  height_inches: boolean;
  height_feet: boolean;
  weight: boolean;
  isManglik: boolean;
  complexion: boolean;
  hasSpectacle: boolean;
  address: boolean;
  health_description: boolean;
  description: boolean;
  residential_status: boolean;
}

const initialPersonalDetailsErrorsObj = {
  dob: false,
  mobile_number: false,
  alternate_number: false,
  marital_status: false,
  blood_group: false,
  caste: false,
  isMarried: false,
  sub_caste: false,
  other_caste: false,
  place_of_birth: false,
  height_inches: false,
  height_feet: false,
  weight: false,
  residential_status: false,
  isManglik: false,
  complexion: false,
  hasSpectacle: false,
  address: false,
  health_description: false,
  description: false,
};

export enum UpdatePersonalDetailsState {
  UpdateDob = "dob",
  UpdateMobileNumber = "mobile_number",
  UpdateAlternateNumber = "alternate_number",
  UpdateMaritalStatus = "marital_status",
  UpdateOtherCaste = "other_caste",
  UpdateBloodGroup = "blood_group",
  UpdateCaste = "caste",
  UpdateSubCaste = "sub_caste",
  UpdatePlaceOfBirth = "place_of_birth",
  UpdateHeightInches = "height_inches",
  UpdateHeightFeet = "height_feet",
  UpdateWeight = "weight",
  UpdateIsMarried = "isMarried",
  UpdateResidentialStatus = "residential_status",
  UpdateComplexion = "complexion",
  UpdateManglik = "isManglik",
  UpdateSpectacles = "hasSpectacle",
  UpdateAddress = "address",
  UpdateHealthDescription = "health_description",
  UpdateDescription = "description",
}

export enum UpdateActions {
  Reset = "reset",
}

export interface IBloodGroupAction {
  type: UpdatePersonalDetailsState.UpdateBloodGroup;
  value: IBloodGroup;
}
export interface IResidentialStatusAction {
  type: UpdatePersonalDetailsState.UpdateResidentialStatus;
  value: IResidentialStatus;
}

export interface IComplexionAction {
  type: UpdatePersonalDetailsState.UpdateComplexion;
  value: IComplexion;
}
export interface IMaritialStatusAction {
  type: UpdatePersonalDetailsState.UpdateMaritalStatus;
  value: IMaritialStatus;
}
export interface ICasteAction {
  type: UpdatePersonalDetailsState.UpdateCaste;
  value: ICaste;
}
export interface ISubCasteAction {
  type: UpdatePersonalDetailsState.UpdateSubCaste;
  value: ISubCaste;
}

export interface IStringAction {
  type:
    | UpdatePersonalDetailsState.UpdateManglik
    | UpdatePersonalDetailsState.UpdateSpectacles
    | UpdatePersonalDetailsState.UpdateIsMarried
    | UpdatePersonalDetailsState.UpdateDob
    | UpdatePersonalDetailsState.UpdateOtherCaste
    | UpdatePersonalDetailsState.UpdatePlaceOfBirth
    | UpdatePersonalDetailsState.UpdateAddress
    | UpdatePersonalDetailsState.UpdateDescription
    | UpdatePersonalDetailsState.UpdateMobileNumber
    | UpdatePersonalDetailsState.UpdateAlternateNumber
    | UpdatePersonalDetailsState.UpdateHealthDescription;
  value: string;
}

export interface INumberAction {
  type:
    | UpdatePersonalDetailsState.UpdateHeightInches
    | UpdatePersonalDetailsState.UpdateHeightFeet
    | UpdatePersonalDetailsState.UpdateWeight;
  value: number;
}

// interface IResetAction {
//   type: UpdateActions.Reset;
// }

export interface IHandlePersonalInfoCreation {
  error: boolean;
  msg?: string;
}

export type PersonalDetailsAction =
  | IStringAction
  | INumberAction
  //   | IResetAction
  | IComplexionAction
  | IBloodGroupAction
  | IMaritialStatusAction
  | ISubCasteAction
  | IResidentialStatusAction
  | ICasteAction;

export type PersonalDetailsActionErrors =
  | IStringAction
  | INumberAction
  | IComplexionAction
  | IBloodGroupAction
  | IResidentialStatusAction
  | IMaritialStatusAction
  | ISubCasteAction
  | ICasteAction;

const addPersonalDetailsReducer = (
  state: IPersonalDetails,
  action: PersonalDetailsAction,
): IPersonalDetails => {
  let newPersonalDetails: IPersonalDetails = { ...state };
  switch (action.type) {
    case UpdatePersonalDetailsState.UpdateDob:
      newPersonalDetails.dob = action.value;
      newPersonalDetails.age = moment().diff(action.value, "years");
      break;
    case UpdatePersonalDetailsState.UpdateMobileNumber:
      newPersonalDetails.mobile_number = action.value;
      break;

    case UpdatePersonalDetailsState.UpdateIsMarried:
      newPersonalDetails.isMarried = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateAlternateNumber:
      newPersonalDetails.alternate_number = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateWeight:
      newPersonalDetails.weight = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateHeightFeet:
      newPersonalDetails.height_feet = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateHeightInches:
      newPersonalDetails.height_inches = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateBloodGroup:
      newPersonalDetails.blood_group = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateMaritalStatus:
      newPersonalDetails.marital_status = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateCaste:
      newPersonalDetails.caste = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateSubCaste:
      newPersonalDetails.sub_caste = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateComplexion:
      newPersonalDetails.complexion = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateAddress:
      newPersonalDetails.address = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateHealthDescription:
      newPersonalDetails.health_description = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateResidentialStatus:
      newPersonalDetails.residential_status = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateDescription:
      newPersonalDetails.description = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateOtherCaste:
      newPersonalDetails.other_caste = action.value;
      break;
    case UpdatePersonalDetailsState.UpdatePlaceOfBirth:
      newPersonalDetails.place_of_birth = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateManglik:
      newPersonalDetails.isManglik = action.value;
      break;
    case UpdatePersonalDetailsState.UpdateSpectacles:
      newPersonalDetails.hasSpectacle = action.value;
      break;
    // case UpdateActions.Reset:
    //   newPersonalDetails = { ...initialPersonalDetails };
  }
  return newPersonalDetails;
};

export const usePersonalDetails = () => {
  const [isPersonalDetailsValidP0, setIsPersonalDetailsValidP0] =
    useState<boolean>(false);
  const [isPersonalDetailsValidP1, setIsPersonalDetailsValidP1] =
    useState<boolean>(false);
  const [personalDetailsErrors, setPersonalDetailsErrors] =
    useState<IPersonalDetailsStateErrors>(initialPersonalDetailsErrorsObj);
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());
  const initialPersonalDetails: IPersonalDetails = {
    album:
      userLocalStorage.personal_details &&
      !isEmpty(userLocalStorage.personal_details.album)
        ? userLocalStorage.personal_details.album
        : [],
    profile_photo:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.profile_photo) ??
      "",
    dob:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.dob) ??
      "",
    age:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.age) ??
      0,
    alternate_number:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.alternate_number) ??
      "",
    mobile_number:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.mobile_number) ??
      "",
    blood_group:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.blood_group) ??
      IBloodGroup.INITIAL_STATE,
    marital_status:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.marital_status) ??
      IMaritialStatus.INITIAL_STATE,
    caste:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.caste) ??
      ICaste.SELECT_CASTE,
    sub_caste:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.sub_caste) ??
      ISubCaste.SELECT_SUB_CASTE,
    other_caste:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.other_caste) ??
      "",
    residential_status:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.residential_status) ??
      IResidentialStatus.INITIAL_STATE,
    place_of_birth:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.place_of_birth) ??
      "",
    height_inches:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.height_inches) ??
      0,
    height_feet:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.height_feet) ??
      0,
    weight:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.weight) ??
      0,
    complexion:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.complexion) ??
      IComplexion.INITIAL_STATE,
    isManglik:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.isManglik) ??
      IManglikState.Select,
    hasSpectacle:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.hasSpectacle) ??
      IBooleanState.Select,
    isMarried: userLocalStorage && userLocalStorage.isMarried ? "Yes" : "No",
    health_description:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.health_description) ??
      "",
    description:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.description) ??
      "",
    address:
      (userLocalStorage.personal_details &&
        userLocalStorage.personal_details.address) ??
      "",
  };

  const [personalDetails, dispatchPersonalDetails] = useReducer(
    addPersonalDetailsReducer,
    initialPersonalDetails,
  );

  const handleReset = () => {
    // dispatchPersonalDetails({
    //   type: UpdateActions.Reset,
    // });
    // setIsPersonalDetailsValidP0(false);
    // setIsPersonalDetailsValidP1(false);
  };

  const handleProfileDetailsUpdate = (action: PersonalDetailsAction) => {
    dispatchPersonalDetails(action);
  };

  const handlePersonalDetailsErrors = (action: PersonalDetailsActionErrors) => {
    let newPersonalDetailsErrors = { ...personalDetailsErrors };
    if (isEmpty(personalDetails[action.type])) {
      newPersonalDetailsErrors[action.type] = true;
    } else {
      if (action.type === UpdatePersonalDetailsState.UpdateDob) {
        if (
          moment().subtract(21, "years").isBefore(moment(personalDetails.dob))
        ) {
          setIsPersonalDetailsValidP0(false);
          newPersonalDetailsErrors[action.type] = true;
          toast.error("You must be atleast 21 years old");
        } else newPersonalDetailsErrors[action.type] = false;
      } else if (
        action.type === UpdatePersonalDetailsState.UpdateMobileNumber
      ) {
        if (personalDetails.mobile_number.length !== 10) {
          setIsPersonalDetailsValidP0(false);
          toast.error("You must enter valid number");
          newPersonalDetailsErrors[action.type] = true;
        } else newPersonalDetailsErrors[action.type] = false;
      } else if (
        action.type === UpdatePersonalDetailsState.UpdateAlternateNumber
      ) {
        if (personalDetails.alternate_number.length !== 10) {
          setIsPersonalDetailsValidP0(false);
          toast.error("You must enter valid number");
          newPersonalDetailsErrors[action.type] = true;
        } else newPersonalDetailsErrors[action.type] = false;
      } else if (action.type === UpdatePersonalDetailsState.UpdateHeightFeet) {
        if (
          personalDetails.height_feet > 7 ||
          personalDetails.height_feet < 0
        ) {
          toast.error("Please enter height in feet");
          setIsPersonalDetailsValidP1(false);
          newPersonalDetailsErrors[action.type] = true;
        } else newPersonalDetailsErrors[action.type] = false;
      } else if (
        action.type === UpdatePersonalDetailsState.UpdateHeightInches
      ) {
        if (
          personalDetails.height_inches > 12 ||
          personalDetails.height_inches < 0
        ) {
          toast.error("Please enter height in inches");
          setIsPersonalDetailsValidP1(false);
          newPersonalDetailsErrors[action.type] = true;
        } else newPersonalDetailsErrors[action.type] = false;
      } else if (action.type === UpdatePersonalDetailsState.UpdateWeight) {
        if (personalDetails.weight > 512 || personalDetails.height_inches < 0) {
          toast.error("Please enter your weight");
          setIsPersonalDetailsValidP1(false);
          newPersonalDetailsErrors[action.type] = true;
        } else newPersonalDetailsErrors[action.type] = false;
      } else newPersonalDetailsErrors[action.type] = false;
    }
    setPersonalDetailsErrors(newPersonalDetailsErrors);
  };
  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);
  useEffect(() => {
    let errors = false;
    if (moment().isBefore(moment(personalDetails.dob))) errors = true;
    if (isEmpty(personalDetails.place_of_birth)) errors = true;
    if (personalDetails.marital_status === IMaritialStatus.INITIAL_STATE)
      errors = true;
    if (personalDetails.caste === ICaste.SELECT_CASTE) errors = true;
    if (personalDetails.blood_group === IBloodGroup.INITIAL_STATE)
      errors = true;
    if (
      personalDetails.sub_caste === ISubCaste.SELECT_SUB_CASTE &&
      isEmpty(personalDetails.other_caste)
    )
      errors = true;
    if (personalDetails.profile_photo === "default.png") errors = true;
    if (personalDetails.marital_status === IMaritialStatus.INITIAL_STATE)
      errors = true;
    if (personalDetails.residential_status === IResidentialStatus.INITIAL_STATE)
      errors = true;

    console.log(
      errors,
      personalDetails.mobile_number.length,
      moment().isBefore(moment(personalDetails.dob)),
    );
    setIsPersonalDetailsValidP0(errors);
  }, [personalDetails]);

  useEffect(() => {
    let errors = false;
    if (personalDetails.weight < 0) errors = true;
    if (personalDetails.height_feet < 0) errors = true;
    if (personalDetails.height_inches < 0) errors = true;
    if (isEmpty(personalDetails.address)) errors = true;
    if (personalDetails.complexion === IComplexion.INITIAL_STATE) errors = true;
    if (personalDetails.hasSpectacle === IBooleanState.Select) errors = true;
    if (personalDetails.isManglik === IManglikState.Select) errors = true;
    setIsPersonalDetailsValidP1(errors);
  }, [personalDetails]);

  const handleCreateP0 = async (): Promise<IHandlePersonalInfoCreation> => {
    try {
      const newPersonalDetails = {
        isMarried: personalDetails.isMarried,
        personal_details: {
          album: personalDetails.album,
          profile_photo: personalDetails.profile_photo,
          dob: personalDetails.dob,
          age: personalDetails.age,
          alternate_number: personalDetails.alternate_number,
          mobile_number: personalDetails.mobile_number,
          blood_group: personalDetails.blood_group,
          marital_status: personalDetails.marital_status,
          caste: personalDetails.caste,
          sub_caste: personalDetails.sub_caste,
          other_caste: personalDetails.other_caste,
          place_of_birth: personalDetails.place_of_birth,
          residential_status: personalDetails.residential_status,
        },
      };

      if (
        moment().subtract(21, "years").isBefore(moment(personalDetails.dob))
      ) {
        toast.error("You must be atleast 21 years old");
        setIsPersonalDetailsValidP0(false);
        return {
          error: true,
        };
      }

      if (
        !isEqual(
          {
            dob: personalDetails.dob,
            age: personalDetails.age,
            alternate_number: personalDetails.alternate_number,
            mobile_number: personalDetails.mobile_number,
            blood_group: personalDetails.blood_group,
            isMarried: personalDetails.isMarried,
            marital_status: personalDetails.marital_status,
            caste: personalDetails.caste,
            sub_caste: personalDetails.sub_caste,
            other_caste: personalDetails.other_caste,
            place_of_birth: personalDetails.place_of_birth,
            residential_status: personalDetails.residential_status,
          },
          {
            dob: userLocalStorage.personal_details.dob,
            age: userLocalStorage.personal_details.age,
            alternate_number:
              userLocalStorage.personal_details.alternate_number,
            mobile_number: userLocalStorage.personal_details.mobile_number,
            blood_group: userLocalStorage.personal_details.blood_group,
            isMarried: userLocalStorage.personal_details.isMarried,
            marital_status: userLocalStorage.personal_details.marital_status,
            caste: userLocalStorage.personal_details.caste,
            sub_caste: userLocalStorage.personal_details.sub_caste,
            other_caste: userLocalStorage.personal_details.other_caste,
            place_of_birth: userLocalStorage.personal_details.place_of_birth,
            residential_status:
              userLocalStorage.personal_details.residential_status,
          },
        )
      ) {
        let user = isAuth();
        user.personal_details.dob = personalDetails.dob;
        user.personal_details.age = personalDetails.age;
        user.personal_details.alternate_number =
          personalDetails.alternate_number;
        user.personal_details.mobile_number = personalDetails.mobile_number;
        user.personal_details.blood_group = personalDetails.blood_group;
        user.personal_details.marital_status = personalDetails.marital_status;
        user.personal_details.caste = personalDetails.caste;
        user.personal_details.sub_caste = personalDetails.sub_caste;
        user.personal_details.other_caste = personalDetails.other_caste;
        user.isMarried = personalDetails.isMarried;
        user.personal_details.place_of_birth = personalDetails.place_of_birth;
        user.personal_details.residential_status =
          personalDetails.residential_status;
        const responseStatus = await patchUserDetails(newPersonalDetails);
        if (responseStatus) {
          setLocalStorage("user", user);
        }
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };
  const handleCreateP1 = async (): Promise<IHandlePersonalInfoCreation> => {
    try {
      const newPersonalDetails = {
        personal_details: {
          height_feet: personalDetails.height_feet,
          height_inches: personalDetails.height_inches,
          complexion: personalDetails.complexion,
          weight: personalDetails.weight,
          isManglik: personalDetails.isManglik,
          health_description: personalDetails.health_description,
          address: personalDetails.address,
          hasSpectacle: personalDetails.hasSpectacle,
          description: personalDetails.description,
        },
      };
      if (personalDetails.height_feet > 7 || personalDetails.height_feet < 0) {
        toast.error("Please enter height in feet");
        setIsPersonalDetailsValidP1(false);
        return {
          error: true,
        };
      }
      if (
        personalDetails.height_inches > 12 ||
        personalDetails.height_inches < 0
      ) {
        toast.error("Please enter height in inches");
        setIsPersonalDetailsValidP1(false);
        return {
          error: true,
        };
      }
      if (personalDetails.weight > 512 || personalDetails.height_inches < 0) {
        toast.error("Please enter your weight");
        setIsPersonalDetailsValidP1(false);
        return {
          error: true,
        };
      }
      if (
        !isEqual(
          {
            height_feet: personalDetails.height_feet,
            height_inches: personalDetails.height_inches,
            complexion: personalDetails.complexion,
            weight: personalDetails.weight,
            isManglik: personalDetails.isManglik,
            health_description: personalDetails.health_description,
            address: personalDetails.address,
            hasSpectacle: personalDetails.hasSpectacle,
            description: personalDetails.description,
          },
          {
            height_feet: userLocalStorage.personal_details.height_feet,
            height_inches: userLocalStorage.personal_details.height_inches,
            complexion: userLocalStorage.personal_details.complexion,
            weight: userLocalStorage.personal_details.weight,
            isManglik: userLocalStorage.personal_details.isManglik,
            health_description:
              userLocalStorage.personal_details.health_description,
            address: userLocalStorage.personal_details.address,
            hasSpectacle: userLocalStorage.personal_details.hasSpectacle,
            description: userLocalStorage.personal_details.description,
          },
        )
      ) {
        let user = isAuth();
        user.personal_details.height_feet = personalDetails.height_feet;
        user.personal_details.height_inches = personalDetails.height_inches;
        user.personal_details.weight = personalDetails.weight;
        user.personal_details.complexion = personalDetails.complexion;
        user.personal_details.hasSpectacle = personalDetails.hasSpectacle;
        user.personal_details.isManglik = personalDetails.isManglik;
        user.personal_details.health_description =
          personalDetails.health_description;
        user.personal_details.address = personalDetails.address;
        user.personal_details.description = personalDetails.description;
        const responseStatus = await patchUserDetails(newPersonalDetails);
        if (responseStatus) {
          setLocalStorage("user", user);
        }
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const personalDetailsRetun: IPersonalDetailsReturn = {
    personalDetails,
    isPersonalDetailsValidP0,
    isPersonalDetailsValidP1,
    personalDetailsErrors,
    handleProfileDetailsUpdate,
    handlePersonalDetailsErrors,
    handleReset,
    handleCreateP0,
    handleCreateP1,
  };
  return personalDetailsRetun;
};

export interface IPersonalDetailsReturn {
  personalDetails: IPersonalDetails;
  isPersonalDetailsValidP1: boolean;
  isPersonalDetailsValidP0: boolean;
  personalDetailsErrors: IPersonalDetailsStateErrors;
  handlePersonalDetailsErrors: (action: PersonalDetailsActionErrors) => void;
  handleProfileDetailsUpdate: (action: PersonalDetailsAction) => void;
  handleReset: () => void;
  handleCreateP1: () => Promise<IHandlePersonalInfoCreation>;
  handleCreateP0: () => Promise<IHandlePersonalInfoCreation>;
}
