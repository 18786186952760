import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import {
  ICaste,
  IComplexion,
  IExpectations,
  ISubCaste,
  IExpectedJobType,
  IExpectedResidentialStatus,
  IExpComplexion,
  IExpCaste,
  IExpSubCaste,
  IExpManglik,
  IBooleanState,
} from "../../interfaces/model_helper";
import { isAuth, setLocalStorage } from "../../utils/helper";
import { patchUserDetails } from "../../services/userService";

/*****
 Show Add Personal Details hook
 *****/

export enum UpdateExpectationDetailsState {
  UpdateExpDetails = "exp_details",
  UpdateExpResidentialStatus = "exp_residential_status",
  UpdateExpOccupationType = "exp_occupation_type",
  UpdateExpCaste = "exp_caste",
  udpateKundaliToSee = "kundaliToSee",
  UpdateExpSubCaste = "exp_sub_caste",
  UpdateExpComplexion = "exp_complexion",
  UpdateExpManglik = "exp_isManglik",
}

export enum UpdateActions {
  Reset = "reset",
}

export interface IComplexionAction {
  type: UpdateExpectationDetailsState.UpdateExpComplexion;
  value: IExpComplexion;
}

export interface IResidentialStatusAction {
  type: UpdateExpectationDetailsState.UpdateExpResidentialStatus;
  value: IExpectedResidentialStatus;
}

export interface IOccupationTypeAction {
  type: UpdateExpectationDetailsState.UpdateExpOccupationType;
  value: IExpectedJobType;
}
export interface ICasteAction {
  type: UpdateExpectationDetailsState.UpdateExpCaste;
  value: IExpCaste;
}
export interface ISubCasteAction {
  type: UpdateExpectationDetailsState.UpdateExpSubCaste;
  value: IExpSubCaste;
}

export interface IStringAction {
  type:
    | UpdateExpectationDetailsState.UpdateExpDetails
    | UpdateExpectationDetailsState.udpateKundaliToSee
    | UpdateExpectationDetailsState.UpdateExpManglik;

  value: string;
}

interface IResetAction {
  type: UpdateActions.Reset;
}

export interface IHandleExpectationDetailsCreation {
  error: boolean;
  msg?: string;
}

export type ExpectationDetailsAction =
  | IStringAction
  | IResetAction
  | IComplexionAction
  | IOccupationTypeAction
  | ISubCasteAction
  | IResidentialStatusAction
  | ICasteAction;

export type ExpectationDetailsErrorsAction =
  | IStringAction
  | IComplexionAction
  | IOccupationTypeAction
  | ISubCasteAction
  | IResidentialStatusAction
  | ICasteAction;

const addExpectationDetailsReducer = (
  state: IExpectations,
  action: ExpectationDetailsAction,
): IExpectations => {
  let newExpectationDetails: IExpectations = { ...state };
  switch (action.type) {
    case UpdateExpectationDetailsState.UpdateExpDetails:
      newExpectationDetails.exp_details = action.value;
      break;
    case UpdateExpectationDetailsState.udpateKundaliToSee:
      newExpectationDetails.exp_kundali = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpOccupationType:
      newExpectationDetails.exp_occupation_type = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpResidentialStatus:
      newExpectationDetails.exp_residential_status = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpCaste:
      newExpectationDetails.exp_caste = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpSubCaste:
      newExpectationDetails.exp_sub_caste = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpComplexion:
      newExpectationDetails.exp_complexion = action.value;
      break;
    case UpdateExpectationDetailsState.UpdateExpManglik:
      newExpectationDetails.exp_isManglik = action.value;
      break;
  }
  return newExpectationDetails;
};

export const useExpectationDetails = () => {
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());

  const initialExpectationDetails: IExpectations = {
    exp_details:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_details) ??
      "",
    exp_kundali:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_kundali) ??
      IBooleanState.Select,
    exp_occupation_type:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_occupation_type) ??
      IExpectedJobType.INITIAL_STATE,
    exp_residential_status:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_residential_status) ??
      IExpectedResidentialStatus.INITIAL_STATE,
    exp_caste:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_caste) ??
      ICaste.SELECT_CASTE,
    exp_sub_caste:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_sub_caste) ??
      ISubCaste.SELECT_SUB_CASTE,
    exp_complexion:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_complexion) ??
      IComplexion.INITIAL_STATE,
    exp_isManglik:
      (userLocalStorage.expectation &&
        userLocalStorage.expectation.exp_isManglik) ??
      IExpManglik.INITIAL_STATE,
  };

  const [ExpectationDetails, dispatchExpectationDetails] = useReducer(
    addExpectationDetailsReducer,
    initialExpectationDetails,
  );

  const handleExpectationDetailsUpdate = (action: ExpectationDetailsAction) => {
    dispatchExpectationDetails(action);
  };

  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);

  const handleCreate = async (): Promise<IHandleExpectationDetailsCreation> => {
    try {
      if (isEmpty(ExpectationDetails.exp_details)) {
        return {
          error: false,
        };
      } else {
        const newExpectation = {
          exp_details: ExpectationDetails.exp_details,
          exp_occupation_type: ExpectationDetails.exp_occupation_type,
          exp_residential_status: ExpectationDetails.exp_residential_status,
          exp_caste: ExpectationDetails.exp_caste,
          exp_kundali: ExpectationDetails.exp_kundali,
          exp_sub_caste: ExpectationDetails.exp_sub_caste,
          exp_complexion: ExpectationDetails.exp_complexion,
          exp_isManglik: ExpectationDetails.exp_isManglik,
        };
        if (
          newExpectation.exp_caste !== IExpCaste.BRAHMIN &&
          newExpectation.exp_sub_caste
        ) {
          newExpectation.exp_sub_caste = "";
        }
        if (
          newExpectation.exp_occupation_type === IExpectedJobType.INITIAL_STATE
        ) {
          newExpectation.exp_occupation_type = "";
        }
        if (newExpectation.exp_isManglik === IExpManglik.INITIAL_STATE) {
          newExpectation.exp_occupation_type = "";
        }
        if (
          newExpectation.exp_residential_status ===
          IExpectedResidentialStatus.INITIAL_STATE
        ) {
          newExpectation.exp_residential_status = "";
        }
        if (newExpectation.exp_complexion === IExpComplexion.INITIAL_STATE) {
          newExpectation.exp_complexion = "";
        }
        if (newExpectation.exp_caste === IExpCaste.SELECT_CASTE) {
          newExpectation.exp_caste = "";
        }
        if (newExpectation.exp_kundali === IBooleanState.Select) {
          newExpectation.exp_kundali = "";
        }
        const newExpectationDetails = {
          expectation: newExpectation,
        };

        if (!isEqual(newExpectation, { ...userLocalStorage.expectation })) {
          let user = isAuth();
          user.expectation = ExpectationDetails;

          const responseStatus = await patchUserDetails(newExpectationDetails);
          if (responseStatus) {
            setLocalStorage("user", user);
          }
        }
        return {
          error: false,
        };
      }
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const ExpectationDetailsRetun: IExpectationDetailsReturn = {
    ExpectationDetails,
    handleExpectationDetailsUpdate,
    handleCreate,
  };
  return ExpectationDetailsRetun;
};

export interface IExpectationDetailsReturn {
  ExpectationDetails: IExpectations;
  handleExpectationDetailsUpdate: (action: ExpectationDetailsAction) => void;
  handleCreate: () => Promise<IHandleExpectationDetailsCreation>;
}
