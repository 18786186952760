export enum IBloodGroup {
  INITIAL_STATE = "Select Blood group",
  A_POSITIVE = "A +ve",
  A_NEGATIVE = "A -ve",
  B_POSITIVE = "B +ve",
  B_NEGATIVE = "B -ve",
  AB_POSITIVE = "AB +ve",
  AB_NEGATIVE = "AB -ve",
  O_POSITIVE = "O +ve",
  O_NEGATIVE = "O -ve",
}

export enum IBooleanState {
  Select = "Select",
  Yes = "true",
  No = "false",
}

export enum IManglikState {
  Select = "Select",
  Yes = "Yes",
  No = "No",
  dontKnow = "Dont know",
}

export enum IMaritialStatus {
  INITIAL_STATE = "Select Marritial Status",
  SINGLE = "Single",
  WIDOW_WITH_CHILD = "Widow with child",
  DIVORCED_WITH_CHILD = "Divorced with child",
  DIVORCED_WITHOUT_CHILD = "Divorced without child",
  WIDOW_WITHOUT_CHILD = "Widow without child",
}
export enum ICaste {
  SELECT_CASTE = "Select a Caste",
  BRAHMIN = "Brahmin",
  OTHERS = "Others",
}
export enum IExpCaste {
  SELECT_CASTE = "Select a Caste",
  BRAHMIN = "Brahmin",
  OTHERS = "Others",
  ANY = "Any",
}

export enum IFilterSubCaste {
  DESHASTHA = "Deshastha",
  DEVRUKHE = "Devrukhe",
  KARHADE = "Karhade",
  KOKANASTHA = "Kokanastha",
}

export enum IFilterAge {
  frist = "21-24",
  second = "25-27",
  third = "28-31",
  fourth = "32-35",
  fifth = "35 above",
}

export enum IFilterIncome {
  frist = "Upto 7.5 Lakhs",
  second = "7.5 Lakhs to 12.5 Lakhs",
  third = "12.5 Lakhs to 20 Lakhs",
  fifth = "Above 20 Lakhs",
}

export enum IExpSubCaste {
  SELECT_SUB_CASTE = "Select Sub Caste",
  ANY_BRAHMIN = "Any Brahmin",
  DESHASTHA = "Deshastha",
  DEVRUKHE = "Devrukhe",
  KARHADE = "Karhade",
  KOKANASTHA = "Kokanastha",
}

export enum ISubCaste {
  SELECT_SUB_CASTE = "Select Sub Caste",
  DESHASTHA = "Deshastha",
  DEVRUKHE = "Devrukhe",
  KARHADE = "Karhade",
  KOKANASTHA = "Kokanastha",
  OTHERS = "Others",
}

export enum IExpComplexion {
  INITIAL_STATE = "Select Complexion",
  ANY = "Any",
  BLACK = "Black",
  WHEATISH = "Wheatish",
  FAIR = "Fair",
}

export enum IComplexion {
  INITIAL_STATE = "Select Complexion",
  BLACK = "Black",
  WHEATISH = "Wheatish",
  FAIR = "Fair",
}

export enum IFamilyMaritialStatus {
  INITIAL_STATE = "Select",
  SEPARATLY = "Seperately",
  TOGETHER = "Together",
  DIVORCED = "Divorced",
}

export enum ISalaryRange {
  select_salary_range = "Select Salary Range",
  BELLOW_5_LPA = "Below 5 lakhs",
  BETWEEN_5_75_LPA = "5 lakhs - 7.5 lakhs",
  BETWEEN_75_10_LPA = "7.5 lakhs - 10 lakhs",
  BETWEEN_10_125_LPA = "10 lakhs - 12.5 lakhs",
  BETWEEN_125_15_LPA = "12.5 lakhs - 15 lakhs",
  BETWEEN_15_20_LPA = "15 lakhs - 20 lakhs",
  ABOVE_15_LPA = "Above 20 lakhs",
}

export enum IJobType {
  INITIAL_STATE = "Select Job Type",
  SERVICE = "Service",
  BUSINESS = "Business",
  SELF_EMPLOYED = "Self Employed",
  NOT_WORKING = "Not Working",
}

export enum IExpectedResidentialStatus {
  INITIAL_STATE = "Expected Residential Status",
  OUTSIDE_INDIA = "Outside India",
  WITHIN_INDIA = "Within Indian",
  ANY = "Any",
}
// export enum IResidentialStatus {
//   OUTSIDE_INDIA = "Outside India",
//   WITHIN_INDIA = "Within Indian",
// }
export enum IExpManglik {
  INITIAL_STATE = "Select Manglik",
  YES = "Yes",
  NO = "No",
  ANY = "Any",
}

export enum IExpectedJobType {
  INITIAL_STATE = "Expected Job Type",
  SERVICE = "Service",
  BUSINESS = "Business",
  SELF_EMPLOYED = "Self Employed",
  ANY = "Any",
}

export enum IDegreeType {
  INITIAL_STATE = "Select Degree Type",
  UNDER_GRAD = "Under-Graduate",
  GRAD = "Graduate",
  POST_GRAD = "POST-Graduate",
  PROFFESSIONAL = "Professional",
}
export interface IPersonalDetails {
  album: string[];
  mobile_number: string;
  alternate_number: string;
  profile_photo: string;
  dob: string;
  blood_group: IBloodGroup;
  marital_status: IMaritialStatus;
  caste: ICaste;
  other_caste: string;
  place_of_birth: string;
  age: number;
  sub_caste: ISubCaste;
  residential_status: IResidentialStatus;
  height_inches: number;
  height_feet: number;
  weight: number;
  complexion: IComplexion;
  isManglik: string;
  hasSpectacle: string;
  isMarried: string;
  health_description: string;
  description: string;
  address: string;
}
export interface IEducationDetails {
  degree_specification: string;
  college_name: string;
  degree: IDegreeType;
  year_of_passing: string;
  special_achievements: string;
}

export interface IExpectations {
  exp_kundali: string;
  exp_caste: IExpCaste | string;
  exp_sub_caste: IExpSubCaste | string;
  exp_complexion: IExpComplexion | string;
  exp_residential_status: IExpectedResidentialStatus | string;
  exp_occupation_type: IExpectedJobType | string;
  exp_isManglik: string;
  exp_details: string;
}

export interface IFamilyDetails {
  father_first_name: string;
  father_middle_name: string;
  father_last_name: string;
  father_mobile_number: string;
  father_occupation: string;
  father_dob: string;
  father_education: string;
  father_isAlive: string | boolean;
  mother_first_name: string;
  mother_middle_name: string;
  mother_last_name: string;
  mother_mobile_number: string;
  mother_isAlive: string | boolean;
  mother_occupation: string;
  mother_dob: string;
  mother_education: string;
  living_status: IFamilyMaritialStatus | string;
  address: string;
  family_background: string;
}
export interface IOccupationDetails {
  company_name: string;
  position: string;
  location: string;
  salary_range: ISalaryRange;
  type: IJobType;
  description: string;
  future_plans: string;
}
export interface IKundaliDetails {
  kundali_exists: string;
  kundali_photo: string;
  nakshatra: string;
  charan: string;
  naadi: string;
  raas: string;
  gan: string;
  gotra: string;
  time_of_birth: string;
}

export enum IResidentialStatus {
  INITIAL_STATE = "Select Residential Status",
  IN = "India",
  AF = "Afghanistan",
  AL = "Albania",
  DZ = "Algeria",
  AS = "American Samoa",
  AD = "Andorra",
  AO = "Angola",
  AI = "Anguilla",
  AQ = "Antarctica",
  AG = "Antigua and Barbuda",
  AR = "Argentina",
  AM = "Armenia",
  AW = "Aruba",
  AU = "Australia",
  AT = "Austria",
  AZ = "Azerbaijan",
  BS = "Bahamas (the)",
  BH = "Bahrain",
  BD = "Bangladesh",
  BB = "Barbados",
  BY = "Belarus",
  BE = "Belgium",
  BZ = "Belize",
  BJ = "Benin",
  BM = "Bermuda",
  BT = "Bhutan",
  BO = "Bolivia (Plurinational State of)",
  BQ = "Bonaire, Sint Eustatius and Saba",
  BA = "Bosnia and Herzegovina",
  BW = "Botswana",
  BV = "Bouvet Island",
  BR = "Brazil",
  IO = "British Indian Ocean Territory (the)",
  BN = "Brunei Darussalam",
  BG = "Bulgaria",
  BF = "Burkina Faso",
  BI = "Burundi",
  CV = "Cabo Verde",
  KH = "Cambodia",
  CM = "Cameroon",
  CA = "Canada",
  KY = "Cayman Islands (the)",
  CF = "Central African Republic (the)",
  TD = "Chad",
  CL = "Chile",
  CN = "China",
  CX = "Christmas Island",
  CC = "Cocos (Keeling) Islands (the)",
  CO = "Colombia",
  KM = "Comoros (the)",
  CD = "Congo (the Democratic Republic of the)",
  CG = "Congo (the)",
  CK = "Cook Islands (the)",
  CR = "Costa Rica",
  HR = "Croatia",
  CU = "Cuba",
  CW = "Curaçao",
  CY = "Cyprus",
  CZ = "Czechia",
  CI = "Côte d'Ivoire",
  DK = "Denmark",
  DJ = "Djibouti",
  DM = "Dominica",
  DO = "Dominican Republic (the)",
  EC = "Ecuador",
  EG = "Egypt",
  SV = "El Salvador",
  GQ = "Equatorial Guinea",
  ER = "Eritrea",
  EE = "Estonia",
  SZ = "Eswatini",
  ET = "Ethiopia",
  FK = "Falkland Islands (the) [Malvinas]",
  FO = "Faroe Islands (the)",
  FJ = "Fiji",
  FI = "Finland",
  FR = "France",
  GF = "French Guiana",
  PF = "French Polynesia",
  TF = "French Southern Territories (the)",
  GA = "Gabon",
  GM = "Gambia (the)",
  GE = "Georgia",
  DE = "Germany",
  GH = "Ghana",
  GI = "Gibraltar",
  GR = "Greece",
  GL = "Greenland",
  GD = "Grenada",
  GP = "Guadeloupe",
  GU = "Guam",
  GT = "Guatemala",
  GG = "Guernsey",
  GN = "Guinea",
  GW = "Guinea-Bissau",
  GY = "Guyana",
  HT = "Haiti",
  HM = "Heard Island and McDonald Islands",
  VA = "Holy See (the)",
  HN = "Honduras",
  HK = "Hong Kong",
  HU = "Hungary",
  IS = "Iceland",
  ID = "Indonesia",
  IR = "Iran (Islamic Republic of)",
  IQ = "Iraq",
  IE = "Ireland",
  IM = "Isle of Man",
  IL = "Israel",
  IT = "Italy",
  JM = "Jamaica",
  JP = "Japan",
  JE = "Jersey",
  JO = "Jordan",
  KZ = "Kazakhstan",
  KE = "Kenya",
  KI = "Kiribati",
  KP = "Korea (the Democratic People's Republic of)",
  KR = "Korea (the Republic of)",
  KW = "Kuwait",
  KG = "Kyrgyzstan",
  LA = "Lao People's Democratic Republic (the)",
  LV = "Latvia",
  LB = "Lebanon",
  LS = "Lesotho",
  LR = "Liberia",
  LY = "Libya",
  LI = "Liechtenstein",
  LT = "Lithuania",
  LU = "Luxembourg",
  MO = "Macao",
  MG = "Madagascar",
  MW = "Malawi",
  MY = "Malaysia",
  MV = "Maldives",
  ML = "Mali",
  MT = "Malta",
  MH = "Marshall Islands (the)",
  MQ = "Martinique",
  MR = "Mauritania",
  MU = "Mauritius",
  YT = "Mayotte",
  MX = "Mexico",
  FM = "Micronesia (Federated States of)",
  MD = "Moldova (the Republic of)",
  MC = "Monaco",
  MN = "Mongolia",
  ME = "Montenegro",
  MS = "Montserrat",
  MA = "Morocco",
  MZ = "Mozambique",
  MM = "Myanmar",
  NA = "Namibia",
  NR = "Nauru",
  NP = "Nepal",
  NL = "Netherlands (the)",
  NC = "New Caledonia",
  NZ = "New Zealand",
  NI = "Nicaragua",
  NE = "Niger (the)",
  NG = "Nigeria",
  NU = "Niue",
  NF = "Norfolk Island",
  MP = "Northern Mariana Islands (the)",
  NO = "Norway",
  OM = "Oman",
  PK = "Pakistan",
  PW = "Palau",
  PS = "Palestine, State of",
  PA = "Panama",
  PG = "Papua New Guinea",
  PY = "Paraguay",
  PE = "Peru",
  PH = "Philippines (the)",
  PN = "Pitcairn",
  PL = "Poland",
  PT = "Portugal",
  PR = "Puerto Rico",
  QA = "Qatar",
  MK = "Republic of North Macedonia",
  RO = "Romania",
  RU = "Russian Federation (the)",
  RW = "Rwanda",
  RE = "Réunion",
  BL = "Saint Barthélemy",
  SH = "Saint Helena, Ascension and Tristan da Cunha",
  KN = "Saint Kitts and Nevis",
  LC = "Saint Lucia",
  MF = "Saint Martin (French part)",
  PM = "Saint Pierre and Miquelon",
  VC = "Saint Vincent and the Grenadines",
  WS = "Samoa",
  SM = "San Marino",
  ST = "Sao Tome and Principe",
  SA = "Saudi Arabia",
  SN = "Senegal",
  RS = "Serbia",
  SC = "Seychelles",
  SL = "Sierra Leone",
  SG = "Singapore",
  SX = "Sint Maarten (Dutch part)",
  SK = "Slovakia",
  SI = "Slovenia",
  SB = "Solomon Islands",
  SO = "Somalia",
  ZA = "South Africa",
  GS = "South Georgia and the South Sandwich Islands",
  SS = "South Sudan",
  ES = "Spain",
  LK = "Sri Lanka",
  SD = "Sudan (the)",
  SR = "Suriname",
  SJ = "Svalbard and Jan Mayen",
  SE = "Sweden",
  CH = "Switzerland",
  SY = "Syrian Arab Republic",
  TW = "Taiwan",
  TJ = "Tajikistan",
  TZ = "Tanzania, United Republic of",
  TH = "Thailand",
  TL = "Timor-Leste",
  TG = "Togo",
  TK = "Tokelau",
  TO = "Tonga",
  TT = "Trinidad and Tobago",
  TN = "Tunisia",
  TR = "Turkey",
  TM = "Turkmenistan",
  TC = "Turks and Caicos Islands (the)",
  TV = "Tuvalu",
  UG = "Uganda",
  UA = "Ukraine",
  AE = "United Arab Emirates (the)",
  GB = "United Kingdom of Great Britain and Northern Ireland (the)",
  UM = "United States Minor Outlying Islands (the)",
  US = "United States of America (the)",
  UY = "Uruguay",
  UZ = "Uzbekistan",
  VU = "Vanuatu",
  VE = "Venezuela (Bolivarian Republic of)",
  VN = "Viet Nam",
  VG = "Virgin Islands (British)",
  VI = "Virgin Islands (U.S.)",
  WF = "Wallis and Futuna",
  EH = "Western Sahara",
  YE = "Yemen",
  ZM = "Zambia",
  ZW = "Zimbabwe",
  AX = "Åland Islands",
}
