/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { isAuth } from "../../utils/helper";
import { useState } from "react";
import Layout from "../layout/Layout";
import FindMales from "../../components/list/FindMale";
import { SearchBar } from "../../components/utils/other";
import { getAction } from "../../services/generalServices";
import ReactPaginate from "react-paginate";
import "../../style/other/pagination.css";
import Loading from "../../components/utils/LoadingSign";
import { IUserData } from "../../interfaces/Interfaces.model";
import { useNavigate } from "react-router";
import WithAuth from "../../hooks/hoc/WithAuth";
import { isEmpty } from "lodash";

const MaleList = () => {
  const linkStyles = {
    textDecoration: "none",
    textUnderlinePosition: "none",
    color: "black",
  };

  const [values, setValues] = useState([]);
  const [nameList, setNameList] = useState<any>(null);
  const [searchValues, setSearchValues] = useState([]);
  const [filterOpts, setfilterOpts] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const MalesPerPage = 10;

  const svalues = isAuth()
    ? searchValues.filter((value: IUserData) => value._id !== isAuth()._id)
    : searchValues;
  //Get Current MaleList
  const indexOfLastMale = currentPage * MalesPerPage;
  const indexOfFirstMale = indexOfLastMale - MalesPerPage;
  const currentMales = svalues.slice(indexOfFirstMale, indexOfLastMale);
  //Change Page
  const paginate = ({ selected: selectedPage }: any) =>
    setCurrentPage(selectedPage + 1);
  const pageCount = Math.ceil(svalues.length / MalesPerPage);
  let render = <Loading />;
  //const limit = 20;
  const navigate = useNavigate();
  useEffect(() => {
    const getmalList = async () => {
      let MalesList = [];
      const s = await getAction(
        `/users?gender=male&isRegistered=true&sort=first_name`,
      );
      if (s && s.status === 200)
        if (s.data.data && s.data.data.length) MalesList = s.data.data;
      setValues(MalesList);
      setSearchValues(MalesList);
      let names = Array.from(
        new Set(
          MalesList.filter(
            (value: IUserData) => value._id !== isAuth()._id,
          ).map((o: IUserData) => `${o.first_name} ${o.last_name}`),
        ),
      );
      setNameList(names);
    };
    getmalList();

    return () => {
      setValues([]);
      setSearchValues([]);
      setNameList([]);
    };
  }, []);

  const getFilterMaleList = async (filterOpts: string[]) => {
    let MalesList = [];
    const s = await getAction(
      `/users?gender=male&isRegistered=true&sort=first_name${filterOpts.join(
        "",
      )}`,
    );
    if (s && s.status === 200)
      if (s.data.data && s.data.data.length) MalesList = s.data.data;
    setValues(MalesList);
    setSearchValues(MalesList);
    let names = Array.from(
      new Set(
        MalesList.filter((value: IUserData) => value._id !== isAuth()._id).map(
          (o: IUserData) => `${o.first_name} ${o.last_name}`,
        ),
      ),
    );
    setNameList(names);
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newData = filterOpts.filter(
      (value: string) => value !== chipToDelete,
    );
    setfilterOpts(newData);
    getFilterMaleList(newData);
  };

  const getFilteredMaleList = async (filter: string) => {
    let MalesList = [];
    const s = await getAction(
      `/users?gender=male&isRegistered=true&sort=first_name${filter}`,
    );
    if (s && s.status === 200)
      if (s.data.data && s.data.data.length) MalesList = s.data.data;
    setValues(MalesList);
    setSearchValues(MalesList);
    let names = Array.from(
      new Set(
        MalesList.filter((value: IUserData) => value._id !== isAuth()._id).map(
          (o: IUserData) => `${o.first_name} ${o.last_name}`,
        ),
      ),
    );
    setNameList(names);
  };

  const handleFilterChange = (field: string, value: string) => {
    switch (field) {
      case "all":
        getFilteredMaleList("");
        setfilterOpts([]);
        break;
      case "caste":
        setfilterOpts([...filterOpts, `&personal_details.caste=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&personal_details.caste=${value}`);
        } else {
          getFilterMaleList([
            ...filterOpts,
            `&personal_details.caste=${value}`,
          ]);
        }
        break;
      case "subCaste":
        setfilterOpts([...filterOpts, `&personal_details.sub_caste=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&personal_details.sub_caste=${value}`);
        } else {
          getFilterMaleList([
            ...filterOpts,
            `&personal_details.sub_caste=${value}`,
          ]);
        }
        break;
      case "maritalStatus":
        setfilterOpts([
          ...filterOpts,
          `&personal_details.marital_status=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&personal_details.marital_status=${value}`);
        } else {
          getFilterMaleList([
            ...filterOpts,
            `&personal_details.marital_status=${value}`,
          ]);
        }
        break;
      case "age":
        setfilterOpts([...filterOpts, `&personal_details.age=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&personal_details.age=${value}`);
        } else {
          getFilterMaleList([...filterOpts, `&personal_details.age=${value}`]);
        }
        break;
      case "annualIncome":
        setfilterOpts([
          ...filterOpts,
          `&occupation_details.salary_range=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&occupation_details.salary_range=${value}`);
        } else {
          getFilterMaleList([
            ...filterOpts,
            `&occupation_details.salary_range=${value}`,
          ]);
        }
        break;
      case "education":
        setfilterOpts([
          ...filterOpts,
          `&educational_qualification.degree=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredMaleList(`&educational_qualification.degree=${value}`);
        } else {
          getFilterMaleList([
            ...filterOpts,
            `&educational_qualification.degree=${value}`,
          ]);
        }
        break;
      default:
        break;
    }
  };

  const searchName = (newList: React.SetStateAction<never[]>) => {
    setSearchValues(newList);
    if (currentPage !== 1) setCurrentPage(1);
  };

  if (nameList) {
    render = (
      <Layout rightSidebar={true}>
        <div className="col-lg-9  mt-2">
          <SearchBar
            role="Male"
            list={values}
            handleDelete={handleDelete}
            nameList={nameList}
            filterOpts={filterOpts}
            searchName={searchName}
            selectedFilter={selectedFilter}
            handleFilterChange={handleFilterChange}
            setSelectedFilter={setSelectedFilter}
          />
          <FindMales
            navigate={navigate}
            searchValues={currentMales}
            linkStyles={linkStyles}
          />
          <div className="center">
            <ReactPaginate
              onPageChange={paginate}
              pageCount={pageCount}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </Layout>
    );
  }

  return render;
};

export default WithAuth("")(MaleList);
