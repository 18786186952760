import React from "react";
import { Link } from "react-router-dom";
import { IUserData } from "../../interfaces/Interfaces.model";
import { isAuth } from "../../utils/helper";
import { checkUser } from "../../utils/utility";
const RightSidebarItem = ({
  male,
  key,
  female,
}: {
  male?: IUserData;
  key: number;
  female?: IUserData;
}) => {
  const item = (object: IUserData | null) => {
    return (
      <div
        className="card py-3 px-4 mt-3 border-0 text-lg-center"
        style={{ borderRadius: "20px" }}
        key={key}
      >
        <ul className="list-group list-group-flush">
          {object ? (
            <>
              <Link
                target="_blank"
                to={`/${checkUser(isAuth())}/${object._id}`}
              >
                <h5>
                  {object.first_name} {object.last_name}
                </h5>
              </Link>

              <p>{object.emailId}</p>
            </>
          ) : (
            <h5>No match found yet. </h5>
          )}
        </ul>
      </div>
    );
  };

  return male
    ? item(male) // If male
    : female
    ? item(female) // If female
    : item(null); // Else LOADING...
};

export default RightSidebarItem;
