import React from "react";
import Layout from "../layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div style={{ height: "70vh", margin: "4rem" }}>
        <h1>Privacy Policy of Vadhuvar Mahiti Kendra</h1>
        Thane We value the customers and privacy of customer information and the
        highest standards of safety of personal information. The main objective
        of this website is to work as an ‘intermediary’ between the customers
        who are willing to find prospective and suitable lawful partner. Privacy
        policy of Vadhuvar Mahiti Kendra, Thane is subject to change at any time
        without notice. To make sure you are aware of any changes, please review
        this policy periodically. By visiting this Website, it means you agree
        and accept by the terms and conditions of this Privacy Policy. This
        Privacy Policy is incorporated into and subject to the Terms of Use. 1.
        Collection of personal data and other information: When you use our
        Website, we collect and store your personal information which is
        provided by you from time to time. Our motive is to provide you a safe,
        efficient, smooth service and to provide services and features that most
        likely meet your needs. 2. Use of personal data / your information We
        use personal information to find prospective and suitable lawful
        partner. By using the Website www.shubhamangalthane.org and/ or by
        providing your information, you consent to the collection and use of the
        information you disclose on the Website in accordance with this Privacy
        Policy, including but not limited to your consent for sharing your
        information as per this privacy policy.
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
