import React, { useState } from "react";
// import { Link } from "react-router-dom";
import LoadingSign from "../utils/LoadingSign";
import { INotiData } from "../../interfaces/Interfaces.model";
import { IPerson } from "../../hooks/UsersList";
import moment from "moment";
import { Tooltip } from "@mui/material";

interface IProps {
  notification: INotiData;
  fromUser: IPerson;
  deleteHandler: (e: any, notification_id: string) => void;
  viewHandler: (e: any, url: string) => void;
  acceptHandler: (e: any, user: IPerson) => void;
}

const Notification = (props: IProps) => {
  let [showAcceptRejectButtons, setShowAcceptRejectButtons] = useState("");
  const data = props.notification;
  let notificationText = data.message
    ? data.message
    : props.fromUser
    ? `You have a notification from ${props.fromUser.first_name} ${props.fromUser.last_name}`
    : null;

  let render = <LoadingSign />;
  if (props.fromUser)
    render = (
      <div
        className="home-forum question-box pb-lg-4"
        style={{ position: "relative" }}
      >
        <h5 style={{ display: "flex", justifyContent: "space-between" }}>
          <strong>{notificationText}</strong>
          <span className="PrivateForumHome-span d-inline float-lg-right">
            <Tooltip title="Delete notification">
              <button
                className="btn btn-outline-danger"
                style={{ margin: "0px" }}
                onClick={(e) => {
                  props.deleteHandler(e, data._id);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </Tooltip>
          </span>
        </h5>
        <span
          className="PrivateForumHome-span"
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "1rem",
          }}
        >
          <strong>
            {moment(props.notification.date).format("DD-MM-YYYY hh:mm:ss")}
          </strong>
          <div className="">
            <button
              className="btn btn-outline-primary"
              style={{ margin: "0px" }}
              onClick={(e) => {
                props.viewHandler(e, data.link);
              }}
            >
              View Profile
            </button>
            <button
              className="btn btn-outline-success"
              disabled={showAcceptRejectButtons === "accepted"}
              style={{ margin: "0px" }}
              onClick={(e) => {
                setShowAcceptRejectButtons("accepted");
                props.acceptHandler(e, props.fromUser);
              }}
            >
              Like Back
            </button>
          </div>
        </span>
      </div>
    );
  return render;
};

export default Notification;
