import React, { useState } from "react";
import { Grid } from "@mui/material";
import Header from "../layout/Header";
import RegisterPersonalDetailsPhase0 from "../../components/register/RegisterPersonalDetailsPhase0";
import RegisterPersonalDetailsPhase1 from "../../components/register/RegisterPersonalDetailsPhase1";
import RegisterEducationalDetails from "../../components/register/RegisterEducationalDetails";
import RegisterFamilyDetails from "../../components/register/RegisterFamilyDetails";
import RegisterOccupationDetails from "../../components/register/RegisterOccupationDetails";
import RegisterKundaliDetails from "../../components/register/RegisterKundaliDetails";
import RegisterPreferenceDetails from "../../components/register/RegisterPreferenceDetails";
import RegistrationLeftSideBar from "../../components/register/RegistrationLeftSideBar";
import { isAuth, onAuth } from "../../utils/helper";
import Loading from "../../components/utils/LoadingSign";
import WithRegister from "../../hooks/hoc/withRegister";
import EditPhotoAlbum from "../../components/editProfile/EditPhotoAlbum";

export interface IStateData {
  phase: number;
  step: number;
}

const RegisterProfile = () => {
  const [state, setState] = useState<IStateData>({
    step: 1,
    phase: 0,
  });
  const { step, phase } = state;
  if (window.location.search.search("razorpay_payment_id") === 1) {
    onAuth(() => {
      window.location.href = "/register";
    });
  }
  const nextStep = () => {
    const { step } = state;
    setState({ ...state, step: step + 1 });
  };

  const prevStep = () => {
    const { step } = state;
    setState({ ...state, step: step - 1 });
  };

  const checkActive = (currentStep: number) => {
    if (currentStep === step) {
      return "btn profile__btn dp--flex dp--jcsb btn--blue";
    } else {
      return "btn profile__btn dp--flex dp--jcsb btn--white";
    }
  };

  let renderSection;
  switch (step) {
    case 1:
      switch (phase) {
        case 0:
          renderSection = (
            <RegisterPersonalDetailsPhase0 state={state} setState={setState} />
          );
          break;
        case 1:
          renderSection = (
            <RegisterPersonalDetailsPhase1 state={state} setState={setState} />
          );
          break;
      }
      break;
    case 2:
      renderSection = (
        <RegisterEducationalDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 3:
      renderSection = (
        <RegisterOccupationDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 4:
      renderSection = (
        <RegisterFamilyDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 5:
      renderSection = (
        <RegisterPreferenceDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 6:
      renderSection = (
        <EditPhotoAlbum prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 7:
      renderSection = <RegisterKundaliDetails prevStep={prevStep} />;
      break;
    default:
      break;
  }
  if (isAuth()) {
    return (
      <>
        <Header />
        <Grid
          container
          columnSpacing={1}
          rowSpacing={1}
          style={{ margin: "24px 24px 24px 0 ", width: "100%" }}
        >
          <RegistrationLeftSideBar
            state={state}
            setState={setState}
            checkActive={checkActive}
          />
          <Grid item lg={8.8}>
            {renderSection}
          </Grid>
        </Grid>
      </>
    );
  } else return <Loading />;
};

export default WithRegister()(RegisterProfile);
