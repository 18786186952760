import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";
import moment from "moment";
import { isEmpty } from "lodash";

const ProfilePersonalAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  if (user) {
    return (
      <>
        <StyledAccordion
          expanded={first}
          onChange={(e: any) => {
            setfirst(!first);
          }}
        >
          <StyledAccordionSummary aria-label="Profile">
            <Grid container alignItems="center" justifyContent="space-between">
              <h4 className="text-primary font-weight-bold">
                Personal Information
              </h4>
            </Grid>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              rowSpacing={1}
            >
              <Grid item lg={12} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Full Name:
                </Typography>
                <Typography
                  style={{
                    textTransform: "capitalize",
                  }}
                >{`${user.first_name} ${user.middle_name ?? ""} ${
                  user.last_name ?? ""
                }`}</Typography>
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Contact No.:
                  </Typography>
                  <Typography style={{ marginRight: "5rem" }}>
                    {user.personal_details.mobile_number}
                  </Typography>
                </Grid>
                {!isEmpty(user.personal_details.alternate_number) && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Alternate Contact No.:
                    </Typography>
                    <Typography>
                      {user.personal_details.alternate_number}
                    </Typography>
                  </Grid>
                )}
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Date of Birth:
                  </Typography>
                  <Typography>
                    {moment(user.personal_details.dob).format("DD/MM/YYYY") ===
                    "Invalid date"
                      ? user.personal_details.dob
                      : moment(user.personal_details.dob).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Age
                  </Typography>
                  <Typography> {user.personal_details.age}</Typography>
                </Grid>
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    place of Birth:
                  </Typography>
                  <Typography style={{ marginRight: "6.4rem" }}>
                    {user.personal_details.place_of_birth}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Marital status:
                  </Typography>
                  <Typography>
                    {" "}
                    {user.personal_details.marital_status}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Caste:
                  </Typography>
                  <Typography> {user.personal_details.caste}</Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Sub Caste:
                  </Typography>
                  <Typography> {user.personal_details.sub_caste}</Typography>
                </Grid>
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    height:
                  </Typography>
                  <Typography style={{ marginRight: "6.4rem" }}>
                    {`${user.personal_details.height_feet ?? ""}' ${
                      user.personal_details.height_inches ?? ""
                    }"`}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Complexion:
                  </Typography>
                  <Typography style={{ marginRight: "6.4rem" }}>
                    {user.personal_details.complexion}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    weight:
                  </Typography>
                  <Typography style={{ marginRight: "6.4rem" }}>
                    {user.personal_details.weight}
                  </Typography>
                </Grid>
                <Grid item lg={3} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Spectacles:
                  </Typography>
                  <Typography style={{ marginRight: "6.4rem" }}>
                    {user.personal_details.hasSpectacle}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={6} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Address:
                </Typography>
                <Typography>{user.personal_details.address} </Typography>
              </Grid>
              {!isEmpty(user.personal_details.description) && (
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Other Details:
                  </Typography>
                  <Typography>{user.personal_details.description} </Typography>
                </Grid>
              )}
              {!isEmpty(user.personal_details.health_description) && (
                <Grid item lg={12} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Health Issues:
                  </Typography>
                  <Typography>
                    {user.personal_details.health_description}{" "}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      </>
    );
  } else return <></>;
};

export default ProfilePersonalAccordian;
