import React from "react";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Notification from "../../components/user/Notification";
import useUsersList, { IPerson } from "../../hooks/UsersList";
import LoadingSign from "../../components/utils/LoadingSign";
import { isAuth, sendLike, setLocalStorage } from "../../utils/helper";
import { deleteAction, getAction } from "../../services/generalServices";
import { INotiData } from "../../interfaces/Interfaces.model";
import Layout from "../layout/Layout";
import WithAuth from "../../hooks/hoc/WithAuth";
import { useNavigate } from "react-router";

const Notifications = () => {
  const { allUsers } = useUsersList(true);
  const [notifications, setNotifications] = useState<INotiData[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getNotification = async () => {
      if (isAuth().notificationId) {
        const res = await getAction(`/notification/${isAuth().notificationId}`);
        if (res && res.status === 200 && res.data.data[0].data) {
          setNotifications(res.data.data[0].data);
          const notificationData = await getAction(
            `/notification/readStatus/${isAuth().notificationId}`,
          );
          setLocalStorage("notification", notificationData.data.data);
        }
      }
    };
    if (isAuth().notificationId) {
      getNotification();
    }
  }, []);

  const acceptHandler = async (e: any, user: IPerson) => {
    e.preventDefault();
    sendLike(user.notificationId, user._id);
  };

  const deleteHandler = async (e: any, notification_id: string) => {
    e.stopPropagation();
    let isConf = window.confirm("Are you sure you want to delete ?");
    if (isConf) {
      const notificationData = await deleteAction(
        `/notification/${
          isAuth().notificationId
        }?notification_id=${notification_id}`,
      );
      if (notificationData && notificationData.status === 200) {
        setLocalStorage("notification", notificationData.data.data);
        setNotifications(notificationData.data.data.data);
      }
    }
  };

  const viewHandler = (e: any, url: string) => {
    e.preventDefault();
    navigate(url);
  };

  let render = <LoadingSign />;
  console.log(notifications, isEmpty(notifications));
  if (notifications && isEmpty(notifications)) {
    render = <h2 style={{ textAlign: "center" }}>No notifications yet!</h2>;
  } else if (notifications) {
    render = (
      <div className="mb-5 mb-lg-0">
        {notifications &&
          !isEmpty(allUsers) &&
          notifications.map((notification: INotiData, key: number) => {
            let fromUser: IPerson = allUsers[notification.from_user];
            console.log(
              "inside notification",
              notification,
              allUsers,
              fromUser,
            );
            return (
              <Notification
                notification={notification}
                fromUser={fromUser}
                deleteHandler={deleteHandler}
                viewHandler={viewHandler}
                acceptHandler={acceptHandler}
              />
            );
          })}
      </div>
    );
  }
  return (
    <Layout rightSidebar={true}>
      <div className="col-lg-9">
        <div
          className="my-4 home-forum col-lg-12"
          style={{
            lineHeight: "1rem",
            backgroundColor: "rgb(212, 218, 223)",
            margin: "auto",
          }}
        >
          <h4 className="text-center forum-h4">
            <strong>Notifications</strong>
          </h4>
        </div>
        {render}
      </div>
    </Layout>
  );
};

export default WithAuth("")(Notifications);
