import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { CustomInput } from "./CustomInput";
import { Typography } from "@mui/material";

const filter = createFilterOptions<string>();

export default function FreeSoloCreateOption({
  options,
  value,
  title,
  onChange,
}: {
  options: string[];
  value: string;
  title: string;
  onChange: (e: any, value: string | null) => void;
}) {
  //   const [value, setValue] = React.useState<string | null>(null);

  return (
    <div>
      <Typography style={{ paddingLeft: "9px", color: "rgba(0, 0, 0, 0.6)" }}>
        {title}
      </Typography>
      <Autocomplete
        value={value}
        onChange={onChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== "" && !isExisting) {
            filtered.push(inputValue);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option;
        }}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        sx={{ width: "113%" }}
        freeSolo
        renderInput={(params) => <CustomInput {...params} />}
      />
    </div>
  );
}
