import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../utils/helper";
import Footer from "./layout/Footer";
import HomeHeader from "../components/Home/HomeHeader";
import SupportForm from "../components/Home/SupportForm";
import HomeFeatures from "../components/Home/HomeFeatures";

const Landing = () => {
  const navigate = useNavigate();
  const user = isAuth();
  return (
    <Fragment>
      <HomeHeader user={user} navigate={navigate} />
      <main>
        <HomeFeatures />
        {/* <SupportForm /> */}
      </main>
      <Footer />
    </Fragment>
  );
};
export default Landing;
