import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router";
import { auth } from "./services/actions";
import Routes from "./useRoutes";
import LoadingSign from "./components/utils/LoadingSign";
import { isAuth, setLocalStorage } from "./utils/helper";
import { getNotification } from "./services/actions/notification";

const App = (props: { notification?: any; error?: any }) => {
  const { error } = props;
  const [isFirstUpdate, setFirstUpdate] = useState(true);

  useEffect(() => {
    if (isAuth()) {
      auth.authCheckState(
        () => {
          setFirstUpdate(false);
          getNotification((res: any) =>
            setLocalStorage("notification", res.data.data[0]),
          );
        },
        () => {
          setFirstUpdate(false);
        },
      );
    } else {
      setFirstUpdate(false);
    }
  }, []);

  //   useEffect(() => {
  //     if (isFirstUpdate) {
  //       setFirstUpdate(false);
  //       return;
  //     }
  //     if (isAuth()) notification.getNotification();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [isFirstUpdate]);

  if (error) console.log("app error", error);

  const user = isAuth();
  const routing = useRoutes(Routes(!!user, user.isRegistered));

  let render = <LoadingSign />;

  if (!isFirstUpdate) {
    render = <>{routing}</>;
  }

  return render;
};

export default App;
