import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLocalNotification, isAuth } from "../../utils/helper";
import { INotiData } from "../../interfaces/Interfaces.model";
import { checkUser } from "../../utils/utility";
import { logout } from "../../services/actions/auth";
const Header = (props: { slideDrawer?: boolean }) => {
  const fontTwenty = { fontSize: "18px" };
  const [unreadCount, setunreadCount] = useState(0);

  useEffect(() => {
    if (isAuth() && getLocalNotification()) {
      let Unreadcount = 0;
      let notificationData = getLocalNotification().data;
      if (notificationData)
        notificationData.forEach((check: INotiData) =>
          check.readStatus ? Unreadcount : Unreadcount++,
        );

      setunreadCount(Unreadcount);
    }
  }, []);

  const isActive = (path: string) => {
    if (window && window.location.href.includes(path) && !props.slideDrawer) {
      return { fontSize: "18px", color: "#0186FE" };
    } else {
      return { fontSize: "18px" };
    }
  };
  const user = isAuth();
  return (
    <Fragment>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{
          boxShadow: "0px 10px 10px -15px",
        }}
      >
        <Link to="/" className="navbar-brand d-block mx-auto mx-lg-0">
          <img
            className="img-brand img-center"
            alt="brand"
            src={"/brahmanMandal.svg"}
          />
          <small style={{ fontSize: "25px" }}>Vadhuvar Mahiti Kendra </small>
        </Link>
        <button
          className="navbar-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mr-lg-5 text-center">
            {!user && (
              <Fragment>
                <li className="nav-item my-1 my-lg-0 d-lg-none">
                  <Link className="nav-link" to="/" style={fontTwenty}>
                    <img
                      className="img-brand"
                      alt="brand"
                      src={"/brahmanMandal.svg"}
                      style={{ fontSize: "5px" }}
                    />{" "}
                    Vadhuvar Mahiti Kendra
                  </Link>
                </li>
                <li className="nav-item my-1 my-lg-0">
                  <Link to="/" className="nav-link" style={fontTwenty}>
                    Home
                  </Link>
                </li>
                <li className="nav-item my-1 my-lg-0">
                  <Link to="/signup" className="nav-link" style={fontTwenty}>
                    Signup
                  </Link>
                </li>
              </Fragment>
            )}
            {user && user.role === "user" ? (
              <Fragment>
                <li
                  className="nav-item my-1 my-lg-0"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    className="nav-link"
                    to="/profile"
                    style={isActive("/profile")}
                  >
                    <i className="fas fa-user-circle fa-1x d-none d-lg-block"></i>
                    Profile
                  </Link>
                </li>
                <li
                  className="nav-item my-1 my-lg-0"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    className="nav-link mt-0"
                    to="/notifications"
                    style={isActive("/notification")}
                  >
                    <i
                      className="fas fa-bell d-none d-lg-block"
                      style={{ position: "relative" }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: -13,
                          right: 27,
                          width: "17px",
                          borderRadius: "100%",
                          backgroundColor: "#0186FE",
                          color: "white",
                        }}
                      >
                        {unreadCount}
                      </span>
                    </i>
                    Notifications
                  </Link>
                </li>
                <li
                  className="nav-item my-1 my-lg-0"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    className="nav-link mt-0"
                    to={`/${checkUser(user)}s`}
                    style={isActive(`/${checkUser(user)}s`)}
                  >
                    <i className="fas fa-question-circle fa-1x d-none d-lg-block"></i>
                    Find Match
                  </Link>
                </li>
                <li
                  className="nav-item my-1 my-lg-0"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    className="nav-link mt-0"
                    to={"/"}
                    onClick={() => {
                      logout();
                    }}
                    style={isActive("/sfdsdfgsdfs")}
                  >
                    <i className="fas fa-sign-out-alt fa-1x d-none d-lg-block"></i>
                    Sign Out
                  </Link>
                </li>
              </Fragment>
            ) : (
              user &&
              user.role === "admin" && (
                <Fragment>
                  <li
                    className="nav-item my-1 my-lg-0"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Link
                      className="nav-link"
                      to="/admin"
                      style={isActive("/admin")}
                    >
                      <i className="fas fa-user-circle fa-1x d-none d-lg-block"></i>
                      Admin Panel
                    </Link>
                  </li>
                  <li
                    className="nav-item my-1 my-lg-0"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Link
                      className="nav-link mt-0"
                      to={"/"}
                      onClick={() => {
                        logout();
                      }}
                      style={isActive("/sfdsdfgsdfs")}
                    >
                      <i className="fas fa-sign-out-alt fa-1x d-none d-lg-block"></i>
                      Sign Out
                    </Link>
                  </li>
                </Fragment>
              )
            )}
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
