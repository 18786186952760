import React from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import {
  IExpectationDetailsReturn,
  UpdateExpectationDetailsState,
  useExpectationDetails,
} from "../../hooks/registration/ExpectationDetailshook";
import {
  IExpManglik,
  IExpCaste,
  IExpComplexion,
  IExpectedJobType,
  IExpectedResidentialStatus,
  IExpSubCaste,
  IBooleanState,
} from "../../interfaces/model_helper";
import { CustomDropdown } from "../Shared/CustomDropdown";
import { isEmpty } from "lodash";

const RegisterPreferenceDetails = ({
  prevStep,
  nextStep,
}: {
  prevStep: () => void;
  nextStep: () => void;
}) => {
  const {
    ExpectationDetails,
    handleExpectationDetailsUpdate,
    handleCreate,
  }: IExpectationDetailsReturn = useExpectationDetails();
  const cancel = () => {
    prevStep();
  };
  const save = () => {
    handleCreate();
    nextStep();
  };
  return (
    <>
      <Grid item lg={12} style={{ height: "15px" }}></Grid>
      <Grid item lg={12}>
        <div style={{ margin: "1rem 3rem " }}>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Caste"}
                entities={Object.values(IExpCaste)}
                value={ExpectationDetails.exp_caste}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpCaste,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            {ExpectationDetails.exp_caste === IExpCaste.BRAHMIN && (
              <Grid item lg={3.5}>
                <CustomDropdown
                  title={"Sub Caste"}
                  entities={Object.values(IExpSubCaste)}
                  value={ExpectationDetails.exp_sub_caste}
                  onChange={(e: any) =>
                    handleExpectationDetailsUpdate({
                      type: UpdateExpectationDetailsState.UpdateExpSubCaste,
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            )}
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Complexion"}
                entities={Object.values(IExpComplexion)}
                value={ExpectationDetails.exp_complexion}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpComplexion,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Residential Status"}
                entities={Object.values(IExpectedResidentialStatus)}
                value={ExpectationDetails.exp_residential_status}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpResidentialStatus,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Occupation Type"}
                entities={Object.values(IExpectedJobType)}
                value={ExpectationDetails.exp_occupation_type}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpOccupationType,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Manglik Status"}
                entities={Object.values(IExpManglik)}
                value={ExpectationDetails.exp_isManglik}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpManglik,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Kundali To See"}
                entities={Object.keys(IBooleanState)}
                value={ExpectationDetails.exp_kundali}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.udpateKundaliToSee,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div
            className="dp--flex dp--jcsb"
            style={{ marginTop: "16px", marginBottom: "48px" }}
          >
            <Grid item lg={10}>
              <CustomInput
                label="Other expectations"
                style={{ width: "100%" }}
                multiline
                rows={4}
                name={UpdateExpectationDetailsState.UpdateExpDetails}
                value={ExpectationDetails.exp_details}
                onChange={(e: any) =>
                  handleExpectationDetailsUpdate({
                    type: UpdateExpectationDetailsState.UpdateExpDetails,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
        </div>
        <Grid item lg={12}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" onClick={cancel}>
              Go Back
            </button>
            <button
              className="btn btn--blue"
              onClick={save}
              disabled={isEmpty(ExpectationDetails.exp_details)}
            >
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterPreferenceDetails;
