import React from "react";

const HomeFeatures = () => {
  return (
    <section className="section-features" id="features">
      <div className="rownat">
        <h1>Guidelines For New Users</h1>
        <br />
        <br />
        <div className="col-12 dp--flex dp--jcc mb-5">
          <ul>
            <li className="guilines">
              After your log on for the first time Select the option “Create”.
              By clicking the same Profile Page will open. Then you have to
              create your own account with your e-mail Id ({" "}
              <span style={{ color: "red" }}> Non Editable </span>) as your
              login ID and choose the password of any combination of your
              choice.
            </li>
            <li className="guilines">
              <span style={{ color: "red" }}>
                Pls Note that No Father / Mother / Guardian’s name should be
                entered in this Profile Page. Only name of Bridal & Bridegroom
                to be entered in Profile Page.
              </span>
            </li>
            <li className="guilines">
              Click the “Sign Up” button by accepting the Terms & Conditions.
              Then you will be redirected to Payment window where you have to
              pay the Annual fees of Rs. 800/- by Credit Card / UPI /or Net
              Banking mode.
            </li>
            <li className="guilines">
              On receipt of Payment the Menu Page will be opened & your Name,
              E-mail Id, Contact no, date of Registration & Validity Date will
              be displayed along with All menu options i.e.
              <span style={{ color: "#0070C0" }}>
                {" "}
                Personal Information – Educational Information – Occupational
                Information – Family Information – Preference Information –
                Photo Gallery – Kundali Information.
              </span>
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}> Personal Information – </span>
              In this menu you have to upload your personal details along with
              the{" "}
              <span style={{ color: "red" }}>
                Passport Size Photo ( Mandatory )
              </span>{" "}
              up to 5 MB. Then save & go to next menu.
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}>
                Educational Information –
              </span>
              Degree, Post Graduation or any professional degree etc along with
              the Name of Institute & year of passing details to be filled.
            </li>

            <li className="guilines">
              <span style={{ color: "#0070C0" }}> Family Details – </span>
              Details of Father & mother, Brothers & sisters, Working or non
              working, living status etc. to be filled.
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}>
                Occupational Information –
              </span>
              Type of occupation ( Service, Self Employment, Business ) Post
              holding, Job Profile, Location & monetary details to be filled.
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}>
                {" "}
                Preference Information –{" "}
              </span>
              Your expectations from your future partner regarding, the job,
              family background, education, hobbies, residential status etc. to
              be mentioned.
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}> Photo Gallary – </span>
              This is a new feature added in this site in which you can upload 5
              more photos of your choice other than Passport size Photo.
            </li>
            <li className="guilines">
              <span style={{ color: "#0070C0" }}>Kundali Information –</span>
              We have already uploaded some common items in this such as Ras,
              Gan, Nadi, Charan etc. You have to select & if possible try to
              upload the Photo of your Kudali & Save & submit the Button.
            </li>
            <li className="guilines">
              Once you submit your data, you can view the Profile of other
              candidates in
              <span style={{ color: "#0070C0" }}>
                “ Find Match ” option.
              </span>{" "}
              In this option you can sort the list of candidates with
              <span style={{ color: "#0070C0" }}>
                Cast, Age Group, Income Group, Marital Status & Education.
              </span>{" "}
              Thus you can view the Profile of selected candidate as you think
              suitable.
            </li>
            <li className="guilines">
              Edit Option – You can add / edit all the information except your
              Name, Birth Date, Gender, Mobile No & e-mail ID ) at any time
              through Edit option given on Profile page. You can also upload &
              Delete the Photos at any time you wish to change.
            </li>
            <li className="guilines">
              For more details contact
              <span style={{ color: "#0070C0" }}>
                {" "}
                vvmk.contact@gmail.com
              </span>{" "}
              & Mob : 9757365403
            </li>
          </ul>
        </div>
        <div className="col-12 ml-2">
          {" "}
          <div style={{ fontWeight: "600" }}>
            <h1> Terms and Conditions</h1>
            <ul>
              <li className="guilines">
                1) Vadhuvar Mahiti Kendra,Thane is not responsible for any
                discrepancy and verification of data.
              </li>
              <li className="guilines">
                2) Vadhuvar Mahiti Kendra, Thane will not provide any additional
                information apart from the information provided by the member.
              </li>
              <li className="guilines">
                3) Vadhuvar Mahiti Kendra, Thane is not responsible for pros and
                cons of uploading the information and photos on website.
              </li>
              <li className="guilines">
                4) Vadhuvar Mahiti Kendra, Thane not facilitate match making. It
                is members responsibility to deactivate account once the
                marriage is fixed, however fees will not be refunded.
              </li>
              <li className="guilines">
                5) In case our website is down due to technical issues we will
                try to resolve as soon as possible.
              </li>
              <li className="guilines">
                6) Registration is valid for one year from the date of
                registration.
              </li>
              <li className="guilines">
                7) Registration fees once paid will not refunded in any case.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFeatures;
