import React from "react";
import Layout from "../layout/Layout";

const TermsAndCondition = () => {
  return (
    <Layout>
      <div style={{ height: "70vh", margin: "4rem" }}>
        <h1> Terms and Conditions</h1>
        <div className="ml-4" style={{ fontWeight: "600" }}>
          1) Vadhuvar Mahiti Kendra,Thane is not responsible for any discrepancy
          and verification of data.
          <br /> 2) Vadhuvar Mahiti Kendra, Thane will not provide any
          additional information apart from the information provided by the
          member.
          <br /> 3) Vadhuvar Mahiti Kendra, Thane is not responsible for pros
          and cons of uploading the information and photos on website.
          <br /> 4) Vadhuvar Mahiti Kendra, Thane not facilitate match making.
          It is members responsibility to deactivate account once the marriage
          is fixed, however fees will not be refunded.
          <br /> 5) In case our website is down due to technical issues we will
          try to resolve as soon as possible.
          <br /> 6) Registration is valid for one year from the date of
          registration.
          <br /> 7) Registration fees once paid will not refunded in any case.
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndCondition;
