import React, { useState } from "react";
import { authenticate, isAuth } from "../../utils/helper";
import { toast } from "react-toastify";
import { SigninForm } from "../../components/authentication/signin";
import { postAction } from "../../services/generalServices";
import { useNavigate } from "react-router";
import WithNoAuth from "../../hooks/hoc/WithNoAuth";

const Signin = () => {
  const navigate = useNavigate();

  const inititialState = {
    email: "",
    password: "",
    isLoading: false,
  };
  const [values, setValues] = useState(inititialState);
  const { email, password, isLoading } = values;

  const [checked, setChecked] = React.useState(false);
  const informParent = (response: any) => {
    if (response.data && response.data.error) {
      toast.error(response.data.error);
      setValues(inititialState);
    } else {
      authenticate(response, () => {
        setValues({
          ...values,
          email: "",
          password: "",
        });
        toast.success(`Hey ${response.data.user.first_name}, welcome back!`);
        isAuth() && isAuth().role === "admin"
          ? navigate("/admin")
          : navigate("/profile");
      });
    }
  };

  const handleChange = (e: any, name: string) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const clickSubmit = async (event: any) => {
    event.preventDefault();
    setValues({
      ...values,
      isLoading: true,
    });
    const data = {
      emailId: email,
      password,
    };
    try {
      await postAction("/auth/signin", data, informParent);
      setValues({
        ...values,
        isLoading: false,
      });
    } catch (error) {
      setValues({
        ...values,
        isLoading: false,
      });
    }
  };

  return (
    <SigninForm
      clickSubmit={clickSubmit}
      handleChange={handleChange}
      email={email}
      isLoading={isLoading}
      password={password}
      checked={checked}
      setChecked={setChecked}
    />
  );
};

export default WithNoAuth()(Signin);
