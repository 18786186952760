import React, { Fragment } from "react";
import default_display from "../../assets/images/default.png";
import ReadMore from "../utils/ReadMore";
import Footer from "../../container/layout/Footer";
import { Link } from "react-router-dom";
import { isAuth, sendLike } from "../../utils/helper";
import { IUserData } from "../../interfaces/Interfaces.model";
import ProfilePersonalAccordian from "../profile/ProfilePersonalAccordian";
import ProfilePreferenceAccordian from "../profile/ProfilePreferenceAccordian";
import ProfileOccupationAccordian from "../profile/ProfileOccupationAccordian";
import ProfileEducationAccordian from "../profile/ProfileEducationAccordian";
import ProfileKundaliAccordian from "../profile/ProfileKundaliAccordian";
import ProfileFamilyAccordian from "../profile/ProfileFamilyAccordian";
import { Tooltip } from "@mui/material";
import moment from "moment";
import ProfilePhotoAccordian from "../profile/ProfilePhotoAccordian";
import { handleAdminDpChange } from "../../utils/utility";

export const greyAreaStyles = {
  height: "30px",
  backgroundColor: "#f5f4f4",
};
const UserProfile = ({ data, navigate }: { data: any; navigate: any }) => {
  const h4Styles = { fontSize: "1.5rem", marginRight: "0.5rem" };

  const User: IUserData = data[0] ? data[0] : data;
  if (User.personal_details) {
    return (
      <Fragment>
        <div className="mt-4 col-lg-12 profile">
          <div
            className="dp--flex dp--aic dp--jcc profile-img"
            style={{ height: "30vh" }}
          >
            {User.personal_details.profile_photo !== "default.png" ? (
              <img
                src={User.personal_details.profile_photo}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(User.personal_details.profile_photo, "_blank");
                }}
                alt="profile"
                className="pfp"
              />
            ) : (
              <img src={default_display} alt="profile" className="pfp" />
            )}

            {isAuth().role === "admin" && (
              <div className="">
                <input
                  type="file"
                  className="upload-image-input input-file"
                  accept="image/*"
                  id="upload-image-admin"
                  onChange={(e) => handleAdminDpChange(e, User._id)}
                  onLoad={(e: any) => console.log(e)}
                />
                <Tooltip title="Upload profile image">
                  <label htmlFor="upload-image-admin">
                    <div
                      className="btn btn-primary dp--flex dp--jcc dp--aic "
                      style={{
                        color: "#FFF",
                        marginLeft: "-.81rem",
                      }}
                    >
                      <i
                        className="fa fa-user"
                        style={{
                          fontSize: "1rem",
                        }}
                      ></i>
                    </div>
                  </label>
                </Tooltip>
              </div>
            )}
          </div>
          <div style={{ marginTop: "16px" }} className="row bg-white mainUser">
            <div className="col-lg-4 mt-2 ">
              {(isAuth() && isAuth()._id === User._id) ||
              isAuth().role === "admin" ? (
                <span className="d-inline d-lg-none float-right">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      onClick={() => {
                        navigate("/editprofile");
                      }}
                    >
                      <i className="fas fa-plus-circle">{}</i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="dropdown-item" to={`/editprofile`}>
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </span>
              ) : (
                <Tooltip title="Like profile">
                  <span className="d-inline d-lg-none float-right">
                    <i
                      style={{
                        margin: "24px",
                        color: "red",
                        fontSize: "1.5rem",
                      }}
                      className="fas fa-heart"
                    ></i>
                  </span>
                </Tooltip>
              )}
              <h4
                className="text-primary font-weight-bold mt-3"
                style={h4Styles}
              >
                {User.first_name} {User.last_name}
              </h4>
              <span>
                <ReadMore
                  className="read-more-content"
                  limit={200}
                  value={User.personal_details.description}
                />
              </span>
            </div>
            {isAuth() && (
              <div className="col-lg-4 mt-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>Date of Registration</p>
                  <p>{User.transaction_date}</p>
                  <p>Date of Expiry</p>
                  <p>
                    {User.dateOfExpiry
                      ? User.dateOfExpiry
                      : moment(User.transaction_date)
                          .add(1, "year")
                          .format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            )}
            <div className="col-lg-4 mt-4 text-lg-center pb-3">
              <span className="d-none d-lg-block">
                {isAuth()._id === User._id ? (
                  <div className="dropdown">
                    <Tooltip title="Edit Profile">
                      <button
                        className="btn btn-primary btn-sm float-lg-right"
                        type="button"
                        onClick={() => {
                          navigate(`/editprofile`);
                        }}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </button>
                    </Tooltip>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="dropdown-item" to={`/editprofile`}>
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                ) : (
                  <span className="d-none d-lg-block">
                    <div className="dropdown">
                      <Tooltip title="Liike profile">
                        <i
                          style={{
                            color: "red",
                            marginRight: "1.5rem",
                            fontSize: "1.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            sendLike(User.notificationId, User._id)
                          }
                          className="fas fa-heart float-lg-right"
                        ></i>
                      </Tooltip>
                    </div>
                  </span>
                )}
              </span>
              <h4 className="text-primary font-weight-bold">
                Contact Information
              </h4>
              <p className="profile__p">
                <strong>Email: </strong> {User.emailId}
              </p>
              <p className="profile__p">
                <strong>Phone no: </strong>{" "}
                {User.personal_details.mobile_number}
              </p>
            </div>

            <div className="col-12" style={greyAreaStyles}></div>
            <ProfilePersonalAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <ProfileEducationAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <ProfileOccupationAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <ProfileFamilyAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <ProfilePreferenceAccordian user={User} />
            <ProfilePhotoAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <ProfileKundaliAccordian user={User} />
            <div className="col-12" style={greyAreaStyles}></div>
            <div className="col-12 d-none d-lg-block">
              <Footer />
            </div>
          </div>
          <div className="col-12 d-lg-none">
            <div className="col-12" style={greyAreaStyles}></div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};
export default UserProfile;
