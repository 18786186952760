import React, { Fragment } from "react";
import Card from "../utils/Card";
import { Link } from "react-router-dom";
import { IFindUser } from "../../interfaces/Interfaces.model";

const FindMales = ({ searchValues, linkStyles, navigate }: IFindUser) => {
  return searchValues.length ? (
    <>
      {searchValues &&
        searchValues.map((male, index) => (
          <Fragment key={index}>
            <Link
              className="card-title"
              to={`/male/${male._id}`}
              style={linkStyles}
            ></Link>
            <Card data={male} />
          </Fragment>
        ))}
    </>
  ) : (
    <h5
      className="my-3"
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: "800",
        color: "#0084FF",
        fontSize: "30px",
      }}
    >
      No Male Found
    </h5>
  );
};

export default FindMales;
