import Chip from "@mui/material/Chip";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Option } from "react-bootstrap-typeahead/types/types";
import {
  IDegreeType,
  IFilterAge,
  IFilterSubCaste,
  IMaritialStatus,
  ISalaryRange,
} from "../../interfaces/model_helper";

export const SearchBar = (props: {
  searchName: (arg0: any) => void;
  list: any[];
  nameList: Option[];
  handleFilterChange: (field: string, value: string) => void;
  role: any;
  handleDelete: (data: any) => void;
  filterOpts: string[];
  selectedFilter: string;
  setSelectedFilter: any;
}) => {
  const [name, setName] = useState("");
  const [searchName, setSearchName] = useState<any>([]);

  const handleChange = (selectedOptions: any) => {
    setSearchName(selectedOptions);
    if (isEmpty(selectedOptions)) handleInputChange(name);
    else {
      setName(selectedOptions[0]);
      checkname(selectedOptions[0]);
    }
  };

  const handleInputChange = (input: string) => {
    setName(input);
    checkname(input);
  };

  const checkname = (name1: string) => {
    if (name1 === "") {
      props.searchName(props.list);
    } else {
      let newValues = props.list.filter((info) =>
        `${info.first_name} ${info.last_name}`
          .toLowerCase()
          .includes(name1.toLowerCase()),
      );
      props.searchName(newValues);
    }
  };

  let renderDropdown;
  switch (props.selectedFilter) {
    case "caste":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) =>
            props.handleFilterChange("caste", e.target.value)
          }
        >
          <option value="">Select</option>
          <option value="Brahmin">Brahmin</option>
          <option value="Others">Others</option>
        </select>
      );
      break;
    case "subCaste":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) =>
            props.handleFilterChange("subCaste", e.target.value)
          }
        >
          <option value="">Select</option>
          {Object.values(IFilterSubCaste).map((entity: any, idx: number) => (
            <option value={entity} key={idx}>
              {entity}
            </option>
          ))}
        </select>
      );
      break;
    case "age":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) => props.handleFilterChange("age", e.target.value)}
        >
          <option value="">Select</option>
          {Object.values(IFilterAge).map((entity: any, idx: number) => (
            <option value={entity} key={idx}>
              {entity}
            </option>
          ))}
        </select>
      );
      break;
    case "annualIncome":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) =>
            props.handleFilterChange("annualIncome", e.target.value)
          }
        >
          <option value="">Select</option>
          {Object.values(ISalaryRange).map((entity: any, idx: number) => (
            <option value={entity} key={idx}>
              {entity}
            </option>
          ))}
        </select>
      );
      break;
    case "maritalStatus":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) =>
            props.handleFilterChange("maritalStatus", e.target.value)
          }
        >
          {Object.values(IMaritialStatus).map((entity: any, idx: number) => (
            <option value={entity} key={idx}>
              {entity}
            </option>
          ))}
        </select>
      );
      break;
    case "education":
      renderDropdown = (
        <select
          style={{
            width: "50%",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          onChange={(e: any) =>
            props.handleFilterChange("education", e.target.value)
          }
        >
          {Object.values(IDegreeType).map((entity: any, idx: number) => (
            <option value={entity} key={idx}>
              {entity}
            </option>
          ))}
        </select>
      );
      break;
    default:
      renderDropdown = <></>;
      break;
  }

  return (
    <div className="jumbotron searchBar" style={{ backgroundColor: "#D4DADF" }}>
      <div className="input-group mb-3">
        <Typeahead
          id="typeaheadBar"
          options={props.nameList}
          minLength={1}
          placeholder={`Search A ${props.role}`}
          onChange={handleChange}
          selected={searchName}
          onInputChange={handleInputChange}
        >
          {({ onClear, selected }) => (
            <div className="rbt-aux">
              {!!selected.length && <ClearButton onClick={onClear} />}
            </div>
          )}
        </Typeahead>
        <div className="input-group-prepend ml-1">
          <span className="input-group-text border-0 bg-transparent">
            <i
              className="fa fa-search search-hover"
              onClick={() => {
                checkname(name);
                setSearchName([]);
              }}
            ></i>
          </span>
        </div>
        <div
          className="dp--flex dp--jcsb"
          style={
            props.selectedFilter !== "all"
              ? {
                  width: "40%",
                  height: "3rem",
                }
              : {
                  width: "20%",
                  height: "3rem",
                }
          }
        >
          <select
            style={
              props.selectedFilter !== "all"
                ? {
                    width: "50%",
                    borderTopLeftRadius: "15px",
                    borderBottomLeftRadius: "15px",
                  }
                : { width: "100%", borderRadius: "15px" }
            }
            onChange={(e) => {
              if (e.target.value === "all") {
                props.handleFilterChange("all", "all");
              }
              props.setSelectedFilter(e.target.value);
            }}
            value={props.selectedFilter}
          >
            <option value="all">All</option>
            <option value="caste">Caste</option>
            <option value="subCaste">Sub Caste</option>
            <option value="age">Age</option>
            <option value="annualIncome">Annual Income</option>
            <option value="maritalStatus">Marital Status</option>
            <option value="education">Education</option>
          </select>
          {renderDropdown}
        </div>
        <div className="input-group mt-3">
          {props.filterOpts.map((data, index) => {
            return (
              <li
                key={index}
                style={{
                  listStyleType: "none",
                }}
              >
                <Chip
                  label={data.split("=")[1]}
                  onDelete={props.handleDelete(data) as any}
                />
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};
