import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import {
  IFamilyDetails,
  IFamilyMaritialStatus,
} from "../../interfaces/model_helper";
import { isAuth, setLocalStorage } from "../../utils/helper";
import { patchUserDetails } from "../../services/userService";
import moment from "moment";
import { toast } from "react-toastify";
/*****
 Show Add Personal Details hook
 *****/
export interface IFamilyDetailsStateErrors {
  father_first_name: boolean;
  father_middle_name: boolean;
  father_last_name: boolean;
  father_mobile_number: boolean;
  father_occupation: boolean;
  father_dob: boolean;
  father_education: boolean;
  father_isAlive: Boolean;
  mother_first_name: boolean;
  mother_middle_name: boolean;
  mother_last_name: boolean;
  mother_mobile_number: boolean;
  mother_isAlive: Boolean;
  mother_occupation: boolean;
  mother_dob: boolean;
  mother_education: boolean;
  living_status: boolean;
  address: boolean;
  family_background: boolean;
}

const initialFamilyDetailsErrorsObj: IFamilyDetailsStateErrors = {
  father_first_name: false,
  father_last_name: false,
  father_middle_name: false,
  father_mobile_number: false,
  father_occupation: false,
  father_dob: false,
  father_education: false,
  father_isAlive: false,
  mother_mobile_number: false,
  mother_first_name: false,
  mother_last_name: false,
  mother_middle_name: false,
  mother_occupation: false,
  mother_dob: false,
  mother_education: false,
  mother_isAlive: false,
  address: false,
  living_status: false,
  family_background: false,
};

export enum UpdateFamilyDetailsState {
  UpdateFatherFirstName = "father_first_name",
  UpdateFatherMiddleName = "father_middle_name",
  UpdateFatherLastName = "father_last_name",
  UpdateFatherMobileNumber = "father_mobile_number",
  UpdateFatherOccupation = "father_occupation",
  UpdateFatherDob = "father_dob",
  UpdateFatherEducation = "father_education",
  UpdateFatherIsAlive = "father_isAlive",
  UpdateMotherFirstName = "mother_first_name",
  UpdateMotherMiddleName = "mother_middle_name",
  UpdateMotherLastName = "mother_last_name",
  UpdateMotherMobileNumber = "mother_mobile_number",
  UpdateMotherOccupation = "mother_occupation",
  UpdateMotherDob = "mother_dob",
  UpdateMotherEducation = "mother_education",
  UpdateMotherIsAlive = "mother_isAlive",
  UpdateAddress = "address",
  UpdateFamilyBackground = "family_background",
  UpdateLivingStatus = "living_status",
}

// export enum UpdateActions {
//   Reset = "reset",
// }

export interface IStringAction {
  type:
    | UpdateFamilyDetailsState.UpdateFatherFirstName
    | UpdateFamilyDetailsState.UpdateFatherIsAlive
    | UpdateFamilyDetailsState.UpdateMotherIsAlive
    | UpdateFamilyDetailsState.UpdateFatherMiddleName
    | UpdateFamilyDetailsState.UpdateFatherLastName
    | UpdateFamilyDetailsState.UpdateFatherMobileNumber
    | UpdateFamilyDetailsState.UpdateFatherOccupation
    | UpdateFamilyDetailsState.UpdateFatherDob
    | UpdateFamilyDetailsState.UpdateFatherEducation
    | UpdateFamilyDetailsState.UpdateMotherFirstName
    | UpdateFamilyDetailsState.UpdateMotherMiddleName
    | UpdateFamilyDetailsState.UpdateMotherLastName
    | UpdateFamilyDetailsState.UpdateMotherMobileNumber
    | UpdateFamilyDetailsState.UpdateMotherOccupation
    | UpdateFamilyDetailsState.UpdateMotherDob
    | UpdateFamilyDetailsState.UpdateMotherEducation
    | UpdateFamilyDetailsState.UpdateAddress
    | UpdateFamilyDetailsState.UpdateFamilyBackground;
  value: string;
}

export interface IFamilyMaritialStatusAction {
  type: UpdateFamilyDetailsState.UpdateLivingStatus;
  value: IFamilyMaritialStatus;
}

export interface IHandleFamilyDetailsCreation {
  error: boolean;
  msg?: string;
}

export type FamilyDetailsAction = IStringAction | IFamilyMaritialStatusAction;

export type FamilyDetailsErrorsAction =
  | IStringAction
  | IFamilyMaritialStatusAction;

const addFamilyDetailsReducer = (
  state: IFamilyDetails,
  action: FamilyDetailsAction,
): IFamilyDetails => {
  let newFamilyDetails: IFamilyDetails = { ...state };
  switch (action.type) {
    case UpdateFamilyDetailsState.UpdateFatherFirstName:
      newFamilyDetails.father_first_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherMiddleName:
      newFamilyDetails.father_middle_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherLastName:
      newFamilyDetails.father_last_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherMobileNumber:
      newFamilyDetails.father_mobile_number = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherOccupation:
      newFamilyDetails.father_occupation = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherDob:
      newFamilyDetails.father_dob = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherEducation:
      newFamilyDetails.father_education = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateFatherIsAlive:
      newFamilyDetails.father_isAlive = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherFirstName:
      newFamilyDetails.mother_first_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherMiddleName:
      newFamilyDetails.mother_middle_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherLastName:
      newFamilyDetails.mother_last_name = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherMobileNumber:
      newFamilyDetails.mother_mobile_number = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherOccupation:
      newFamilyDetails.mother_occupation = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherDob:
      newFamilyDetails.mother_dob = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherEducation:
      newFamilyDetails.mother_education = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateMotherIsAlive:
      newFamilyDetails.mother_isAlive = action.value;
      break;

    case UpdateFamilyDetailsState.UpdateFamilyBackground:
      newFamilyDetails.family_background = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateAddress:
      newFamilyDetails.address = action.value;
      break;
    case UpdateFamilyDetailsState.UpdateLivingStatus:
      newFamilyDetails.living_status = action.value;
      break;
    // case UpdateActions.Reset:
    //   newFamilyDetails = { ...initialFamilyDetails };
  }
  return newFamilyDetails;
};

export const useFamilyDetails = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [familyDetailsErrors, setFamilyDetailsErrors] =
    useState<IFamilyDetailsStateErrors>(initialFamilyDetailsErrorsObj);
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());

  const initialFamilyDetails: IFamilyDetails = {
    father_first_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_first_name) ??
      "",
    father_middle_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_middle_name) ??
      "",
    father_last_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_last_name) ??
      "",
    father_mobile_number:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_mobile_number) ??
      "",
    father_occupation:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_occupation) ??
      "",
    father_dob:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_dob) ??
      "",
    father_education:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.father_education) ??
      "",
    father_isAlive:
      userLocalStorage.family_details &&
      userLocalStorage.family_details.father_isAlive
        ? "Yes"
        : "No",
    mother_first_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_first_name) ??
      "",
    mother_middle_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_middle_name) ??
      "",
    mother_last_name:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_last_name) ??
      "",
    mother_mobile_number:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_mobile_number) ??
      "",
    mother_occupation:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_occupation) ??
      "",
    mother_dob:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_dob) ??
      "",
    mother_education:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.mother_education) ??
      "",
    mother_isAlive:
      userLocalStorage.family_details &&
      userLocalStorage.family_details.mother_isAlive
        ? "Yes"
        : "No",
    family_background:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.family_background) ??
      "",
    address:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.address) ??
      "",
    living_status:
      (userLocalStorage.family_details &&
        userLocalStorage.family_details.living_status) ??
      IFamilyMaritialStatus.INITIAL_STATE,
  };

  const [familyDetails, dispatchFamilyDetails] = useReducer(
    addFamilyDetailsReducer,
    initialFamilyDetails,
  );
  //   const handleReset = () => {
  // dispatchFamilyDetails({
  //   type: UpdateActions.Reset,
  // });
  //     setIsValid(false);
  //   };

  const handleFamilyDetailsUpdate = (action: FamilyDetailsAction) => {
    dispatchFamilyDetails(action);
  };

  const handleFamilyDetailsErrors = (action: FamilyDetailsErrorsAction) => {
    let newFamilyDetails = { ...familyDetailsErrors };
    if (isEmpty(familyDetails[action.type])) {
      newFamilyDetails[action.type] = true;
    } else if (action.type === UpdateFamilyDetailsState.UpdateMotherDob) {
      if (
        moment()
          .subtract(30, "years")
          .isBefore(moment(familyDetails.mother_dob))
      ) {
        setIsValid(true);
        newFamilyDetails[action.type] = true;
        toast.error("You must be atleast 30 years old");
      } else newFamilyDetails[action.type] = false;
    } else if (action.type === UpdateFamilyDetailsState.UpdateFatherDob) {
      if (
        moment()
          .subtract(30, "years")
          .isBefore(moment(familyDetails.father_dob))
      ) {
        setIsValid(true);
        newFamilyDetails[action.type] = true;
        toast.error("You must be atleast 30 years old");
      } else newFamilyDetails[action.type] = false;
    } else if (
      action.type === UpdateFamilyDetailsState.UpdateMotherMobileNumber
    ) {
      if (familyDetails[action.type].toString().length !== 10) {
        setIsValid(true);
        toast.error("Please enter a valid number");
      } else newFamilyDetails[action.type] = false;
    } else if (
      action.type === UpdateFamilyDetailsState.UpdateFatherMobileNumber
    ) {
      if (familyDetails[action.type].toString().length !== 10) {
        setIsValid(true);
        toast.error("Please enter a valid number");
      } else newFamilyDetails[action.type] = false;
    } else if (!isEmpty(familyDetails[action.type])) {
      newFamilyDetails[action.type] = false;
    }
    setFamilyDetailsErrors(newFamilyDetails);
  };

  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);

  useEffect(() => {
    let errors = false;
    if (isEmpty(familyDetails.father_middle_name)) errors = true;
    if (isEmpty(familyDetails.father_first_name)) errors = true;
    if (
      !isEmpty(familyDetails.mother_dob) &&
      moment().isBefore(moment(familyDetails.mother_dob))
    )
      errors = true;
    if (
      !isEmpty(familyDetails.father_dob) &&
      moment().isBefore(moment(familyDetails.father_dob))
    )
      errors = true;
    if (isEmpty(familyDetails.father_last_name)) errors = true;
    if (isEmpty(familyDetails.family_background)) errors = true;
    setIsValid(errors);
  }, [familyDetails]);

  const handleCreate = async (): Promise<IHandleFamilyDetailsCreation> => {
    try {
      const newfamilyDetails = {
        family_details: familyDetails,
      };
      if (familyDetails.living_status === IFamilyMaritialStatus.INITIAL_STATE) {
        newfamilyDetails.family_details.living_status = "";
      }
      if (familyDetails.father_isAlive === "Select") {
        newfamilyDetails.family_details.father_isAlive = "";
      }
      if (familyDetails.mother_isAlive === "Select") {
        newfamilyDetails.family_details.mother_isAlive = "";
      }

      if (familyDetails.father_isAlive === "Yes") {
        newfamilyDetails.family_details.father_isAlive = true;
      }
      if (familyDetails.mother_isAlive === "Yes") {
        newfamilyDetails.family_details.mother_isAlive = true;
      }
      if (familyDetails.father_isAlive === "No") {
        newfamilyDetails.family_details.father_isAlive = false;
      }
      if (familyDetails.mother_isAlive === "No") {
        newfamilyDetails.family_details.mother_isAlive = false;
      }

      if (
        !isEqual(
          newfamilyDetails.family_details,
          userLocalStorage.family_details,
        )
      ) {
        let user = isAuth();
        if (!user.family_details) {
          user.family_details = {};
        }
        user.family_details = familyDetails;

        const responseStatus = await patchUserDetails(newfamilyDetails);
        if (responseStatus) {
          setLocalStorage("user", user);
        }
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const FamilyDetailsRetun: IFamilyDetailsReturn = {
    familyDetails,
    isValid,
    familyDetailsErrors,
    handleFamilyDetailsUpdate,
    handleFamilyDetailsErrors,
    handleCreate,
  };
  return FamilyDetailsRetun;
};

export interface IFamilyDetailsReturn {
  familyDetails: IFamilyDetails;
  isValid: boolean;
  familyDetailsErrors: IFamilyDetailsStateErrors;
  handleFamilyDetailsErrors: (action: FamilyDetailsErrorsAction) => void;
  handleFamilyDetailsUpdate: (action: FamilyDetailsAction) => void;
  handleCreate: () => Promise<IHandleFamilyDetailsCreation>;
}
