import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getAction, postAction } from "../../services/generalServices";
import { EditUser } from "../../components/Admin/EditUser";

const AdminEditUser = () => {
  const params = useParams();

  const inititialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
    isLoading: false,
  };
  const [values, setValues] = useState(inititialState);
  const {
    firstName,
    middleName,
    lastName,
    gender,
    email,
    mobileNumber,
    isLoading,
  } = values;

  useEffect(() => {
    const { userId } = params;

    const getUserDetails = async () => {
      setValues({
        ...values,
        isLoading: true,
      });
      const userDetails = await getAction(`/users/${userId}`);
      console.log("userDetails", userDetails);
      if (userDetails.status === 200) {
        setValues({
          firstName: userDetails.data.data[0].first_name,
          middleName: userDetails.data.data[0].middle_name,
          lastName: userDetails.data.data[0].last_name,
          gender: userDetails.data.data[0].gender,
          mobileNumber: userDetails.data.data[0].personal_details.mobile_number,
          email: userDetails.data.data[0].emailId,
          isLoading: false,
        });
      } else {
        toast.error("something went wrong");
      }
    };
    getUserDetails();
    return () => {
      setValues(inititialState);
    };
  }, []);
  console.log("values", values);

  const handleChange = (event: any, name: string) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const navigate = useNavigate();

  const clickSubmit = async (event: any) => {
    event.preventDefault();
    setValues({ ...values, isLoading: true });
    const data = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender: gender,
      emailId: email,
      mobile_number: mobileNumber,
    };
    const response = await postAction("admin/save_user", data);
    if (response.data.error) {
      toast.error(response.data.error);
    } else {
      setValues(inititialState);
      toast.success("User created successfully");
      navigate("/admin");
    }
  };

  return (
    <EditUser
      clickSubmit={clickSubmit}
      handleChange={handleChange}
      email={email}
      firstName={firstName}
      middleName={middleName}
      lastName={lastName}
      gender={gender}
      mobileNumber={mobileNumber}
      isLoading={isLoading}
    />
  );
};

export default AdminEditUser;
