import React, { useState } from "react";
import { Grid } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";
import CustomCarousel from "../Shared/CustomCarousal";
import { isEmpty } from "lodash";
import { greyAreaStyles } from "../user/UserProfile";

const ProfilePhotoAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  let render = <div></div>;
  if (!isEmpty(user.personal_details.album)) {
    render = (
      <>
        <div className="col-12" style={greyAreaStyles}></div>
        <StyledAccordion
          expanded={first}
          onChange={(e: any) => {
            setfirst(!first);
          }}
        >
          <StyledAccordionSummary aria-label="Profile">
            <Grid container alignItems="center" justifyContent="space-between">
              <h4 className="text-primary font-weight-bold">Photo Gallery</h4>
            </Grid>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Grid
              item
              lg={12}
              style={{ width: "36%", margin: "auto" }}
              className="dp--flex dp--jcc"
            >
              <CustomCarousel>
                {user.personal_details.album.map(
                  (item: string, index: number) => (
                    <div key={index}>
                      <img src={item} alt="album" />
                    </div>
                  ),
                )}
              </CustomCarousel>
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      </>
    );
  }
  return render;
};

export default ProfilePhotoAccordian;
