import React from "react";
import { deleteAction } from "../../services/generalServices";

export const DeleteConfirmation = ({ deleteId }: { deleteId: string }) => {
  return (
    <div className="modal" id="myModal1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Are you sure?</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <p>
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-danger "
              data-dismiss="modal"
              onClick={(e) =>
                deleteAction(`/users/${deleteId}`, () => {
                  window.location.reload();
                })
              }
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
