import {
  isAuth,
  setLocalStorage,
  removeLocalStorage,
  axiosInstance as axios,
} from "../../utils/helper";
import { getAction } from "../generalServices";

export const logout = (next) => {
  removeLocalStorage("user");
  removeLocalStorage("notification");
  removeLocalStorage("expirationDate");
  getAction("/auth/logout", next);
};

export const auth = (callback, errCallback) => {
  axios
    .get("/me")
    .then((response) => {
      setLocalStorage("user", response.data.user);
      const expirationDate = new Date(
        new Date().getTime() + 60 * 60 * 24 * 10 * 1000,
      );
      localStorage.setItem("expirationDate", expirationDate);
      if (callback) callback();
    })
    .catch((err) => {
      if (errCallback) errCallback();
    });
};

export const authCheckState = (callback, errCallback) => {
  const user = isAuth();
  if (user) {
    const expirationDate = new Date(localStorage.getItem("expirationDate"));
    if (expirationDate <= new Date()) {
      logout();
    } else {
      if (callback) callback();
    }
  } else {
    auth(callback, errCallback);
  }
};
