import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { isAuth } from "../../utils/helper";
import LoadingSign from "../../components/utils/LoadingSign";
import UserProfile from "../../components/user/UserProfile";
import { IUserData } from "../../interfaces/Interfaces.model";
import { useNavigate } from "react-router";
import WithAuth from "../../hooks/hoc/WithAuth";

const Profile = () => {
  const [profile, setprofile] = useState<IUserData | null>(null);

  useEffect(() => {
    const getProfile = async () => {
      setprofile(isAuth());
    };
    getProfile();
  }, []);

  const navigate = useNavigate();

  let render = <LoadingSign />;
  if (profile) {
    render = <UserProfile navigate={navigate} data={profile} />;
  }

  // styling issues
  // return <Layout rightSidebar={true} projects={true}>{render}</Layout>;
  if (window.innerWidth > 992) {
    return <Layout profile={true}>{render}</Layout>;
  } else {
    return <Layout>{render}</Layout>;
  }
};

export default WithAuth("")(Profile);
