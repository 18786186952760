import { toast } from "react-toastify";
import { ISupportFields } from "../hooks/registration/SupportMailHook";
import { IUserData } from "../interfaces/Interfaces.model";
import { postAction } from "../services/generalServices";
import { onAuth } from "./helper";

export const findDiff = (prev: any[], update: any[]) => {
  let [added, removed]: any = [[], []];
  if (prev) {
    added = update
      .filter((item) => !prev.includes(item))
      .map((item) => String(item)); //Sanitize
    removed = prev
      .filter((item) => !update.includes(item))
      .map((item) => String(item)); //Sanitize
  } else added = update.map((item) => String(item)); //Sanitize
  return [added, removed];
};

export const checkUser = (user: IUserData) => {
  if (user.gender === "male") {
    return "female";
  } else {
    return "male";
  }
};

export const checkValidity = (
  value: string,
  rules: {
    required: boolean;
    minLength?: number;
    maxLength?: number;
    isEmail?: boolean;
    isNumeric?: boolean;
  },
) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};
const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

export const handleFileChange = async (e: any) => {
  const fileType = e.target.files[0].type;
  if (allowedTypes.includes(fileType)) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await postAction("/photo/all", formData, (res: any) => {
      console.log(res);
      onAuth(() => {
        window.location.reload();
        toast.success("Profile photo updated successfully");
      });
    });
  } else {
    toast.error("Please select a valid file");
  }
};

export const handleDpChange = async (e: any) => {
  const fileType = e.target.files[0].type;
  if (allowedTypes.includes(fileType)) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await postAction("/photo/", formData, () => {
      onAuth(() => {
        toast.success("Profile photo updated successfully");
        window.location.reload();
      });
    });
  } else {
    toast.error("Please select a valid file");
  }
};

export const handleAdminDpChange = async (e: any, id: string) => {
  const fileType = e.target.files[0].type;
  if (allowedTypes.includes(fileType)) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await postAction(`/photo/${id}`, formData, (res: any) => {
      console.log("res", res);
      toast.success("Profile photo updated successfully");
      window.location.reload();
    });
  } else {
    toast.error("Please select a valid file");
  }
};

export const handleKundaliChange = async (e: any) => {
  const fileType = e.target.files[0].type;
  if (allowedTypes.includes(fileType)) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await postAction("/photo/kundali", formData, () => {
      onAuth(() => {
        toast.success("kundali photo updated successfully");
        window.location.reload();
      });
    });
  } else {
    toast.error("Please select a valid file");
  }
};

export interface ISupportMail {
  status: string;
  message: string;
}

export const sendSupportMail = async (
  mailData: ISupportFields,
): Promise<ISupportMail> => {
  try {
    const data = await postAction("/auth/report", mailData);
    return {
      status: "success",
      message: data.message,
    };
  } catch (error: any) {
    console.log(error);
    return {
      status: "error",
      message: error,
    };
  }
};
