import React from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import {
  IPersonalDetailsReturn,
  UpdatePersonalDetailsState,
  usePersonalDetails,
} from "../../hooks/registration/PersonalDetailshook";
import { IStateData } from "../../container/user/RegisterProfile";
import { IBooleanState } from "../../interfaces/model_helper";
import { CustomDropdown } from "../Shared/CustomDropdown";

const EditPersonalDetailsPhase1 = ({
  state,
  setState,
}: {
  state: IStateData;
  setState: (state: IStateData) => void;
}) => {
  const nextStep = () => {
    const { step } = state;
    setState({ ...state, step: step + 1 });
    handleCreateP1();
  };

  const prevPhase = () => {
    const { phase } = state;
    setState({ ...state, phase: phase - 1 });
    // handleReset();
  };
  const {
    personalDetails,
    isPersonalDetailsValidP1,
    personalDetailsErrors,
    handleProfileDetailsUpdate,
    handlePersonalDetailsErrors,
    handleCreateP1,
  }: IPersonalDetailsReturn = usePersonalDetails();

  return (
    <>
      <Grid item lg={12}>
        <div style={{ margin: "1rem 3rem " }}>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                label="Complexion *"
                value={personalDetails.complexion}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                label="Mangalik *"
                value={personalDetails.isManglik}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item lg={0.5}></Grid>

            <Grid item lg={4}>
              <CustomDropdown
                title={"Spectacles *"}
                entities={Object.keys(IBooleanState)}
                value={personalDetails.hasSpectacle}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateSpectacles,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="NUMBER"
                label="height (feet) *"
                style={{ width: "100%" }}
                type="number"
                name={UpdatePersonalDetailsState.UpdateHeightFeet}
                value={personalDetails.height_feet}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateHeightFeet,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateHeightFeet,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.height_feet}
                helper={
                  personalDetailsErrors.height_feet
                    ? {
                        text: "Please enter your height (feet)",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="NUMBER"
                label="height (Inches) *"
                type="number"
                style={{ width: "100%" }}
                name={UpdatePersonalDetailsState.UpdateHeightInches}
                value={personalDetails.height_inches}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateHeightInches,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateHeightInches,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.height_inches}
                helper={
                  personalDetailsErrors.height_inches
                    ? {
                        text: "Please enter your height (inches)",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>

            <Grid item lg={4}>
              <CustomInput
                allowOnly="NUMBER"
                type="number"
                label="Weight *"
                style={{ width: "100%" }}
                name={UpdatePersonalDetailsState.UpdateWeight}
                value={personalDetails.weight}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateWeight,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateWeight,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.weight}
                helper={
                  personalDetailsErrors.weight
                    ? {
                        text: "Please enter your weight (kg)",
                      }
                    : undefined
                }
                required
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={10}>
              <CustomInput
                label="Address *"
                style={{ width: "100%" }}
                multiline
                rows={3}
                name={UpdatePersonalDetailsState.UpdateAddress}
                value={personalDetails.address}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateAddress,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateAddress,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.address}
                helper={
                  personalDetailsErrors.address
                    ? {
                        text: "Please enter your address",
                      }
                    : undefined
                }
                required
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={10}>
              <CustomInput
                label="Other Details"
                style={{ width: "100%" }}
                multiline
                placeholder="Personal Information / Hobbies / Extra-curriculum activities"
                rows={2}
                name={UpdatePersonalDetailsState.UpdateDescription}
                value={personalDetails.description}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateDescription,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={10}>
              <CustomInput
                label="Health Issues (If any)"
                style={{ width: "100%" }}
                multiline
                rows={2}
                name={UpdatePersonalDetailsState.UpdateHealthDescription}
                value={personalDetails.health_description}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateHealthDescription,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
        </div>
        <Grid item lg={12} style={{ marginTop: "16px" }}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" onClick={prevPhase}>
              Go Back
            </button>
            <button
              className="btn btn--blue"
              disabled={isPersonalDetailsValidP1}
              onClick={nextStep}
            >
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default EditPersonalDetailsPhase1;
