import { Typography } from "@mui/material";
import moment from "moment";
export const UsersListColumns = (
  updateDeleteId: (id: string) => void,
  updateUser: (id: string) => void,
) => [
  {
    field: "Delete",
    headerName: "Delete",
    width: 10,
    headerAlign: "left",
    renderCell: (params: any) => (
      <i
        style={{ color: "red" }}
        className="fas fa-trash"
        data-toggle="modal"
        onClick={() => {
          updateDeleteId(params.id);
        }}
        data-target="#myModal1"
      ></i>
    ),
  },
  {
    field: "update",
    headerName: "Update",
    width: 10,
    headerAlign: "left",
    renderCell: (params: any) => (
      <i
        style={{ color: "blue" }}
        className="fas fa-pencil-alt"
        onClick={() => {
          updateUser(params.id);
        }}
      ></i>
    ),
  },
  {
    field: "first_name",
    headerName: "First Name",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography
        color="blue"
        onClick={() =>
          window.open(`/${params.row.gender}/${params.row.id}`, "_blank")
        }
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "middle_name",
    headerName: "Middle Name",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "last_name",
    headerName: "Last Name",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "emailId",
    headerName: "email Id",
    width: 230,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "isRegistered",
    headerName: "Registered",
    width: 100,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">
        {params.value ? "True" : "False"}
      </Typography>
    ),
  },
  {
    field: "dob",
    headerName: "Date of birth",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">
        {moment(params.value).format("DD/MM/YYYY") === "Invalid date"
          ? params.value
          : moment(params.value).format("DD/MM/YYYY")}
      </Typography>
    ),
  },
  {
    field: "isMarried",
    headerName: "Is Married",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">
        {params.value ? "True" : "False"}
      </Typography>
    ),
  },
  {
    field: "transaction_id",
    headerName: "Transaction Id",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "transaction_date",
    headerName: "Transaction Date",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "dateOfExpiry",
    headerName: "Date Of Expiry",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "mobile_number",
    headerName: "Mobile Number",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "marital_status",
    headerName: "Marital Status",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "caste",
    headerName: "Caste",
    width: 100,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "sub_caste",
    headerName: "Sub Caste",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "other_caste",
    headerName: "Other Caste",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "place_of_birth",
    headerName: "Place of Birth",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
  {
    field: "residential_status",
    headerName: "Resident",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },

  {
    field: "address",
    headerName: "Address",
    width: 130,
    headerAlign: "left",
    renderCell: (params: any) => (
      <Typography color="text.secondary">{params.value}</Typography>
    ),
  },
];
