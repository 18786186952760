import styled from "@emotion/styled";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

export const CustomFormControl = styled(FormControl)({
  "&.MuiInputBase-root": {
    // borderRadius: "12px",
    boxShadow: "none",
    height: "40px",
  },
  ".MuiOutlinedInput-notchedOutline": {
    height: "40px",
    boxSizing: "border-box",
    border: "2px solid #FBCDCD",
    borderRadius: "15px",
    top: "0px",
    legend: {
      display: "none",
    },
  },
  "&.MuiPaper-root": {
    boxShadow: "none",
  },
});
export const CustomSelect = styled(Select)({
  "& .MuiPaper-root": {
    boxShadow: "none",
  },
  ".MuiSelect-select": {
    padding: "8px 10px",
    lineHeight: "1.5",
  },
});
interface ICustomDropdown {
  title: string;
  entities: string[];
  value: string;
  style?: any;
  onChange: (e: any) => void;
}

export const CustomDropdown = ({
  title,
  entities,
  style,
  value,
  onChange,
}: ICustomDropdown) => {
  return (
    <div>
      <Typography style={{ marginBottom: "8px", color: "rgba(0, 0, 0, 0.6)" }}>
        {title}
      </Typography>
      <CustomFormControl style={{ width: "100%" }} required>
        <CustomSelect
          IconComponent={() => (
            <i
              className="bi bi-chevron-down"
              style={{ marginRight: "16px" }}
            ></i>
          )}
          value={value}
          onChange={onChange}
          fullWidth
          style={style}
        >
          {entities.map((entity: any, idx: number) => (
            <MenuItem value={entity} key={idx}>
              {entity}
            </MenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </div>
  );
};
