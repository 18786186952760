import React from "react";
import { toast } from "react-toastify";
import Footer from "../../container/layout/Footer";
import Header from "../../container/layout/Header";
import { checkValidity } from "../../utils/utility";
import { CustomInput } from "../Shared/CustomInput";

interface IForgotPassword {
  email: string;
  isLoading: boolean;
  clickSubmit: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleChange: (e: any, label: string) => void;
}
export const ForgotPassword = (props: IForgotPassword) => (
  <>
    <Header />
    <div
      className="signup__container"
      style={{
        backgroundImage: "url('/images/Signin.jpg')",
        padding: "2.54rem",
      }}
    >
      <div className="authentication_Landing__white-box">
        <h1 className="p-2 pt-4 text-center" style={{ color: "#535253" }}>
          Forgot Password
        </h1>

        <div className="white-box">
          <div className="form-group" style={{ width: "93%", margin: "auto" }}>
            <CustomInput
              allowOnly="EMAIL"
              label="Email"
              style={{
                border: "2px solid #000",
                borderRadius: "15px",
              }}
              name={"email"}
              value={props.email}
              onChange={(e: any) => props.handleChange(e, "email")}
              onBlur={(e: any) => {
                if (
                  !checkValidity(e.target.value, {
                    isEmail: true,
                    required: true,
                  })
                ) {
                  toast.error("Please enter a valid email");
                }
              }}
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: ".8rem",
            }}
          >
            <button
              className="btn btn-primary"
              style={{ width: "30%" }}
              onClick={() => (window.location.href = "/signin")}
            >
              Sign in
            </button>
            <button
              onClick={(e) => props.clickSubmit(e)}
              className="btn  btn-outline-danger"
              style={{ border: "1.2px solid #dc3545" }}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
