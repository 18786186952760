import React from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import {
  IOccupationDetailsReturn,
  UpdateOccupationDetailsState,
  useOccupationDetails,
} from "../../hooks/registration/OccupationDetailsHook";
import { IJobType, ISalaryRange } from "../../interfaces/model_helper";
import { CustomDropdown } from "../Shared/CustomDropdown";
import { CustomCapitalInput } from "../Shared/CustomCapitalInput";

const RegisterOccupationDetails = ({
  prevStep,
  nextStep,
}: {
  prevStep: () => void;
  nextStep: () => void;
}) => {
  const {
    occupationDetails,
    isValid,
    occupationDetailsErrors,
    handleOccupationDetailsUpdate,
    handleOccupationDetailsErrors,
    handleCreate,
  }: IOccupationDetailsReturn = useOccupationDetails();
  const cancel = () => {
    prevStep();
  };
  const save = () => {
    handleCreate();
    nextStep();
  };
  return (
    <>
      <Grid item lg={12} style={{ height: "15px" }}></Grid>
      <Grid item lg={12}>
        <div style={{ margin: "1rem 3rem " }}>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Occupation type *"}
                entities={Object.values(IJobType)}
                value={occupationDetails.type}
                onChange={(e: any) =>
                  handleOccupationDetailsUpdate({
                    type: UpdateOccupationDetailsState.Updatetype,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            {occupationDetails.type !== IJobType.NOT_WORKING && (
              <>
                <Grid item lg={0.5}></Grid>
                <Grid item lg={3.5}>
                  <CustomDropdown
                    title={"Annual Income *"}
                    entities={Object.values(ISalaryRange)}
                    value={occupationDetails.salary_range}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdateSalaryRange,
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item lg={0.5}></Grid>
                <Grid item lg={4}></Grid>
              </>
            )}
          </div>
          {occupationDetails.type !== IJobType.NOT_WORKING && (
            <>
              <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
                <Grid item lg={3.5}>
                  <CustomCapitalInput
                    label="Name of Organization / Business *"
                    style={{ width: "100%" }}
                    name={UpdateOccupationDetailsState.UpdateCompanyName}
                    value={occupationDetails.company_name}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdateCompanyName,
                        value: e.target.value,
                      })
                    }
                    onBlur={(e: any) =>
                      handleOccupationDetailsErrors({
                        type: UpdateOccupationDetailsState.UpdateCompanyName,
                        value: e.target.value,
                      })
                    }
                    error={occupationDetailsErrors.company_name}
                    helper={
                      occupationDetailsErrors.company_name
                        ? {
                            text: "Please enter your company name",
                          }
                        : undefined
                    }
                    required
                  />
                </Grid>
                <Grid item lg={0.5}></Grid>
                <Grid item lg={3.5}>
                  <CustomInput
                    allowOnly="TEXT"
                    label="Location *"
                    style={{ width: "100%" }}
                    name={UpdateOccupationDetailsState.UpdateLocation}
                    value={occupationDetails.location}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdateLocation,
                        value: e.target.value,
                      })
                    }
                    onBlur={(e: any) =>
                      handleOccupationDetailsErrors({
                        type: UpdateOccupationDetailsState.UpdateLocation,
                        value: e.target.value,
                      })
                    }
                    error={occupationDetailsErrors.location}
                    helper={
                      occupationDetailsErrors.location
                        ? {
                            text: "Please enter your Job location",
                          }
                        : undefined
                    }
                    required
                  />
                </Grid>
                <Grid item lg={0.5}></Grid>

                <Grid item lg={4}>
                  <CustomInput
                    allowOnly="TEXT"
                    label="Position *"
                    style={{ width: "100%" }}
                    name={UpdateOccupationDetailsState.UpdatePosition}
                    value={occupationDetails.position}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdatePosition,
                        value: e.target.value,
                      })
                    }
                    onBlur={(e: any) =>
                      handleOccupationDetailsErrors({
                        type: UpdateOccupationDetailsState.UpdatePosition,
                        value: e.target.value,
                      })
                    }
                    error={occupationDetailsErrors.position}
                    helper={
                      occupationDetailsErrors.position
                        ? {
                            text: "Please enter your job position",
                          }
                        : undefined
                    }
                    required
                  />
                </Grid>
              </div>
              <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
                <Grid item lg={10}>
                  <CustomInput
                    label="Occupation Description *"
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    name={UpdateOccupationDetailsState.UpdateDescription}
                    value={occupationDetails.description}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdateDescription,
                        value: e.target.value,
                      })
                    }
                    onBlur={(e: any) =>
                      handleOccupationDetailsErrors({
                        type: UpdateOccupationDetailsState.UpdateDescription,
                        value: e.target.value,
                      })
                    }
                    error={occupationDetailsErrors.description}
                    helper={
                      occupationDetailsErrors.description
                        ? {
                            text: "Please enter your job description",
                          }
                        : undefined
                    }
                    required
                  />
                </Grid>
              </div>
              <div
                className="dp--flex dp--jcsb"
                style={{ marginTop: "16px", marginBottom: "48px" }}
              >
                <Grid item lg={10}>
                  <CustomInput
                    label="Future endeavours"
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    name={UpdateOccupationDetailsState.UpdateFuturePlans}
                    value={occupationDetails.future_plans}
                    onChange={(e: any) =>
                      handleOccupationDetailsUpdate({
                        type: UpdateOccupationDetailsState.UpdateFuturePlans,
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
              </div>
            </>
          )}
        </div>
        <Grid item lg={12}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" onClick={cancel}>
              Go Back
            </button>
            <button className="btn btn--blue" disabled={isValid} onClick={save}>
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterOccupationDetails;
