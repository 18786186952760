import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";

const ProfileKundaliAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  return (
    <>
      <StyledAccordion
        expanded={first}
        onChange={(e: any) => {
          setfirst(!first);
        }}
      >
        <StyledAccordionSummary aria-label="Profile">
          <Grid container alignItems="center" justifyContent="space-between">
            <h4 className="text-primary font-weight-bold">
              Kundali Information
            </h4>
          </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {user.kundali_details && (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              rowSpacing={1}
            >
              <Grid item lg={6}>
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Kundali Exists:
                  </Typography>
                  <Typography>
                    {user.kundali_details.kundali_exists ? "Yes" : "No"}
                  </Typography>
                </Grid>
                {user.kundali_details.gan && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Gan:
                    </Typography>
                    <Typography>{user.kundali_details.gan}</Typography>
                  </Grid>
                )}
                {user.kundali_details.gotra && (
                  <Grid
                    item
                    lg={3}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Gotra:
                    </Typography>
                    <Typography>{user.kundali_details.gotra}</Typography>
                  </Grid>
                )}
                {user.kundali_details.nakshatra && (
                  <Grid
                    item
                    lg={6}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Nakshatra:
                    </Typography>
                    <Typography>{user.kundali_details.nakshatra}</Typography>
                  </Grid>
                )}
                {user.kundali_details.charan && (
                  <Grid
                    item
                    lg={6}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Charan:
                    </Typography>
                    <Typography>{user.kundali_details.charan}</Typography>
                  </Grid>
                )}
                {user.kundali_details.raas && (
                  <Grid
                    item
                    lg={6}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Raas:
                    </Typography>
                    <Typography>{user.kundali_details.raas}</Typography>
                  </Grid>
                )}
                {user.kundali_details.naadi && (
                  <Grid
                    item
                    lg={6}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Naadi:
                    </Typography>
                    <Typography>{user.kundali_details.naadi}</Typography>
                  </Grid>
                )}
                {user.kundali_details.time_of_birth && (
                  <Grid
                    item
                    lg={6}
                    style={{ marginTop: "10px" }}
                    className="dp--flex"
                  >
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Time of Birth:
                    </Typography>
                    <Typography>
                      {user.kundali_details.time_of_birth}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={6}>
                {user.kundali_details.kundali_photo && (
                  <img
                    style={{ width: "30rem" }}
                    src={user.kundali_details.kundali_photo}
                    alt="kundali"
                  />
                )}
              </Grid>
            </Grid>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </>
  );
};

export default ProfileKundaliAccordian;
