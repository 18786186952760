import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";
import { isEmpty } from "lodash";

const ProfileEducationAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  let render = <div></div>;
  if (!isEmpty(user.educational_qualification)) {
    render = (
      <>
        <StyledAccordion
          expanded={first}
          onChange={(e: any) => {
            setfirst(!first);
          }}
        >
          <StyledAccordionSummary aria-label="Profile">
            <Grid container alignItems="center" justifyContent="space-between">
              <h4 className="text-primary font-weight-bold">
                Educational Information
              </h4>
            </Grid>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {user.educational_qualification.map((edu: any, index: number) => (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{
                  borderBottom: "1px solid grey",
                  paddingBottom: "10px",
                }}
                key={index}
              >
                <Grid item lg={12} className="dp--flex">
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Name of Institute:
                    </Typography>
                    <Typography>{edu.college_name}</Typography>
                  </Grid>
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Degree:
                    </Typography>
                    <Typography>{edu.degree} </Typography>
                  </Grid>
                  <Grid item lg={4} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Degree Description:
                    </Typography>
                    <Typography style={{ marginRight: "5rem" }}>
                      {edu.degree_specification}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Year of Passing:
                    </Typography>
                    <Typography style={{ marginRight: "5rem" }}>
                      {edu.year_of_passing}
                    </Typography>
                  </Grid>
                </Grid>
                {!isEmpty(edu.special_achievements) && (
                  <Grid item lg={12} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Special Achievements:
                    </Typography>
                    <Typography>{edu.special_achievements}</Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      </>
    );
  }
  return render;
};

export default ProfileEducationAccordian;
