import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ForgotPassword } from "../../components/authentication/PasswordForget";
import { forgot } from "../../services/actions";

export const PasswordForget = () => {
  const [values, setValues] = useState({
    email: "",
    isLoading: false,
  });
  const { email, isLoading } = values;
  const navigate = useNavigate();
  const handleChange = (e: any, name: string) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const clickSubmit = (event: any) => {
    // event.preventDefault();
    setValues({ ...values, isLoading: true });
    forgot(email, () => {
      navigate("/signin");
    });
  };

  return (
    <ForgotPassword
      email={email}
      isLoading={isLoading}
      clickSubmit={clickSubmit}
      handleChange={handleChange}
    />
  );
};

export default PasswordForget;
