import React, { useState } from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import CustomAutocomplate from "../Shared/CustomAutocomplate";
import {
  IKundaliDetailsReturn,
  UpdateKundaliState,
  useKundaliDetails,
} from "../../hooks/registration/KundaliDetailsHook";
import { CustomDropdown } from "../Shared/CustomDropdown";
import { IBooleanState } from "../../interfaces/model_helper";
import { ConfirmationPopup } from "../Shared/ConfirmationPopup";
import { handleKundaliChange } from "../../utils/utility";
import { isEmpty } from "lodash";
import {
  ICharan,
  IGan,
  IGotra,
  INaadi,
  INakshatra,
  IRaas,
} from "../../interfaces/KundaliInterface";

const RegisterKundaliDetails = ({ prevStep }: { prevStep: () => void }) => {
  const {
    kundaliDetails,
    isValid,
    handleKundaliDetailsUpdate,
    handleReset,
    handleCreate,
  }: IKundaliDetailsReturn = useKundaliDetails();
  const cancel = () => {
    prevStep();
    handleReset();
  };
  const save = () => {
    handleCreate();
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid item lg={12} style={{ height: "15px" }}></Grid>
      <Grid item lg={12} className="dp--flex dp--jcsa dp--aife">
        <h4 className="ml-5"> Do you have kundali details?</h4>
        <div style={{ width: "30%", marginRight: "3rem" }} className="">
          <CustomDropdown
            title="Yes/No *"
            value={kundaliDetails.kundali_exists}
            onChange={(e) => {
              handleKundaliDetailsUpdate({
                type: UpdateKundaliState.UpdatekundaliExists,
                value: e.target.value,
              });
            }}
            entities={Object.keys(IBooleanState)}
          />
        </div>
      </Grid>
      {kundaliDetails.kundali_exists === "Yes" ? (
        <Grid item lg={12}>
          <div style={{ margin: "1rem 2rem " }}>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={3.5}>
                <CustomAutocomplate
                  title="Gan"
                  options={Object.values(IGan)}
                  value={kundaliDetails.gan}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGan,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGan,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGan,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item lg={0.5}></Grid>
              <Grid item lg={3.5}>
                <CustomAutocomplate
                  title="Gotra"
                  options={Object.values(IGotra)}
                  value={kundaliDetails.gotra}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGotra,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGotra,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateGotra,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item lg={0.5}></Grid>
              <Grid item lg={4}>
                <CustomAutocomplate
                  title="Nakshatra"
                  options={Object.values(INakshatra)}
                  value={kundaliDetails.nakshatra}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNakshatra,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNakshatra,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNakshatra,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
            </div>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={3.5}>
                <CustomAutocomplate
                  title="Charan"
                  options={Object.values(ICharan)}
                  value={kundaliDetails.charan}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateCharan,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateCharan,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateCharan,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item lg={0.5}></Grid>
              <Grid item lg={3.5}>
                <CustomAutocomplate
                  title="Nadi"
                  options={Object.values(INaadi)}
                  value={kundaliDetails.naadi}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNaadi,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNaadi,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.UpdateNaadi,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item lg={0.5}></Grid>
              <Grid item lg={4}>
                <CustomAutocomplate
                  title="Raas"
                  options={Object.values(IRaas)}
                  value={kundaliDetails.raas}
                  onChange={(event: any, newValue: any) => {
                    if (typeof newValue === "string") {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.Updateraas,
                        value: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.Updateraas,
                        value: newValue.inputValue,
                      });
                    } else {
                      handleKundaliDetailsUpdate({
                        type: UpdateKundaliState.Updateraas,
                        value: newValue,
                      });
                    }
                  }}
                />
              </Grid>
            </div>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={8}></Grid>
            </div>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={3.5}>
                <CustomInput
                  type="time"
                  label="Time of Birth"
                  style={{ width: "100%" }}
                  name={UpdateKundaliState.UpdateTimeOfBirth}
                  value={kundaliDetails.time_of_birth}
                  onChange={(e: any) =>
                    handleKundaliDetailsUpdate({
                      type: UpdateKundaliState.UpdateTimeOfBirth,
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={0.5} className="dp--flex"></Grid>
              {isEmpty(kundaliDetails.kundali_photo) && (
                <Grid
                  item
                  lg={8}
                  className="dp--flex dp--aife"
                  style={{ height: "4rem" }}
                >
                  <div style={{ fontSize: "1.25rem", width: "25%" }}>
                    Upload Kundali* &nbsp; &nbsp; &nbsp;
                  </div>
                  <label
                    htmlFor="file-upload"
                    style={{ margin: "0px" }}
                    className="btn btn--white"
                  >
                    choose file
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      save();
                      handleKundaliChange(e);
                    }}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      ) : (
        <br />
      )}
      {!isEmpty(kundaliDetails.kundali_photo) &&
        kundaliDetails.kundali_exists === "Yes" && (
          <Grid item lg={12}>
            <Grid item lg={4}></Grid>
            <Grid item lg={4} style={{ margin: "auto" }}>
              <img
                style={{ width: "30rem" }}
                src={kundaliDetails.kundali_photo}
                alt="kundali"
              />
            </Grid>
            <Grid item lg={4}></Grid>
          </Grid>
        )}
      <Grid item lg={12}>
        <div className="dp--flex dp--jcc ">
          <button className="btn btn--blue" onClick={cancel}>
            Go Back
          </button>
          <button
            className="btn btn--blue"
            disabled={kundaliDetails.kundali_exists === "Select" || isValid}
            onClick={() => save()}
          >
            Save and Register
          </button>
        </div>
      </Grid>
      <ConfirmationPopup
        open={open}
        setOpen={setOpen}
        onClickConfirm={save}
        title={"Please check your details"}
        msg={"Are you sure you want to continue?"}
      />
    </>
  );
};

export default RegisterKundaliDetails;
