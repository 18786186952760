import React, { useState } from "react";

const ReadMore = (props: { value: any; limit: any; className: any }) => {
  const { value, limit, className } = props;
  const [state, toggle] = useState(true);
  const toggleState = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggle(!state);
  };

  return value ? (
    <>
      {value.length < limit ? (
        <p className={className}>{value}</p>
      ) : state ? (
        <>
          <p className={className}>
            {value.substr(0, limit)}...&nbsp;
            <button className="btn btn--blue" onClick={toggleState}>
              Read More
            </button>
          </p>
        </>
      ) : (
        <>
          <p className={className}>
            {value}
            <br />
            <button className="btn btn--blue" onClick={toggleState}>
              Read Less
            </button>
          </p>
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default ReadMore;
