import { AddUser } from "../../components/Admin/AddUser";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { postAction } from "../../services/generalServices";

const AdminAddUser = () => {
  const inititialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    isLoading: false,
  };
  const [values, setValues] = useState(inititialState);
  const {
    firstName,
    middleName,
    lastName,
    gender,
    email,
    mobileNumber,
    password,
    confirmPassword,
    isLoading,
  } = values;

  const handleChange = (event: any, name: string) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const navigate = useNavigate();

  const clickSubmit = async (event: any) => {
    event.preventDefault();
    setValues({ ...values, isLoading: true });
    const data = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender: gender,
      emailId: email,
      mobile_number: mobileNumber,
      password: password,
      confirm_password: confirmPassword,
    };
    const response = await postAction("admin/user", data);
    if (response.data.error) {
      toast.error(response.data.error);
    } else {
      setValues(inititialState);
      toast.success("User created successfully");
      navigate("/admin");
    }
  };

  return (
    <AddUser
      clickSubmit={clickSubmit}
      handleChange={handleChange}
      email={email}
      firstName={firstName}
      middleName={middleName}
      lastName={lastName}
      gender={gender}
      mobileNumber={mobileNumber}
      password={password}
      confirmPassword={confirmPassword}
      isLoading={isLoading}
    />
  );
};

export default AdminAddUser;
