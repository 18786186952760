import React from "react";
import Footer from "../../container/layout/Footer";
import Header from "../../container/layout/Header";
import { CustomInput } from "../Shared/CustomInput";

interface IResetPasswordForm {
  name: string;
  newPassword: string;
  clickSubmit: (e: React.FormEvent<HTMLButtonElement>) => void;
  handlePassChange: (e: any) => void;
  isLoading: boolean;
}
export const ResetPasswordForm = (props: IResetPasswordForm) => {
  return (
    <>
      <Header />
      <div
        className="signup__container"
        style={{ backgroundImage: "url('/images/Signin.jpg')" }}
      >
        <div className="authentication_Landing__white-box">
          <h2
            className="authentication_Landing__featured"
            style={{ color: "#535253" }}
          >
            hey {props.name}, enter new password
          </h2>
          <form className="authentication_Landing__form">
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                type="password"
                label="Password"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"password"}
                value={props.newPassword}
                onChange={(e: any) => props.handlePassChange(e)}
              />
            </div>
            <br />
            <div className="">
              <button
                onClick={props.clickSubmit}
                style={{ margin: "auto" }}
                className="btn btn--blue"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
