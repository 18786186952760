import React, { useState } from "react";
import { Grid } from "@mui/material";
import DatagridTable from "../Shared/DatagridTable";
import { UsersListColumns } from "./constants";
import { IAdminUserData } from "../../interfaces/Interfaces.model";
import { useNavigate } from "react-router";
import { DeleteConfirmation } from "./DeleteConfirmation";

const AdminHome = ({ userList }: { userList: IAdminUserData[] }) => {
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const updateUser = (userId: string) => {
    console.log(userId);
    navigate(`/adminUser/edit/${userId}`);
  };
  return (
    <>
      <Grid
        item
        lg={12}
        className="dp--flex dp--jcsb mx-3 dp--aic"
        style={{ width: "100%" }}
      >
        <p></p>
        <h1 onClick={() => navigate("/admin")}>Admin Panel</h1>
        <button
          className="btn btn--green"
          onClick={() => navigate("/admin/add-user")}
        >
          Add new user
        </button>
      </Grid>
      <DatagridTable
        rowClickHandler={(params: any) => {
          // window.open(`/${params.row.gender}/${params.row.id}`, "_blank");
        }}
        columns={UsersListColumns(setDeleteId, updateUser)}
        rows={userList}
        setSelectionModel={(data: any) => {}}
      />
      <DeleteConfirmation deleteId={deleteId} />
    </>
  );
};

export default AdminHome;
