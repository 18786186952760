import { Grid } from "@mui/material";
import React from "react";
import { IStateData } from "../../container/user/RegisterProfile";

interface IRegistrationLeftSideBarprops {
  state: IStateData;
  setState: (state: IStateData) => void;
  checkActive: (currentStep: number) => string;
}

const RegistrationLeftSideBar = ({
  state,
  setState,
  checkActive,
}: IRegistrationLeftSideBarprops) => {
  return (
    <Grid
      item
      lg={3}
      className="sidebar__container"
      style={{ height: "80vh", borderRight: "2px solid #FBCDCD" }}
    >
      <div className="profile__sidebar">
        <button
          className={checkActive(1)}
          onClick={() => setState({ ...state, step: 1 })}
        >
          Personal Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right"></i>
        </button>
        <button
          className={checkActive(2)}
          onClick={() => setState({ ...state, step: 2 })}
        >
          Educational Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
        <button
          className={checkActive(3)}
          onClick={() => setState({ ...state, step: 3 })}
        >
          Occupational Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
        <button
          className={checkActive(4)}
          onClick={() => setState({ ...state, step: 4 })}
        >
          Family Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
        <button
          className={checkActive(5)}
          onClick={() => setState({ ...state, step: 5 })}
        >
          Preference Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
        <button
          className={checkActive(6)}
          onClick={() => setState({ ...state, step: 6 })}
        >
          Photo Gallery
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
        <button
          className={checkActive(7)}
          onClick={() => setState({ ...state, step: 7 })}
        >
          Kundali Details
          <i style={{ fontSize: "1.2rem" }} className="fas fa-caret-right" />
        </button>
      </div>
    </Grid>
  );
};
export default RegistrationLeftSideBar;
