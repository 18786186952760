import { isEmpty } from "lodash";
import React from "react";
import { Navigate } from "react-router";
import ErrorPage from "../../components/utils/ErrorPage";
import { IUserData } from "../../interfaces/Interfaces.model";
import { isAuth } from "../../utils/helper";

const WithAuth = (role: string) => (Component: any) =>
  class withAuth extends React.Component {
    renderProtectedPage() {
      const user: IUserData = isAuth();
      const userRole = user && user.role;
      const isAdmin = user && user.role === "admin";
      let isAuthorized = isAdmin;
      if (!isEmpty(role)) {
        if (userRole && userRole === role) {
          isAuthorized = true;
        }
      } else {
        isAuthorized = true;
      }
      const newRegex = new RegExp("/" + user.gender, "g");
      if (!user) {
        return <Navigate to="/signin" />;
      } else if (!isAuthorized) {
        return <ErrorPage error="unAuthorized" />;
      } else if (isAdmin) {
        return <Component {...this.props} />;
      } else if (newRegex.test(window.location.href)) {
        return <ErrorPage error="unAuthorized" />;
      } else if (!user.isRegistered && !isEmpty(user.transaction_id)) {
        return <Navigate to="/register" />;
      } else if (!user.transaction_id || isEmpty(user.transaction_id)) {
        return <Navigate to="/payment" />;
      } else {
        return <Component {...this.props} />;
      }
    }

    render() {
      return this.renderProtectedPage();
    }
  };

export default WithAuth;
