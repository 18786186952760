export enum IGan {
  Manav_Gan = "Manav Gan",
  Dev_Gan = "Dev Gan",
  Rakshas_Gan = "Rakshas Gan",
}

export enum IGotra {
  Atri = "Atri",
  Bharadvaja = "Bharadvaja",
  Bhrigu = "Bhrigu",
  Gotama = "Gotama",
  Kashyapa = "Kashyapa",
  Vasishtha = "Vasishtha",
  Vishvamitra = "Vishvamitra",
  Jamdagni = "Jamdagni",
  Angira = "Angira",
  Shandilya = "Shandilya",
  Bhargava = "Bhargava",
  Gargya = "Gargya",
  Kaushik = "Kaushik",
  Kapil = "Kapil",
  Parashar = "Parashar",
  Mudgal = "Mudgal",
}

export enum INakshatra {
  Anuradha = "Anuradha",
  Ardra = "Ardra",
  Ashwini = "Ashwini",
  Ashlesha = "Ashlesha",
  Bharani = "Bharani",
  Chitra = "Chitra",
  Dhanishta = "Dhanishta",
  Hasta = "Hasta",
  Jyestha = "Jyestha",
  Kritika = "Kritika",
  Magh = "Magh",
  Moola = "Moola",
  Mrigashira = "Mrigashira",
  Pushya = "Pushya",
  Punarvasu = "Punarvasu",
  Poorva_Ashadha = "Poorva Ashadha",
  Poorva_Bhadra = "Poorva Bhadra",
  Purva_Phalguni = "Purva Phalguni",
  Revati = "Revati",
  Rohini = "Rohini",
  Shatabhisha = "Shatabhisha",
  Shravan = "Shravan",
  Swati = "Swati",
  Uttar_Bhadra = "Uttar Bhadra",
  Uttar_Phalguni = "Uttar Phalguni",
  Uttar_Ashadha = "Uttar Ashadha",
  Vishakha = "Vishakha",
}

export enum IRaas {
  Aries = "Mesh (Aries)",
  Taurus = "Varishabha (Taurus)",
  Gemini = "Mithuna (Gemini)",
  Cancer = "Karka (Cancer)",
  Leo = "Simha (Leo)",
  Virgo = "Kanya (Virgo)",
  Libra = "Tula (Libra)",
  Scorpio = "Vrichika (Scorpio)",
  Sagittarius = "Dhanur (Sagittarius)",
  Capricorn = "Makara (Capricorn)",
  Aquarius = "Kumbha (Aquarius)",
  Pisces = "Meena (Pisces)",
}
export enum INaadi {
  Aadi_Nadi = "Aadi Nadi",
  Madhya_Nadi = "Madhya Nadi",
  Antya_Nadi = "Antya Nadi",
}
export enum ICharan {}
