import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import { isAuth, onAuth } from "../../utils/helper";
import default_display from "../../assets/images/default.png";

import {
  IPersonalDetailsReturn,
  UpdatePersonalDetailsState,
  usePersonalDetails,
} from "../../hooks/registration/PersonalDetailshook";
import { IStateData } from "../../container/user/RegisterProfile";
import { CustomDropdown } from "../Shared/CustomDropdown";
import {
  IBloodGroup,
  ICaste,
  IMaritialStatus,
  IResidentialStatus,
  ISubCaste,
} from "../../interfaces/model_helper";
import { handleDpChange } from "../../utils/utility";
import { CustomCapitalInput } from "../Shared/CustomCapitalInput";
import { deleteAction } from "../../services/generalServices";
import { toast } from "react-toastify";

const RegisterPersonalDetailsPhase0 = ({
  state,
  setState,
}: {
  state: IStateData;
  setState: (state: IStateData) => void;
}) => {
  const nextPhase = async () => {
    const { phase } = state;
    const response = await handleCreateP0();
    if (!response.error) setState({ ...state, phase: phase + 1 });
  };

  const {
    personalDetails,
    isPersonalDetailsValidP0,
    personalDetailsErrors,
    handleProfileDetailsUpdate,
    handlePersonalDetailsErrors,
    handleCreateP0,
  }: IPersonalDetailsReturn = usePersonalDetails();

  return (
    <>
      <Grid
        item
        lg={12}
        className="parent-img"
        // style={{
        //   height: "30vh",
        //   backgroundImage: `url("${isAuth().personal_details.album[0]}")`,
        // }}
      >
        <div
          className="dp--flex"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <div className="dp--flex">
            <div className="ml-1 mb-1">
              <img
                src={
                  isAuth().personal_details.profile_photo !== "default.png"
                    ? isAuth().personal_details.profile_photo
                    : default_display
                }
                alt="profile"
                className="pfp"
              />
            </div>
            <div className="dp--flex" style={{ flexDirection: "column" }}>
              <div
                className="dp--flex dp--jcfs"
                style={{ flexDirection: "column", height: "8.3rem" }}
              >
                <Tooltip title="delete profile image">
                  <div
                    className="btn btn-danger dp--flex dp--jcc dp--aic "
                    onClick={() =>
                      deleteAction("/photo/profile-photo", () => {
                        onAuth(() => {
                          toast.success("Profile photo removed successfully");
                          window.location.reload();
                        });
                      })
                    }
                    style={{
                      color: "#FFF",
                      marginLeft: "-.81rem",
                    }}
                  >
                    <i
                      className="fa fa-trash-can"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      
                    </i>
                  </div>
                </Tooltip>
              </div>
              <div
                className="dp--flex dp--jce"
                style={{ flexDirection: "column" }}
              >
                <input
                  type="file"
                  className="upload-image-input input-file"
                  accept="image/*"
                  id="upload-image-input"
                  onChange={handleDpChange}
                  onLoad={(e: any) => console.log(e)}
                />
                <Tooltip title="Upload profile image">
                  <label htmlFor="upload-image-input">
                    <div
                      className="btn btn-primary dp--flex dp--jcc dp--aic "
                      style={{
                        color: "#FFF",
                        marginLeft: "-.81rem",
                      }}
                    >
                      <i
                        className="fa fa-user"
                        style={{
                          fontSize: "1rem",
                        }}
                      ></i>
                    </div>
                  </label>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <p className="dp--flex dp--jcc mb-4 flex--aifs">
          *Image should not be more than 5mb. Please upload &nbsp;
          <span style={{ color: "red" }}> passport size photo</span>
        </p>
      </Grid>
      <Grid item lg={12}>
        <div style={{ margin: "1rem 3rem " }}>
          <div className="dp--flex dp--jcsb">
            <Grid item lg={3.5}>
              <CustomInput
                label="First Name"
                value={isAuth().first_name}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                label="Middle Name"
                value={isAuth().middle_name}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomCapitalInput
                label="Last Name"
                value={isAuth().last_name}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                allowOnly="NUMBER"
                type="number"
                label="Contact Number *"
                name={UpdatePersonalDetailsState.UpdateMobileNumber}
                value={personalDetails.mobile_number}
                style={{ width: "100%" }}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateMobileNumber,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateMobileNumber,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.mobile_number}
                helper={
                  personalDetailsErrors.mobile_number
                    ? {
                        text: "Please enter valid mobile number",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                label="Alternate Contact"
                type="number"
                style={{ width: "100%" }}
                name={UpdatePersonalDetailsState.UpdateAlternateNumber}
                value={personalDetails.alternate_number}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateAlternateNumber,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>

            <Grid item lg={4}>
              <CustomInput
                label="Email-Id"
                value={isAuth().emailId}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                label="Gender"
                value={isAuth().gender}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                label="Date of Birth *"
                type="date"
                style={{ width: "100%" }}
                name={UpdatePersonalDetailsState.UpdateDob}
                value={personalDetails.dob}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateDob,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdateDob,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.dob}
                helper={
                  personalDetailsErrors.dob
                    ? {
                        text: "Please enter valid date of birth",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>

            <Grid item lg={4}>
              <CustomInput
                label="Age"
                value={personalDetails.age}
                style={{ width: "100%" }}
                disabled
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="TEXT"
                label="Place of Birth *"
                name={UpdatePersonalDetailsState.UpdatePlaceOfBirth}
                value={personalDetails.place_of_birth}
                style={{ width: "100%" }}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdatePlaceOfBirth,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handlePersonalDetailsErrors({
                    type: UpdatePersonalDetailsState.UpdatePlaceOfBirth,
                    value: e.target.value,
                  })
                }
                error={personalDetailsErrors.place_of_birth}
                helper={
                  personalDetailsErrors.place_of_birth
                    ? {
                        text: "Please enter valid place of birth",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Blood Group"}
                entities={Object.values(IBloodGroup)}
                value={personalDetails.blood_group}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateBloodGroup,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Maritial Status *"}
                entities={Object.values(IMaritialStatus)}
                value={personalDetails.marital_status}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateMaritalStatus,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Caste *"}
                entities={Object.values(ICaste)}
                value={personalDetails.caste}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateCaste,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              {ICaste.OTHERS !== personalDetails.caste ? (
                <CustomDropdown
                  title={"sub Caste *"}
                  entities={Object.values(ISubCaste)}
                  value={personalDetails.sub_caste}
                  onChange={(e: any) =>
                    handleProfileDetailsUpdate({
                      type: UpdatePersonalDetailsState.UpdateSubCaste,
                      value: e.target.value,
                    })
                  }
                />
              ) : (
                <CustomInput
                  allowOnly="TEXT"
                  label="Other caste *"
                  name={UpdatePersonalDetailsState.UpdateOtherCaste}
                  value={personalDetails.other_caste}
                  style={{ width: "100%" }}
                  onChange={(e: any) =>
                    handleProfileDetailsUpdate({
                      type: UpdatePersonalDetailsState.UpdateOtherCaste,
                      value: e.target.value,
                    })
                  }
                  onBlur={(e: any) =>
                    handlePersonalDetailsErrors({
                      type: UpdatePersonalDetailsState.UpdateOtherCaste,
                      value: e.target.value,
                    })
                  }
                  error={personalDetailsErrors.other_caste}
                  helper={
                    personalDetailsErrors.other_caste
                      ? {
                          text: "Please enter your caste",
                        }
                      : undefined
                  }
                  required
                />
              )}
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Residing in  *"}
                entities={Object.values(IResidentialStatus)}
                value={personalDetails.residential_status}
                onChange={(e: any) =>
                  handleProfileDetailsUpdate({
                    type: UpdatePersonalDetailsState.UpdateResidentialStatus,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
        </div>
        <Grid item lg={12}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" disabled={true}>
              Go Back
            </button>
            <button
              disabled={isPersonalDetailsValidP0}
              className="btn btn--blue"
              onClick={nextPhase}
            >
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterPersonalDetailsPhase0;
