import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { patchAction } from "../services/generalServices";
//set in localStorage
export const setLocalStorage = (key: string, value: string) => {
  if (window) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
//remove in localStorage
export const removeLocalStorage = (key: string) => {
  if (window) {
    localStorage.removeItem(key);
  }
};

//set in cookie
export const setcookie = (key: string, value: string) => {
  if (window) {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};
//remove in cookie
export const removecookie = (key: string) => {
  if (window) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

//get cookie
export const getcookie = (key: string) => {
  if (window) {
    return cookie.get(key);
  }
};
export const onAuth = (callback?: any) => {
  axiosInstance
    .get("/me")
    .then((response) => {
      setLocalStorage("user", response.data.user);
      if (callback) callback(response);
    })
    .catch((err) => {
      toast.error("something went wrong");
    });
};

//get data from localstorage
export const isAuth = () => {
  if (window) {
    if (!!localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user") ?? "");
    } else {
      return false;
    }
  }
};

export const getLocalNotification = () => {
  if (window) {
    if (!!localStorage.getItem("notification")) {
      return JSON.parse(localStorage.getItem("notification") ?? "");
    } else {
      return false;
    }
  }
};
//store token and user data in storage
export const authenticate = (response: { data: any }, next: any) => {
  setLocalStorage("user", response.data.user);

  const expirationDate = new Date(
    new Date().getTime() + 60 * 60 * 24 * 10 * 1000,
  );
  setLocalStorage("expirationDate", expirationDate.toDateString());

  if (response.data.user.notificationId) {
    axios
      .get(`/notification/${isAuth().notificationId}`)
      .then((response) => {
        setLocalStorage("notification", response.data.data[0]);
        next();
      })
      .catch((err) => {
        next();
      });
  } else {
    next();
  }
};
export const updateObject = (oldObject: [], updatedProperties: []) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const updateArray = (oldArray: [], newArray: []) => {
  return [...oldArray, ...newArray];
};
let url = `${process.env.REACT_APP_SERVER_URL}/api`;
export const axiosInstance = axios.create({
  baseURL: url,
  withCredentials: true,
});

export const getErrorData = (response: any) => {
  const startString = response.data.search("Error:");
  const endString = response.data.search("<br>");
  const errData = response.data.substring(startString + 7, endString);
  return errData;
};

export const sendLike = async (notificationId: string, toUserId: string) => {
  let notificationBody = {
    link: `/${isAuth().gender}/${isAuth()._id}`,
    from_user: isAuth()._id,
    to_user: toUserId,
    notificationType: "like",
    message: `${isAuth().first_name} ${isAuth().last_name} likes your profile.`,
  };
  //check if data we received from chat contains notification ID
  if (notificationId) {
    try {
      const response = await patchAction(
        `/notification/${notificationId}`,
        notificationBody,
      );
      if (response.status !== 200) {
        // const errData = getErrorData(response);
        toast.error("You have already Liked this profile");
      } else {
        toast.success("Like sent successfully");
      }
    } catch (error) {}
  }
};

export const defaultPhoto = async (event: any, navigate: any) => {
  event.preventDefault();

  await patchAction(
    `/users/${isAuth()._id}`,
    {
      defaultPhoto: true,
    },
    () => {
      onAuth(() => {
        navigate(`/profile`);
        window.location.reload();
      });
    },
  );
};
