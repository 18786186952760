import { axiosInstance as axios, isAuth } from "../../utils/helper";

export const getNotification = (next) => {
  try {
    if (isAuth().notificationId) {
      axios.get(`/notification/${isAuth().notificationId}`).then((response) => {
        if (next) next(response);
      });
    }
  } catch (error) {
    console.log("notification action error", error);
  }
};
