import React, { Fragment } from "react";
import Card from "../utils/Card";
import { Link } from "react-router-dom";
import { IFindUser } from "../../interfaces/Interfaces.model";

const FindFemales = ({ searchValues, linkStyles, navigate }: IFindUser) => {
  return !!searchValues.length ? (
    <>
      {searchValues &&
        searchValues.map((female, index) => {
          return (
            <Fragment key={index}>
              <Link
                className="card-title"
                to={`/${female.role}/${female._id}`}
                style={linkStyles}
              ></Link>
              <Card data={female} />
            </Fragment>
          );
        })}
    </>
  ) : (
    <h5
      className="my-3"
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: "800",
        color: "#0084FF",
        fontSize: "30px",
      }}
    >
      No Female Found
    </h5>
  );
};

export default FindFemales;
