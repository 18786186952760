import styled from "@emotion/styled";
import {
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  Typography,
} from "@mui/material";
import React from "react";

export const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    minWidth: "560px",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
  },
});

export const ConfirmationPopup = ({
  open,
  setOpen,
  onClickConfirm,
  title,
  msg,
  btnColor = "primary",
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickConfirm: () => void;
  title: string;
  msg: string;
  btnColor?: "primary" | "error";
}) => {
  const handleClose = () => setOpen(false);

  return (
    <CustomDialog
      open={open}
      // onClose={handleClose}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 24px 0px 24px",
        }}
      >
        <h3>{title}</h3>
        <IconButton size="small" onClick={handleClose}>
          <i className="bi bi-x" style={{ fontSize: "2rem" }} />
        </IconButton>
      </div>
      <DialogContent sx={{ padding: "0px 24px 12px 24px" }}>
        <Typography>{msg}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "12px 24px 24px 24px" }}>
        <button className="btn btn--red" color={btnColor} onClick={handleClose}>
          Cancel
        </button>
        <button
          color={btnColor}
          className="btn btn--blue"
          onClick={onClickConfirm}
        >
          confirm
        </button>
      </DialogActions>
    </CustomDialog>
  );
};
