import React from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import {
  IFamilyDetailsReturn,
  UpdateFamilyDetailsState,
  useFamilyDetails,
} from "../../hooks/registration/FamilyDetailsHook";
import {
  IFamilyMaritialStatus,
  IBooleanState,
} from "../../interfaces/model_helper";
import { CustomDropdown } from "../Shared/CustomDropdown";
import moment from "moment";
import { isEmpty } from "lodash";
import { CustomCapitalInput } from "../Shared/CustomCapitalInput";

const RegisterFamilyDetails = ({
  prevStep,
  nextStep,
}: {
  prevStep: () => void;
  nextStep: () => void;
}) => {
  const {
    familyDetails,
    isValid,
    familyDetailsErrors,
    handleFamilyDetailsUpdate,
    handleFamilyDetailsErrors,
    handleCreate,
  }: IFamilyDetailsReturn = useFamilyDetails();
  const cancel = () => {
    prevStep();
  };
  const save = () => {
    handleCreate();
    nextStep();
  };
  return (
    <>
      <Grid item lg={12}>
        <div style={{ margin: "1rem 3rem " }}>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Father's First Name *"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherFirstName}
                value={familyDetails.father_first_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherFirstName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFatherFirstName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.father_first_name}
                helper={
                  familyDetailsErrors.father_first_name
                    ? {
                        text: "Please enter your father's first name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Father's Middle Name *"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherMiddleName}
                value={familyDetails.father_middle_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherMiddleName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFatherMiddleName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.father_middle_name}
                helper={
                  familyDetailsErrors.father_middle_name
                    ? {
                        text: "Please enter your father's middle name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Father's Last Name *"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherLastName}
                value={familyDetails.father_last_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherLastName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFatherLastName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.father_last_name}
                helper={
                  familyDetailsErrors.father_last_name
                    ? {
                        text: "Please enter your father's last name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="TEXT"
                label="Father's Occupation"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherOccupation}
                value={familyDetails.father_occupation}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherOccupation,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                type="date"
                label="Father's Date of Birth"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherDob}
                value={familyDetails.father_dob}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherDob,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFatherDob,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomInput
                allowOnly="TEXT"
                label="Father's Age"
                style={{ width: "100%" }}
                value={
                  !isEmpty(familyDetails.father_dob)
                    ? moment().diff(familyDetails.father_dob, "years")
                    : 0
                }
                disabled={true}
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                label="Father's Education"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherEducation}
                value={familyDetails.father_education}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherEducation,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="NUMBER"
                type="tel"
                label="Father's Contact"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateFatherMobileNumber}
                value={familyDetails.father_mobile_number}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherMobileNumber,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFatherMobileNumber,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Father Alive"}
                entities={Object.keys(IBooleanState)}
                value={
                  (isEmpty(familyDetails.father_isAlive)
                    ? "Select"
                    : familyDetails.father_isAlive) as any
                }
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFatherIsAlive,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Mother's First Name"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherFirstName}
                value={familyDetails.mother_first_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherFirstName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateMotherFirstName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.mother_first_name}
                helper={
                  familyDetailsErrors.mother_first_name
                    ? {
                        text: "Please enter your mother's first name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Mother's Middle Name"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherMiddleName}
                value={familyDetails.mother_middle_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherMiddleName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateMotherMiddleName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.mother_middle_name}
                helper={
                  familyDetailsErrors.mother_middle_name
                    ? {
                        text: "Please enter your mother's middle name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
            <Grid item lg={0.5}></Grid>

            <Grid item lg={4}>
              <CustomCapitalInput
                allowOnly="TEXT"
                label="Mother's Last Name"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherLastName}
                value={familyDetails.mother_last_name}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherLastName,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateMotherLastName,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.mother_last_name}
                helper={
                  familyDetailsErrors.mother_last_name
                    ? {
                        text: "Please enter your mother's last name",
                      }
                    : undefined
                }
                required
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="TEXT"
                label="Mother's Occupation"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherOccupation}
                value={familyDetails.mother_occupation}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherOccupation,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                type="date"
                label="Mother's Date of Birth"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherDob}
                value={familyDetails.mother_dob}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherDob,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateMotherDob,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomInput
                allowOnly="TEXT"
                label="Mother's Age"
                style={{ width: "100%" }}
                value={
                  !isEmpty(familyDetails.mother_dob)
                    ? moment().diff(familyDetails.mother_dob, "years")
                    : 0
                }
                disabled={true}
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomInput
                label="Mother's Education"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherEducation}
                value={familyDetails.mother_education}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherEducation,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={3.5}>
              <CustomInput
                allowOnly="NUMBER"
                type="tel"
                label="Mother's Contact"
                style={{ width: "100%" }}
                name={UpdateFamilyDetailsState.UpdateMotherMobileNumber}
                value={familyDetails.mother_mobile_number}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherMobileNumber,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateMotherMobileNumber,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item lg={0.5}></Grid>
            <Grid item lg={4}>
              <CustomDropdown
                title={"Mother Alive"}
                entities={Object.keys(IBooleanState)}
                value={
                  (isEmpty(familyDetails.mother_isAlive)
                    ? "Select"
                    : familyDetails.mother_isAlive) as any
                }
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateMotherIsAlive,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={3.5}>
              <CustomDropdown
                title={"Living Status"}
                entities={Object.values(IFamilyMaritialStatus)}
                value={familyDetails.living_status}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateLivingStatus,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </div>
          <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
            <Grid item lg={10}>
              <CustomInput
                label="Address"
                style={{ width: "100%" }}
                multiline
                rows={3}
                name={UpdateFamilyDetailsState.UpdateAddress}
                value={familyDetails.address}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateAddress,
                    value: e.target.value,
                  })
                }
                // onBlur={(e: any) =>
                //   handleFamilyDetailsErrors({
                //     type: UpdateFamilyDetailsState.UpdateAddress,
                //     value: e.target.value,
                //   })
                // }
                // error={familyDetailsErrors.address}
                // helper={
                //   familyDetailsErrors.address
                //     ? {
                //         text: "Please enter an address for your family",
                //       }
                //     : undefined
                // }
                // required
              />
            </Grid>
          </div>
          <div
            className="dp--flex dp--jcsb"
            style={{ marginTop: "16px", marginBottom: "18px" }}
          >
            <Grid item lg={10}>
              <CustomInput
                label="Family Background *"
                style={{ width: "100%" }}
                multiline
                rows={3}
                name={UpdateFamilyDetailsState.UpdateFamilyBackground}
                value={familyDetails.family_background}
                onChange={(e: any) =>
                  handleFamilyDetailsUpdate({
                    type: UpdateFamilyDetailsState.UpdateFamilyBackground,
                    value: e.target.value,
                  })
                }
                onBlur={(e: any) =>
                  handleFamilyDetailsErrors({
                    type: UpdateFamilyDetailsState.UpdateFamilyBackground,
                    value: e.target.value,
                  })
                }
                error={familyDetailsErrors.family_background}
                helper={
                  familyDetailsErrors.family_background
                    ? {
                        text: "Please enter some background for your family",
                      }
                    : undefined
                }
                required
              />
            </Grid>
          </div>
        </div>
        <Grid item lg={12}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" onClick={cancel}>
              Go Back
            </button>
            <button className="btn btn--blue" disabled={isValid} onClick={save}>
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterFamilyDetails;
