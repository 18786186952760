import React from "react";
import Layout from "../../container/layout/Layout";

interface IEditUser {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  mobileNumber: string;
  email: string;
  clickSubmit: (e: any) => void;
  handleChange: (e: any, label: string) => void;
  isLoading: boolean;
}
export const EditUser = (props: IEditUser) => {
  return (
    <Layout>
      <div
        className="authentication_Landing__white-box"
        style={{ margin: "auto" }}
      >
        <h2 className="authentication_Landing__featured">Edit User Details</h2>
        <form className="authentication_Landing__form">
          <div className="">
            <span> First Name </span>
            <br />
            <input
              type="text"
              value={props.firstName}
              onChange={(e: any) => props.handleChange(e, "firstName")}
            />
          </div>
          <div className="">
            <span> Middle Name </span>
            <br />
            <input
              type="text"
              value={props.middleName}
              onChange={(e: any) => props.handleChange(e, "middleName")}
            />
          </div>
          <div className="">
            <span> Last Name </span>
            <br />
            <input
              type="text"
              value={props.lastName}
              onChange={(e: any) => props.handleChange(e, "lastName")}
            />
          </div>
          <div className="">
            <span> Gender </span>
            <br />
            <select
              onChange={(e: any) => props.handleChange(e, "gender")}
              name="gender"
              value={props.gender}
              id="gender"
              style={{ width: "30%", padding: ".25rem" }}
            >
              <option value="gender">select the gender</option>
              <option value="male">male</option>
              <option value="female">female</option>
            </select>
          </div>
          <br />
          Mobile No.
          <br />
          <input
            type="number"
            value={props.mobileNumber}
            onChange={(e: any) => props.handleChange(e, "mobileNumber")}
          />
          <br />
          Email Id <br />
          <input
            type="email"
            value={props.email}
            onChange={(e: any) => props.handleChange(e, "email")}
          />
          <br />
          <br />
          <div className="">
            <button
              onClick={props.clickSubmit}
              style={{ margin: "auto" }}
              className="btn btn--blue"
            >
              Save Details
            </button>
            <br />
          </div>
        </form>
      </div>
    </Layout>
  );
};
