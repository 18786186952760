import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";
import { isEmpty } from "lodash";

const ProfileOccupationAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);
  let render = <div></div>;
  if (user.occupation_details) {
    render = (
      <>
        <StyledAccordion
          expanded={first}
          onChange={(e: any) => {
            setfirst(!first);
          }}
        >
          <StyledAccordionSummary aria-label="Profile">
            <Grid container alignItems="center" justifyContent="space-between">
              <h4 className="text-primary font-weight-bold">
                Occupation Information
              </h4>
            </Grid>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={4} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Type of Occupation:
                  </Typography>
                  <Typography>{user.occupation_details.type}</Typography>
                </Grid>
                {!isEmpty(user.occupation_details.company_name) && (
                  <Grid item lg={4} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Name of Organization / Business:
                    </Typography>
                    <Typography style={{ textTransform: "capitalize" }}>
                      {user.occupation_details.company_name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={12} className="dp--flex">
                {!isEmpty(user.occupation_details.position) && (
                  <Grid item lg={4} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Position:
                    </Typography>
                    <Typography style={{ marginRight: "5rem" }}>
                      {user.occupation_details.position}
                    </Typography>
                  </Grid>
                )}
                {!isEmpty(user.occupation_details.location) && (
                  <Grid item lg={4} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Location:
                    </Typography>
                    <Typography>{user.occupation_details.location} </Typography>
                  </Grid>
                )}
              </Grid>
              {!isEmpty(user.occupation_details.salary_range) && (
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Salary Range:
                  </Typography>
                  <Typography>
                    {user.occupation_details.salary_range}
                  </Typography>
                </Grid>
              )}
              {!isEmpty(user.occupation_details.description) && (
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Occupation Description:
                  </Typography>
                  <Typography>{user.occupation_details.description}</Typography>
                </Grid>
              )}
              {!isEmpty(user.occupation_details.future_plans) && (
                <Grid item lg={6} className="dp--flex">
                  <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                    Future Plans:
                  </Typography>
                  <Typography>
                    {user.occupation_details.future_plans}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      </>
    );
  }
  return render;
};

export default ProfileOccupationAccordian;
