import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { SignupForm } from "../../components/authentication/Signup";
import { getAction, postAction } from "../../services/generalServices";
import { TableData } from "../../components/authentication/TableData";
import { IUserStats } from "../../interfaces/Interfaces.model";

const Signup = () => {
  const inititialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    isLoading: false,
  };
  const [values, setValues] = useState(inititialState);
  const {
    firstName,
    middleName,
    lastName,
    gender,
    email,
    mobileNumber,
    password,
    confirmPassword,
    isLoading,
  } = values;
  const [checked, setChecked] = React.useState(false);
  const [userStats, setUserStats] = useState<any>(null);
  const handleChange = (event: any, name: string) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const navigate = useNavigate();

  const clickSubmit = async (event: any) => {
    event.preventDefault();
    if (!checked) {
      toast.error("Please accept the terms and conditions");
    } else {
      setValues({ ...values, isLoading: true });
      const data = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        gender: gender,
        emailId: email,
        mobile_number: mobileNumber,
        password: password,
        confirm_password: confirmPassword,
      };
      const response = await postAction("/auth/signup", data);
      if (response.data.error) {
        toast.error(response.data.error);
      } else {
        setValues(inititialState);
        toast.success("Signup success");
        navigate("/profile");
      }
    }
  };
  useEffect(() => {
    getAction("/auth/stats", (data: any) => {
      console.log(data.data);
      setUserStats(data.data);
      return () => {
        setUserStats(null);
      };
    });
  }, []);

  return (
    <div className="">
      <SignupForm
        clickSubmit={clickSubmit}
        handleChange={handleChange}
        email={email}
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        gender={gender}
        mobileNumber={mobileNumber}
        password={password}
        confirmPassword={confirmPassword}
        checked={checked}
        setChecked={setChecked}
        isLoading={isLoading}
      />
      {userStats && <TableData userStats={userStats} />}
    </div>
  );
};

export default Signup;
