/**
 * @deprecated The following component is marked deprecated as on 19-04-2022
 */
import React from "react";
import {
  DataGrid,
  GridCellEditStopParams,
  GridEditMode,
  GridFilterModel,
  GridRowParams,
  GridToolbar,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import styled from "@emotion/styled";

// borderRadius :12
const StyledDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F0F0F2",
    borderRadius: "12px 12px 0 0",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#F0F0F2",
  },
  "& .MuiDataGrid-main .Mui-selected": {
    backgroundColor: "rgba(0, 122, 255, 0.08)",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: "rgba(26, 26, 31, 0.65)",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "& .MuiCheckbox-root": {
    color: "rgba(26, 26, 31, 0.6)",
  },
  "& .MuiDataGrid-row.disabled .MuiDataGrid-cell": {
    cursor: "not-allowed",
  },
});

interface DataGridTableInterface {
  rows: Array<any>;
  columns: Array<any>;
  setSelectionModel: React.Dispatch<React.SetStateAction<any>>;
  rowClickHandler(params: any): void;
  showCheckBox?: boolean;
  filterModel?: GridFilterModel;
  rowsPerPage?: number;
  selectionModel?: GridRowSelectionModel;
  onCellEditCommit?: (e: GridCellEditStopParams) => void;
  editRowsModel?: GridEditMode;
  loading?: boolean;
  isSummaryPage?: boolean;

  getRowClassName?: (
    params: GridRowParams<{
      [key: string]: any;
    }>,
  ) => string;
}

export default function DatagridTable({
  rows,
  onCellEditCommit,
  rowsPerPage,
  columns,
  selectionModel,
  setSelectionModel,
  showCheckBox,
  rowClickHandler,
  filterModel,
  loading,
  getRowClassName,
}: DataGridTableInterface) {
  const [allowSelection, setAllowSelection] = React.useState(true);

  const handleCheckBoxSelection = (newModel: GridRowSelectionModel) => {
    if (allowSelection) setSelectionModel(newModel);
  };

  React.useEffect(() => {
    if (showCheckBox === false) setAllowSelection(false);
  }, [showCheckBox]);

  return (
    <div style={{ height: "max-content", width: "100%" }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        loading={loading ? (rows.length > 0 ? false : true) : false}
        //@ts-ignore
        pageSize={typeof rowsPerPage === typeof undefined ? 100 : rowsPerPage}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        autoHeight
        onCellEditCommit={onCellEditCommit}
        rowsPerPageOptions={
          typeof rowsPerPage === typeof undefined ? [100] : [rowsPerPage!]
        }
        selectionModel={selectionModel}
        onSelectionModelChange={(newModel: any) =>
          handleCheckBoxSelection(newModel)
        }
        checkboxSelection={allowSelection}
        onRowClick={(params: any) => rowClickHandler(params)}
        disableSelectionOnClick
        disableColumnMenu={true}
        components={{ Toolbar: GridToolbar }}
        getRowClassName={getRowClassName}
      />
    </div>
  );
}
