/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { SearchBar } from "../../components/utils/other";
import Layout from "../layout/Layout";
import { getAction } from "../../services/generalServices";
import FindFemales from "../../components/list/FindFemale";
import ReactPaginate from "react-paginate";
import "../../style/other/pagination.css";
import { isAuth } from "../../utils/helper";
import Loading from "../../components/utils/LoadingSign";
import { IUserData } from "../../interfaces/Interfaces.model";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import WithAuth from "../../hooks/hoc/WithAuth";

const FemaleList = () => {
  const linkStyles = {
    textDecoration: "none",
    textUnderlinePosition: "none",
    color: "black",
  };

  const [values, setValues] = useState([]);
  const [nameList, setNameList] = useState<any>([]);
  const [searchValues, setSearchValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const FemalesPerPage = 10;

  const svalues =
    isAuth() && !isEmpty(searchValues)
      ? searchValues.filter((value: IUserData) => value._id !== isAuth()._id)
      : searchValues;
  //Get Current FemaleList
  const indexOfLastFemale = currentPage * FemalesPerPage;
  const indexOfFirstFemale = indexOfLastFemale - FemalesPerPage;
  const currentFemales = svalues.slice(indexOfFirstFemale, indexOfLastFemale);
  const [filterOpts, setfilterOpts] = useState<string[]>([]);

  let render = <Loading />;
  const paginate = ({ selected: selectedPage }: any) =>
    setCurrentPage(selectedPage + 1);
  const pageCount = Math.ceil(svalues.length / FemalesPerPage);
  const navigate = useNavigate();
  // incomplete pagination

  useEffect(() => {
    const getfemalList = async () => {
      const t = await getAction(`/users?gender=female&isRegistered=true`);
      if (t && t.status === 200)
        if (t.data.data) {
          setValues(t.data.data);
          setSearchValues(t.data.data);
        }

      let names = Array.from(
        new Set(
          t.data.data
            .filter((value: IUserData) => {
              if (isAuth()) return value._id !== isAuth()._id;
            })
            .map((o: IUserData) => `${o.first_name} ${o.last_name}`),
        ),
      );

      setNameList(names);
    };
    getfemalList();
    return () => {
      setValues([]);
      setNameList([]);
    };
  }, []);

  const handleDelete = (chipToDelete: string) => () => {
    console.log(
      filterOpts,
      filterOpts.filter((value: string) => value !== chipToDelete),
      chipToDelete,
    );
    setfilterOpts(filterOpts.filter((value: string) => value !== chipToDelete));
  };

  const getFilterFemaleList = async (filterOpts: string[]) => {
    let femalesList = [];
    console.log(filterOpts);
    const s = await getAction(
      `/users?gender=female&isRegistered=true${filterOpts.join("")}`,
    );
    if (s && s.status === 200)
      if (s.data.data && s.data.data.length) femalesList = s.data.data;
    setValues(femalesList);
    setSearchValues(femalesList);
    let names = Array.from(
      new Set(
        femalesList
          .filter((value: IUserData) => value._id !== isAuth()._id)
          .map((o: IUserData) => `${o.first_name} ${o.last_name}`),
      ),
    );
    setNameList(names);
  };

  const getFilteredFemaleList = async (filter: string) => {
    let MalesList = [];
    const s = await getAction(
      `/users?gender=female&isRegistered=true${filter}`,
    );
    if (s && s.status === 200)
      if (s.data.data && s.data.data.length) MalesList = s.data.data;
    setValues(MalesList);
    setSearchValues(MalesList);
    let names = Array.from(
      new Set(
        MalesList.filter((value: IUserData) => value._id !== isAuth()._id).map(
          (o: IUserData) => `${o.first_name} ${o.last_name}`,
        ),
      ),
    );
    setNameList(names);
  };
  const searchName = (newList: []) => {
    setSearchValues(newList);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const handleFilterChange = (field: string, value: string) => {
    switch (field) {
      case "all":
        getFilteredFemaleList("");
        setfilterOpts([]);
        break;
      case "caste":
        setfilterOpts([...filterOpts, `&personal_details.caste=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&personal_details.caste=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&personal_details.caste=${value}`,
          ]);
        }
        break;
      case "subCaste":
        setfilterOpts([...filterOpts, `&personal_details.sub_caste=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&personal_details.sub_caste=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&personal_details.sub_caste=${value}`,
          ]);
        }
        break;
      case "maritalStatus":
        setfilterOpts([
          ...filterOpts,
          `&personal_details.marital_status=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&personal_details.marital_status=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&personal_details.marital_status=${value}`,
          ]);
        }
        break;
      case "age":
        setfilterOpts([...filterOpts, `&personal_details.age=${value}`]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&personal_details.age=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&personal_details.age=${value}`,
          ]);
        }
        break;
      case "annualIncome":
        setfilterOpts([
          ...filterOpts,
          `&occupation_details.salary_range=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&occupation_details.salary_range=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&occupation_details.salary_range=${value}`,
          ]);
        }
        break;
      case "education":
        setfilterOpts([
          ...filterOpts,
          `&educational_qualification.degree=${value}`,
        ]);
        if (isEmpty(filterOpts)) {
          getFilteredFemaleList(`&educational_qualification.degree=${value}`);
        } else {
          getFilterFemaleList([
            ...filterOpts,
            `&educational_qualification.degree=${value}`,
          ]);
        }
        break;
      default:
        break;
    }
  };

  if (nameList) {
    render = (
      <Layout rightSidebar={true}>
        <div className="col-lg-8 mr-5 mt-2">
          <SearchBar
            role="Female"
            list={values}
            nameList={nameList}
            filterOpts={filterOpts}
            handleDelete={handleDelete}
            searchName={searchName}
            selectedFilter={selectedFilter}
            handleFilterChange={handleFilterChange}
            setSelectedFilter={setSelectedFilter}
          />
          <FindFemales
            navigate={navigate}
            searchValues={currentFemales}
            linkStyles={linkStyles}
          />
          <div className="center">
            <ReactPaginate
              onPageChange={paginate}
              pageCount={pageCount}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </Layout>
    );
  }
  return render;
};

export default WithAuth("")(FemaleList);
