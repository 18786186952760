import React from "react";
import { Navigate } from "react-router";
import { isAuth } from "../../utils/helper";

const WithRegister = () => (Component: any) =>
  class withRegister extends React.Component {
    renderProtectedPage() {
      const user = isAuth();

      if (!user) {
        return <Navigate to="/signin" />;
      } else if (user.isRegistered) {
        return <Navigate to="/profile" />;
      } else {
        return <Component {...this.props} />;
      }
    }

    render() {
      return this.renderProtectedPage();
    }
  };

export default WithRegister;
