import { Navigate } from "react-router-dom";
import Home from "./container/Home";
import Profile from "./container/user/profile";
import Register from "./container/user/RegisterProfile";
import ErrorPage from "./components/utils/ErrorPage";
import Signup from "./container/authentication/signup";
import Signin from "./container/authentication/signin";
import { PasswordForget } from "./container/authentication/PasswordForget";
import Reset from "./container/authentication/ResetPassword";
import FemaleList from "./container/list/FemaleList";
import MaleList from "./container/list/MaleList";
import FemaleProfile from "./container/user/FemaleProfile";
import MaleProfile from "./container/user/MaleProfile";
import EditProfile from "./container/user/editProfile";
import Notifications from "./container/notification/Notifications";
import Payment from "./container/Payment";
import Admin from "./container/Admin/Admin";
import AdminAddUser from "./container/Admin/AdminAddUser";
import AdminEditUser from "./container/Admin/AdminEditUser";
import PrivacyPolicy from "./container/authentication/PrivacyPolicy";
import TermsAndCondition from "./container/authentication/TermsAndCondition";

const routes = (isLoggedIn: boolean, isRegistered: boolean) => [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signin",
    element: !isLoggedIn ? <Signin /> : <Navigate to="/profile" />,
  },
  {
    path: "/password-forgot",
    element: !isLoggedIn ? <PasswordForget /> : <Navigate to="/profile" />,
  },
  {
    path: "/reset-password/:token",
    element: !isLoggedIn ? <Reset /> : <Navigate to="/profile" />,
  },
  {
    path: "/signup",
    element: !isLoggedIn ? <Signup /> : <Navigate to="/profile" />,
  },
  {
    path: "/register",
    element: isLoggedIn ? <Register /> : <Navigate to="/signin" />,
  },
  {
    path: "/payment",
    element: isLoggedIn ? <Payment /> : <Navigate to="/signin" />,
  },
  {
    path: "/profile",
    element: isLoggedIn ? <Profile /> : <Navigate to="/signin" />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndCondition />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/editprofile",
    element: isLoggedIn ? <EditProfile /> : <Navigate to="/signin" />,
  },
  {
    path: "/admin",
    element: isLoggedIn ? <Admin /> : <Navigate to="/signin" />,
  },
  {
    path: "/admin/add-user",
    element: isLoggedIn ? <AdminAddUser /> : <Navigate to="/signin" />,
  },
  {
    path: "/adminUser/edit/:userId",
    element: isLoggedIn ? <AdminEditUser /> : <Navigate to="/signin" />,
  },
  {
    path: "/females",
    element: isLoggedIn ? <FemaleList /> : <Navigate to="/signin" />,
  },
  {
    path: "/males",
    element: isLoggedIn ? <MaleList /> : <Navigate to="/signin" />,
  },
  {
    path: "/female/:id",
    element: isLoggedIn ? <FemaleProfile /> : <Navigate to="/signin" />,
  },
  {
    path: "/male/:id",
    element: isLoggedIn ? <MaleProfile /> : <Navigate to="/signin" />,
  },
  {
    path: "/notifications",
    element: isLoggedIn ? <Notifications /> : <Navigate to="/signin" />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
];

export default routes;
