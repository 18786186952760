import { CSSProperties, ReactElement } from "react";
import {
  TextField,
  styled,
  InputAdornment,
  Box,
  SxProps,
  InputProps,
  InputBaseComponentProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { InputRegEx } from "./utils";

enum ColorName {
  default = "default",
  primary = "primary",
  success = "success",
  error = "error",
  warning = "warning",
}

enum ColorValue {
  default = "error.main",
  primary = "primary.main",
  success = "success.main",
  error = "error.main",
  warning = "warning.main",
  text = "text.secondary",
}

const BaseInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "auto",
    padding: 0,
  },
  "& .MuiInputBase-input": {
    padding: "8px",
    height: "40px",
    boxSizing: "border-box",
    border: "2px solid #FBCDCD",
    textTransform: "capitalize",
    borderRadius: "15px",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
    borderStyle: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
    borderStyle: "none",
  },
  "& :not(.Mui-error)": {
    ".MuiInputBase-input": {
      "&:placeholder-shown ~ .MuiOutlinedInput-notchedOutline": {
        borderColor: ColorValue.text,
      },
      "&:not(:placeholder-shown) ~ .MuiOutlinedInput-notchedOutline": {
        borderColor: ColorValue.default,
      },
    },
  },
  "& .MuiFormHelperText-root": {
    margin: "0px 0 0",
    display: "flex",
    flexWrap: "nowrap",
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },
});

const BaseLabel = styled(Typography)({
  "&": {
    letterSpacing: "-0.1px",
    label: {
      "&.error": {
        color: ColorValue.error,
      },
    },
  },
});

const Icon = styled("i")({
  "&": {
    fontSize: "16px",
    lineHeight: "16px",
    "&.bi-info-circle": {
      marginLeft: "4px",
    },
    "&.bi-exclamation-circle": {
      marginRight: "4px",
    },
  },
});

interface IInputProps {
  label?: string;
  onChange?: (event: any) => void;
  value?: string | number;
  id?: string;
  onBlur?: (event: any) => any;
  defaultValue?: string | number;
  error?: boolean;
  name?: string;
  inputProps?: InputBaseComponentProps;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  rows?: number;
  InputProps?: InputProps;
  size?: "medium" | "small";
  required?: boolean;
  multiline?: boolean;
  allowOnly?: "TEXT" | "NUMBER" | "EMAIL" | "DECIMAL_NUMBER";
  tooltip?: {
    text: string;
    color?: "primary" | "error" | "warning";
  };
  helper?: {
    text: string;
    textColor?: "primary" | "error" | "warning";
    icon?: boolean;
    iconColor?: "primary" | "error" | "warning";
  };
  icon?: {
    element: ReactElement | string;
    position: "start" | "end";
  };
  style?: CSSProperties;
  sx?: SxProps;
  onFocus?: any;
  maxRows?: number;
}

export const CustomCapitalInput = (props: IInputProps): ReactElement => {
  const {
    label,
    onChange,
    value,
    id,
    tooltip,
    onBlur,
    defaultValue,
    disabled,
    size,
    helper,
    error,
    icon,
    name,
    placeholder,
    multiline,
    type,
    required,
    inputProps,
    allowOnly,
    onFocus,
    style,
    sx,
    InputProps,
    maxRows,
    rows,
  }: IInputProps = props;

  const UID = new Date().toTimeString() + label;

  const handleChange = (event: any) => {
    if (typeof onChange !== typeof undefined) {
      switch (allowOnly) {
        case "TEXT":
          if (InputRegEx[allowOnly].test(event.target.value)) onChange!(event);
          break;
        case "NUMBER":
          if (InputRegEx[allowOnly].test(event.target.value)) {
            onChange!(event);
          }
          break;
        case "DECIMAL_NUMBER":
          if (InputRegEx[allowOnly].test(event.target.value)) {
            onChange!(event);
          }
          break;
        case "EMAIL":
          if (InputRegEx[allowOnly].test(event.target.value)) onChange!(event);
          break;
        default:
          onChange!(event);
          break;
      }
    }
  };

  return (
    <>
      {label && (
        <div className="dp--flex dp--aic">
          <label htmlFor={id ? id : UID} className={error ? "error" : ""}>
            <BaseLabel
              color={helper?.text ? helper?.textColor : ColorValue.text}
            >
              {label}
            </BaseLabel>
          </label>

          {tooltip && (
            <Tooltip title={tooltip?.text}>
              <Box color={ColorValue[tooltip.color || ColorName.primary]}>
                <Icon className="bi bi-info-circle" />
              </Box>
            </Tooltip>
          )}
        </div>
      )}
      <BaseInput
        fullWidth
        multiline={multiline}
        maxRows={maxRows}
        rows={rows}
        onChange={handleChange}
        inputProps={inputProps}
        onFocus={onFocus}
        value={value}
        onWheel={(e: any) => e.target.blur()}
        variant="outlined"
        placeholder={placeholder}
        disabled={disabled}
        id={id ? id : UID}
        name={name}
        type={
          allowOnly === "NUMBER" || allowOnly === "DECIMAL_NUMBER"
            ? "number"
            : type
        }
        defaultValue={defaultValue}
        onBlur={onBlur}
        size={size}
        required={required}
        error={error}
        style={style}
        sx={sx}
        helperText={
          typeof helper !== typeof undefined && (
            <>
              {helper?.icon && (
                <Typography
                  variant="caption"
                  color={
                    ColorValue[
                      helper?.iconColor ||
                        helper?.textColor ||
                        ColorName.primary
                    ]
                  }
                >
                  <Icon className="bi bi-exclamation-circle" />
                </Typography>
              )}
              <Typography
                variant="caption"
                color={ColorValue[helper?.textColor || ColorName.default]}
              >
                {helper?.text}
              </Typography>
            </>
          )
        }
        InputProps={{
          startAdornment: (
            <>
              {icon?.position === "start" && (
                <InputAdornment position="end">{icon.element}</InputAdornment>
              )}
            </>
          ),
          endAdornment: (
            <>
              {icon?.position === "end" && (
                <InputAdornment position="start">{icon.element}</InputAdornment>
              )}
            </>
          ),
          ...InputProps,
        }}
      />
    </>
  );
};
