import { toast } from "react-toastify";
import { postAction } from "../generalServices";

export * as auth from "./auth";
export * as notification from "./notification";

export async function reset(
  newPassword: string,
  token: string,
  callback?: any,
) {
  try {
    const res = await postAction("/auth/reset-password", {
      newPassword,
      resetPasswordLink: token,
    });
    if (res.status === 200) {
      toast.success("Password changed successfully");
      if (callback) callback();
    } else {
      toast.error(`Password not changed ${res.data.error}`);
    }
  } catch (error) {
    toast.error("something went wrong");
  }
}

export async function forgot(email: string, callback?: any) {
  try {
    const res = await postAction("/auth/forgot-password", { emailId: email });
    if (res.status === 200) {
      toast.success("Email sent");
      if (callback) callback();
    } else {
      toast.error(`Mail not sent ${res.data.error}`);
    }
  } catch (error) {
    toast.error("something went wrong");
  }
}
