import { isEmpty } from "lodash";
import React from "react";
import RightSidebarItem from "../../components/utils/RightSidebarItem";
import { IUserData } from "../../interfaces/Interfaces.model";
import { isAuth } from "../../utils/helper";
import { checkUser } from "../../utils/utility";

export default function RightSidebar({
  recommendedMales,
  recommendedFemales,
}: {
  recommendedMales: IUserData[];
  recommendedFemales: IUserData[];
}) {
  const user: IUserData = isAuth();
  if (checkUser(user) === "male") {
    return (
      <div className="col-lg-3  mb-5 mt-5 mt-lg-2">
        <h3 className="text-center">People you may like</h3>
        {recommendedMales &&
          !isEmpty(recommendedMales) &&
          recommendedMales
            .slice(0, 10)
            .map((male: IUserData, index) => (
              <RightSidebarItem key={index} male={male} />
            ))}
      </div>
    );
  } else if (checkUser(user) === "female")
    return (
      <div className="col-lg-3 ">
        <h3 className="text-center">People you may like</h3>
        {!isEmpty(recommendedFemales) &&
          recommendedFemales
            .slice(0, 10)
            .map((female: IUserData, index) => (
              <RightSidebarItem key={index} female={female} />
            ))}
      </div>
    );
  else return <></>;
}
