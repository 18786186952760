import { useEffect, useReducer, useState } from "react";
import { isEmpty, isEqual, has } from "lodash";
import { IKundaliDetails } from "../../interfaces/model_helper";
import { isAuth, onAuth, setLocalStorage } from "../../utils/helper";
import { patchUserDetails } from "../../services/userService";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
/*****
 Show Add Personal Details hook
 *****/
export interface IKundaliDetailsStateErrors {
  kundali_photo: boolean;
  nakshatra: boolean;
  kundali_exists: boolean;
  charan: boolean;
  time_of_birth: boolean;
  naadi: boolean;
  gan: boolean;
  gotra: boolean;
  raas: boolean;
}

const initialKundaliDetailsErrorsObj = {
  kundali_exists: false,
  kundali_photo: false,
  nakshatra: false,
  charan: false,
  time_of_birth: false,
  naadi: false,
  gan: false,
  gotra: false,
  raas: false,
};

export enum UpdateKundaliState {
  UpdateKundaliPhoto = "kundali_photo",
  UpdatekundaliExists = "kundali_exists",
  UpdateNakshatra = "nakshatra",
  UpdateTimeOfBirth = "time_of_birth",
  UpdateNaadi = "naadi",
  Updateraas = "raas",
  UpdateCharan = "charan",
  UpdateGan = "gan",
  UpdateGotra = "gotra",
}

export enum UpdateActions {
  Reset = "reset",
}

export interface IStringAction {
  type: UpdateKundaliState;
  value: string;
}

interface IResetAction {
  type: UpdateActions.Reset;
}

export interface IHandleKundaliDetailsCreation {
  error: boolean;
  msg?: string;
}

export type KundaliDetailsAction = IStringAction | IResetAction;

const addKundaliDetailsReducer = (
  state: IKundaliDetails,
  action: KundaliDetailsAction,
): IKundaliDetails => {
  let newKundaliDetails: IKundaliDetails = { ...state };
  switch (action.type) {
    case UpdateKundaliState.UpdateKundaliPhoto:
      newKundaliDetails.kundali_photo = action.value;
      break;
    case UpdateKundaliState.UpdatekundaliExists:
      newKundaliDetails.kundali_exists = action.value;
      break;
    case UpdateKundaliState.UpdateNakshatra:
      newKundaliDetails.nakshatra = action.value;
      break;
    case UpdateKundaliState.UpdateTimeOfBirth:
      newKundaliDetails.time_of_birth = action.value;
      break;
    case UpdateKundaliState.UpdateNaadi:
      newKundaliDetails.naadi = action.value;
      break;
    case UpdateKundaliState.Updateraas:
      newKundaliDetails.raas = action.value;
      break;
    case UpdateKundaliState.UpdateCharan:
      newKundaliDetails.charan = action.value;
      break;
    case UpdateKundaliState.UpdateGan:
      newKundaliDetails.gan = action.value;
      break;
    case UpdateKundaliState.UpdateGotra:
      newKundaliDetails.gotra = action.value;
      break;
  }
  return newKundaliDetails;
};

export const useKundaliDetails = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [kundaliDetailsErrors, setKundaliDetailsErrors] =
    useState<IKundaliDetailsStateErrors>(initialKundaliDetailsErrorsObj);
  const [userLocalStorage, setUserLocalStorage] = useState(isAuth());

  const navigate = useNavigate();
  const initialKundaliDetails: IKundaliDetails = {
    kundali_exists: has(userLocalStorage.kundali_details, "kundali_exists")
      ? userLocalStorage.kundali_details.kundali_exists
        ? "Yes"
        : "No"
      : "Select",
    kundali_photo:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.kundali_photo) ??
      "",
    nakshatra:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.nakshatra) ??
      "",
    charan:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.charan) ??
      "",
    naadi:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.naadi) ??
      "",
    raas:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.raas) ??
      "",
    gan:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.gan) ??
      "",
    gotra:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.gotra) ??
      "",
    time_of_birth:
      (userLocalStorage.kundali_details &&
        userLocalStorage.kundali_details.time_of_birth) ??
      "",
  };
  const [kundaliDetails, dispatchKundaliDetails] = useReducer(
    addKundaliDetailsReducer,
    initialKundaliDetails,
  );

  const handleReset = () => {
    dispatchKundaliDetails({
      type: UpdateActions.Reset,
    });
    setIsValid(false);
  };

  const handleKundaliDetailsUpdate = (action: KundaliDetailsAction) => {
    dispatchKundaliDetails(action);
  };

  const handleKundaliDetailsErrors = (action: IStringAction) => {
    let newAddress = { ...kundaliDetailsErrors };
    if (isEmpty(kundaliDetails[action.type])) {
      newAddress[action.type] = true;
    } else {
      newAddress[action.type] = false;
    }
    setKundaliDetailsErrors(newAddress);
  };

  useEffect(() => {
    setUserLocalStorage(isAuth());
  }, []);

  useEffect(() => {
    let errors = false;
    if (kundaliDetails.kundali_exists === "Select") errors = true;
    setIsValid(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kundaliDetails]);

  const handleCreate = async (): Promise<IHandleKundaliDetailsCreation> => {
    try {
      const newOccupationDetails = {
        kundali_details: { ...kundaliDetails },
      };

      if (
        !isEqual({ ...kundaliDetails }, { ...userLocalStorage.kundali_details })
      ) {
        let user = isAuth();
        user.kundali_details = kundaliDetails;

        const responseStatus = await patchUserDetails(newOccupationDetails);
        if (responseStatus) {
          if (isEmpty(user.personal_details)) {
            toast.error("Please fill up personal details ");
          } else if (isEmpty(user.expectation)) {
            toast.error("Please fill up preference details ");
          } else if (isEmpty(user.educational_qualification)) {
            toast.error("Please fill up educational qualification ");
          } else if (isEmpty(user.family_details)) {
            toast.error("Please fill up family details ");
          } else if (isEmpty(user.occupation_details)) {
            toast.error("Please fill up occupational details ");
          } else if (
            !isEmpty(user.personal_details) &&
            !isEmpty(user.expectation) &&
            !isEmpty(user.educational_qualification) &&
            !isEmpty(user.family_details) &&
            !isEmpty(user.occupation_details) &&
            (user.kundali_details.kundali_exists === "No" ||
              !isEmpty(user.kundali_details.kundali_photo))
          ) {
            toast.success("Profile updated successfully");
            user.isRegistered = true;
          }
          setLocalStorage("user", user);
          await onAuth(() => navigate("/profile"));
        }
      } else {
        toast.error("No changes made");
      }
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
      };
    }
  };

  const KundaliDetailsRetun: IKundaliDetailsReturn = {
    kundaliDetails,
    isValid,
    kundaliDetailsErrors,
    handleKundaliDetailsUpdate,
    handleKundaliDetailsErrors,
    handleReset,
    handleCreate,
  };
  return KundaliDetailsRetun;
};

export interface IKundaliDetailsReturn {
  kundaliDetails: IKundaliDetails;
  isValid: boolean;
  kundaliDetailsErrors: IKundaliDetailsStateErrors;
  handleKundaliDetailsErrors: (action: IStringAction) => void;
  handleKundaliDetailsUpdate: (action: KundaliDetailsAction) => void;
  handleReset: () => void;
  handleCreate: () => Promise<IHandleKundaliDetailsCreation>;
}
