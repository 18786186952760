import React from "react";
import Footer from "../../container/layout/Footer";
import Header from "../../container/layout/Header";
import { CustomInput } from "../Shared/CustomInput";

interface ISigninForm {
  email: string;
  password: string;
  isLoading: boolean;
  clickSubmit: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleChange: (e: any, label: string) => void;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}
export const SigninForm = (props: ISigninForm) => (
  <>
    <Header />
    <div
      className="signup__container"
      style={{
        backgroundImage: "url('/images/Signin.jpg')",
        padding: "2.54rem",
      }}
    >
      <div className="authentication_Landing__white-box">
        <h1 className="p-2 pt-4 text-center" style={{ color: "#535253" }}>
          Signin
        </h1>
        <h4 className=" text-center" style={{ color: "#535253" }}>
          This is the sign you are waiting for.
        </h4>
        {/* <div
          className="pb-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_SERVER_URL}/api/auth/login-google`;
            }}
            className="btn btn-primary btn-lg btn-block"
          >
            <i className="fab fa-google pr-2"></i>  Google
          </button>
        </div>

        <p className="lead pb-0 text-center">OR</p> */}
        <div className="white-box">
          <div className="form-group" style={{ width: "93%", margin: "auto" }}>
            <CustomInput
              allowOnly="EMAIL"
              label="Email"
              style={{
                border: "2px solid #000",
                borderRadius: "15px",
              }}
              name={"email"}
              value={props.email}
              onChange={(e: any) => props.handleChange(e, "email")}
              //   onBlur={(e: any) =>
              //     handleFamilyDetailsErrors({
              //       type: UpdateFamilyDetailsState.UpdateMotherLastName,
              //       value: e.target.value,
              //     })
              //   }
              //   error={familyDetailsErrors.mother_last_name}
              //   helper={
              //     familyDetailsErrors.mother_last_name
              //       ? {
              //           text: "Please enter your mother's last name",
              //         }
              //       : undefined
              //   }
              //   required
            />
          </div>
          <div className="form-group" style={{ width: "93%", margin: "auto" }}>
            <CustomInput
              label="Password"
              name="password"
              style={{
                border: "2px solid #000",
                borderRadius: "15px",
              }}
              type={props.checked ? "text" : "password"}
              value={props.password}
              onChange={(e: any) => props.handleChange(e, "password")}
              //   onBlur={(e: any) =>
              //     handleFamilyDetailsErrors({
              //       type: UpdateFamilyDetailsState.UpdateMotherLastName,
              //       value: e.target.value,
              //     })
              //   }
              //   error={familyDetailsErrors.mother_last_name}
              //   helper={
              //     familyDetailsErrors.mother_last_name
              //       ? {
              //           text: "Please enter your mother's last name",
              //         }
              //       : undefined
              //   }
              //   required
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: ".8rem",
            }}
          >
            {/* <input
              type="checkbox"
              checked={props.checked}
              onChange={() => {
                props.setChecked(!props.checked);
              }}
            />
            <label
              onClick={() => {
                props.setChecked(!props.checked);
              }}
            >
              Show Password
            </label> */}
            <br />
            <button
              className="btn btn-primary"
              style={{ width: "30%" }}
              onClick={(e) => props.clickSubmit(e)}
              disabled={props.isLoading}
            >
              Sign in
            </button>
            <button
              onClick={() => (window.location.href = "/password-forgot")}
              className="btn  btn-outline-danger"
              style={{ border: "1.2px solid #dc3545" }}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
