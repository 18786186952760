import React, { useState } from "react";
import { Grid } from "@mui/material";
import Header from "../layout/Header";
import EditPersonalDetailsPhase0 from "../../components/editProfile/EditPersonalDetailsPhase0";
import EditPersonalDetailsPhase1 from "../../components/editProfile/EditPersonalDetailsPhase1";
import EditEducationalDetails from "../../components/editProfile/EditEducationalDetails";
import EditFamilyDetails from "../../components/editProfile/EditFamilyDetails";
import EditOccupationDetails from "../../components/editProfile/EditOccupationDetails";
import EditKundaliDetails from "../../components/editProfile/EditKundaliDetails";
import EditPreferenceDetails from "../../components/editProfile/EditPreferenceDetails";
import RegistrationLeftSideBar from "../../components/register/RegistrationLeftSideBar";
import { isAuth } from "../../utils/helper";
import Loading from "../../components/utils/LoadingSign";
import WithAuth from "../../hooks/hoc/WithAuth";
import EditPhotoAlbum from "../../components/editProfile/EditPhotoAlbum";

export interface IStateData {
  phase: number;
  step: number;
}

const EditProfile = () => {
  const [state, setState] = useState<IStateData>({
    step: 1,
    phase: 0,
  });
  const { step, phase } = state;

  const nextStep = () => {
    const { step } = state;
    setState({ ...state, step: step + 1 });
  };

  const prevStep = () => {
    const { step } = state;
    setState({ ...state, step: step - 1 });
  };

  const checkActive = (currentStep: number) => {
    if (currentStep === step) {
      return "btn profile__btn dp--flex dp--jcsb btn--blue";
    } else {
      return "btn profile__btn dp--flex dp--jcsb btn--white";
    }
  };

  let renderSection;
  switch (step) {
    case 1:
      switch (phase) {
        case 0:
          renderSection = (
            <EditPersonalDetailsPhase0 state={state} setState={setState} />
          );
          break;
        case 1:
          renderSection = (
            <EditPersonalDetailsPhase1 state={state} setState={setState} />
          );
          break;
      }
      break;
    case 2:
      renderSection = (
        <EditEducationalDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 3:
      renderSection = (
        <EditOccupationDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 4:
      renderSection = (
        <EditFamilyDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 5:
      renderSection = (
        <EditPreferenceDetails prevStep={prevStep} nextStep={nextStep} />
      );
      break;
    case 6:
      renderSection = (
        <EditPhotoAlbum prevStep={prevStep} nextStep={nextStep} />
      );
      break;

    case 7:
      renderSection = <EditKundaliDetails prevStep={prevStep} />;
      break;
    default:
      break;
  }
  if (isAuth()) {
    return (
      <>
        <Header />
        <Grid
          container
          columnSpacing={1}
          rowSpacing={1}
          style={{ margin: "24px 24px 24px 0 ", width: "100%" }}
        >
          <RegistrationLeftSideBar
            state={state}
            setState={setState}
            checkActive={checkActive}
          />
          <Grid item lg={8.8}>
            {renderSection}
          </Grid>
        </Grid>
      </>
    );
  } else return <Loading />;
};

export default WithAuth("")(EditProfile);
