import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../container/layout/Footer";
import Header from "../../container/layout/Header";
import { CustomDropdown } from "../Shared/CustomDropdown";
import { CustomInput } from "../Shared/CustomInput";
interface ISignupForm {
  firstName: string;
  middleName: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  lastName: string;
  gender: string;
  mobileNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  clickSubmit: (e: any) => void;
  handleChange: (e: any, label: string) => void;
  isLoading: boolean;
}
export const SignupForm = (props: ISignupForm) => {
  return (
    <>
      <Header />
      <div
        className="signup__container"
        style={{ backgroundImage: "url('/images/Signin.jpg')" }}
      >
        <div className="authentication_Landing__white-box">
          <h2
            className="authentication_Landing__featured"
            style={{ color: "#535253" }}
          >
            Create your Account
          </h2>
          <form className="authentication_Landing__form">
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                label="Bride's/Groom's First Name **"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"firstName"}
                value={props.firstName}
                onChange={(e: any) => props.handleChange(e, "firstName")}
              />
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                label="Bride's/Groom's Middle Name **"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"middleName"}
                value={props.middleName}
                onChange={(e: any) => props.handleChange(e, "middleName")}
              />
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                label="Bride's/Groom's Last Name **"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"lastName"}
                value={props.lastName}
                onChange={(e: any) => props.handleChange(e, "lastName")}
              />
            </div>
            <div
              style={{
                width: "70%",
                margin: "auto",
                textAlign: "left",
              }}
            >
              <CustomDropdown
                title="Bride's/Groom's gender **"
                entities={["select the gender", "male", "female"]}
                value={props.gender}
                style={{
                  border: "1px solid #000",
                  borderRadius: "15px",
                }}
                onChange={(e: any) => props.handleChange(e, "gender")}
              />
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                label="Mobile No **"
                allowOnly="NUMBER"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"mobileNumber"}
                value={props.mobileNumber}
                onChange={(e: any) => props.handleChange(e, "mobileNumber")}
                onBlur={(e: any) => {
                  if (e.target.value.length !== 10) {
                    toast.error("Enter valid phone number");
                  }
                }}
              />
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                label="Email Id **"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                type="email"
                name={"email"}
                value={props.email}
                onChange={(e: any) => props.handleChange(e, "email")}
              />
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                type="password"
                label="Password"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"password"}
                value={props.password}
                onChange={(e: any) => props.handleChange(e, "password")}
              />
            </div>
            <div className="mt-2 mb-2" style={{ width: "70%", margin: "auto" }}>
              <CustomInput
                type="password"
                label="Confirm Password"
                style={{
                  border: "2px solid #000",
                  borderRadius: "15px",
                }}
                name={"confirmPassword"}
                value={props.confirmPassword}
                onChange={(e: any) => props.handleChange(e, "confirmPassword")}
              />
            </div>
            <input
              type="checkbox"
              checked={props.checked}
              data-toggle="modal"
              data-target="#myModal"
              onChange={() => {
                props.setChecked(!props.checked);
              }}
              style={{
                marginRight: "1rem",
              }}
            />
            <Link
              to="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                props.setChecked(!props.checked);
              }}
              style={{ fontSize: "1rem", color: "red", fontWeight: "bold" }}
            >
              Accept Terms and Conditions to proceed
            </Link>
            <p>** states that those fields are Non Editable Field</p>
            <br />
            <div className="">
              <button
                onClick={props.clickSubmit}
                style={{ margin: "auto" }}
                className="btn btn--blue"
              >
                Sign-Up
              </button>
              <br />
              <p>
                already signup? <Link to="/signin">sign-in</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
