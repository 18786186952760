import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../Shared/CustomAccordians";
import moment from "moment";

const ProfileFamilyAccordian = ({ user }: { user: any }) => {
  const [first, setfirst] = useState(false);

  return (
    <>
      <StyledAccordion
        expanded={first}
        onChange={(e: any) => {
          setfirst(!first);
        }}
      >
        <StyledAccordionSummary aria-label="Profile">
          <Grid container alignItems="center" justifyContent="space-between">
            <h4 className="text-primary font-weight-bold">
              Family Information
            </h4>
          </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {user.family_details && (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={3} className="dp--flex">
                  <Typography
                    style={{
                      marginRight: "5px",
                      color: "#3198f8",
                      textTransform: "capitalize",
                    }}
                  >
                    Father's Full Name:
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                    }}
                  >{`${user.family_details.father_first_name ?? ""} ${
                    user.family_details.father_middle_name ?? ""
                  } ${user.family_details.father_last_name ?? ""}`}</Typography>
                </Grid>
                {user.family_details.father_mobile_number && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Father's Contact:
                    </Typography>
                    <Typography style={{ marginRight: "5rem" }}>
                      {user.family_details.father_mobile_number}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.father_dob && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Fathers Dob:
                    </Typography>
                    <Typography>
                      {moment(user.family_details.father_dob).format(
                        "DD/MM/YYYY",
                      )}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.father_dob && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Father's Age:
                    </Typography>
                    <Typography>{user.family_details.father_age}</Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={12} className="dp--flex">
                {user.family_details.father_education && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Fathers Education:
                    </Typography>
                    <Typography>
                      {user.family_details.father_education}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.father_occupation && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Father's Occupation.:
                    </Typography>
                    <Typography>
                      {user.family_details.father_occupation}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={12} className="dp--flex">
                <Grid item lg={3} className="dp--flex">
                  <Typography
                    style={{
                      marginRight: "5px",
                      color: "#3198f8",
                    }}
                  >
                    Mother's Full Name:
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                    }}
                  >{`${user.family_details.mother_first_name ?? ""} ${
                    user.family_details.mother_middle_name ?? ""
                  } ${user.family_details.mother_last_name ?? ""}`}</Typography>
                </Grid>
                {user.family_details.mother_mobile_number && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Mother's Contact No.:
                    </Typography>
                    <Typography>
                      {user.family_details.mother_mobile_number}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.mother_dob && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Mothers Dob:
                    </Typography>
                    <Typography>
                      {moment(user.family_details.mother_dob).format(
                        "DD/MM/YYYY",
                      )}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.mother_dob && (
                  <Grid item lg={3} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Mother's Age:
                    </Typography>
                    <Typography>{user.family_details.father_age}</Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={12} className="dp--flex">
                {user.family_details.mother_education && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Mothers Education:
                    </Typography>
                    <Typography>
                      {user.family_details.mother_education}
                    </Typography>
                  </Grid>
                )}
                {user.family_details.mother_occupation && (
                  <Grid item lg={6} className="dp--flex">
                    <Typography
                      style={{ marginRight: "5px", color: "#3198f8" }}
                    >
                      Mother's Occupation.:
                    </Typography>
                    <Typography>
                      {user.family_details.mother_occupation}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={6} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Living Status:
                </Typography>
                <Typography>{user.family_details.living_status}</Typography>
              </Grid>
              <Grid item lg={6} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Address:
                </Typography>
                <Typography>{user.family_details.address}</Typography>
              </Grid>
              <Grid item lg={5.9} className="dp--flex">
                <Typography style={{ marginRight: "5px", color: "#3198f8" }}>
                  Family Background:
                </Typography>
                <Typography>{user.family_details.family_background}</Typography>
              </Grid>
            </Grid>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </>
  );
};

export default ProfileFamilyAccordian;
