import React from "react";
import { Grid } from "@mui/material";
import { CustomInput } from "../Shared/CustomInput";
import {
  IEducationDetailsReturn,
  UpdateEducationDetailsState,
  useEducationDetails,
} from "../../hooks/registration/EducationDetailsHook";
import { CustomDropdown } from "../Shared/CustomDropdown";
import { IDegreeType } from "../../interfaces/model_helper";
import { isEmpty } from "lodash";

const EditEducationalDetails = ({
  prevStep,
  nextStep,
}: {
  prevStep: () => void;
  nextStep: () => void;
}) => {
  const {
    EducationDetails,
    isValid,
    addNewEducation,
    allEducations,
    EducationDetailsErrors,
    handleEducationDetailsUpdate,
    handleEducationDetailsErrors,
    handleCreate,
    hideNewInput,
    isHidden,
  }: IEducationDetailsReturn = useEducationDetails();

  const cancel = () => {
    prevStep();
  };
  const save = () => {
    handleCreate();
    nextStep();
  };
  return (
    <>
      <Grid item lg={12} style={{ height: "10vh" }}></Grid>
      <Grid item lg={12}>
        {allEducations.map((education, index) => {
          return (
            <div key={index} style={{ margin: "1rem 3rem " }}>
              <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
                <Grid item lg={3.5}>
                  <CustomInput
                    label="Degree Type *"
                    style={{ width: "100%" }}
                    value={education.degree}
                  />
                </Grid>
                <Grid item lg={0.5}></Grid>
                <Grid item lg={3.5}>
                  <CustomInput
                    label="Institution *"
                    style={{ width: "100%" }}
                    value={education.college_name}
                  />
                </Grid>
                <Grid item lg={0.5}></Grid>
                <Grid item lg={4}>
                  <CustomInput
                    label="Year of Passing"
                    style={{ width: "100%" }}
                    value={education.year_of_passing}
                  />
                </Grid>
              </div>
              <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
                <Grid item lg={10}>
                  <CustomInput
                    label="Degree specification"
                    style={{ width: "100%" }}
                    value={education.degree_specification}
                  />
                </Grid>
              </div>
              <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
                <Grid item lg={10}>
                  <CustomInput
                    label="Any Special Achievements"
                    style={{ width: "100%" }}
                    value={education.special_achievements}
                  />
                </Grid>
              </div>
            </div>
          );
        })}
        {isHidden ? (
          <></>
        ) : (
          <div style={{ margin: "1rem 3rem " }}>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={3.5}>
                <CustomDropdown
                  title={"Degree Type *"}
                  entities={Object.values(IDegreeType)}
                  value={EducationDetails.degree}
                  onChange={(e: any) =>
                    handleEducationDetailsUpdate({
                      type: UpdateEducationDetailsState.UpdateDegreeType,
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={0.5}></Grid>
              <Grid item lg={3.5}>
                <CustomInput
                  label="Institution *"
                  style={{ width: "100%" }}
                  name={UpdateEducationDetailsState.UpdateCollegeName}
                  value={EducationDetails.college_name}
                  onChange={(e: any) =>
                    handleEducationDetailsUpdate({
                      type: UpdateEducationDetailsState.UpdateCollegeName,
                      value: e.target.value,
                    })
                  }
                  onBlur={(e: any) =>
                    handleEducationDetailsErrors({
                      type: UpdateEducationDetailsState.UpdateCollegeName,
                      value: e.target.value,
                    })
                  }
                  error={EducationDetailsErrors.college_name}
                  helper={
                    EducationDetailsErrors.college_name
                      ? {
                          text: "Please enter your college name",
                        }
                      : undefined
                  }
                  required
                />
              </Grid>
              <Grid item lg={0.5}></Grid>

              <Grid item lg={4}>
                <CustomInput
                  allowOnly="NUMBER"
                  label="Year of Passing"
                  type="tel"
                  style={{ width: "100%" }}
                  name={UpdateEducationDetailsState.UpdateYearOfPassing}
                  value={EducationDetails.year_of_passing}
                  onChange={(e: any) =>
                    handleEducationDetailsUpdate({
                      type: UpdateEducationDetailsState.UpdateYearOfPassing,
                      value: e.target.value,
                    })
                  }
                  onBlur={(e: any) =>
                    handleEducationDetailsErrors({
                      type: UpdateEducationDetailsState.UpdateYearOfPassing,
                      value: e.target.value,
                    })
                  }
                  error={EducationDetailsErrors.year_of_passing}
                  helper={
                    EducationDetailsErrors.year_of_passing
                      ? {
                          text: "Please enter your year of passing",
                        }
                      : undefined
                  }
                  required
                />
              </Grid>
            </div>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={10}>
                <CustomInput
                  label="Degree specification"
                  style={{ width: "100%" }}
                  value={EducationDetails.degree_specification}
                  name={UpdateEducationDetailsState.UpdateDegreeSpecification}
                  onChange={(e: any) =>
                    handleEducationDetailsUpdate({
                      type: UpdateEducationDetailsState.UpdateDegreeSpecification,
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </div>
            <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
              <Grid item lg={10}>
                <CustomInput
                  label="Any Special Achievements"
                  style={{ width: "100%" }}
                  name={UpdateEducationDetailsState.UpdateSpecialAchievements}
                  value={EducationDetails.special_achievements}
                  onChange={(e: any) =>
                    handleEducationDetailsUpdate({
                      type: UpdateEducationDetailsState.UpdateSpecialAchievements,
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
            </div>
          </div>
        )}
        <div className="dp--flex dp--jcsb" style={{ marginTop: "16px" }}>
          <Grid item lg={12}>
            <div className="dp--flex dp--jcc ">
              {!isValid && !isEmpty(allEducations) ? (
                <button className="btn btn--blue" onClick={hideNewInput}>
                  cancel
                </button>
              ) : (
                <button
                  className="btn btn--blue"
                  disabled={isEmpty(allEducations) && !isValid}
                  onClick={addNewEducation}
                >
                  Add Additional Degree
                </button>
              )}
            </div>
          </Grid>
        </div>
        <Grid item lg={12}>
          <div className="dp--flex dp--jcc ">
            <button className="btn btn--blue" onClick={cancel}>
              Go Back
            </button>
            <button
              className="btn btn--blue"
              disabled={!isValid}
              onClick={save}
            >
              Save and Next
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default EditEducationalDetails;
